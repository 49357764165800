@defer (when dataLoaded) {
<div class="grid">
  <div class="col text-left">
    <button
      pButton
      [label]="labelDuplicate()"
      icon="fa fa-download"
      class="p-button-success"
      (click)="showDialog()"
      [disabled]="!duplicateButton"
    ></button>
  </div>
  <div class="col text-right">
    <button
      pButton
      [label]="labelExport()"
      icon="fa fa-download"
      class="p-button-success"
      (click)="exportDishes()"
    ></button>
  </div>
</div>

<p-table
  #dt
  [value]="dishes"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateStorage="session"
  stateKey="dishes-list-session"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
  selectionMode="multiple"
  [(selection)]="selectedDishes"
  >
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th pSortableColumn="client.name">
        {{ "Licence" | transloco }}
        <p-sortIcon field="client.name"></p-sortIcon>
      </th>
      <th pSortableColumn="name">
        {{ "Nom" | transloco }}<p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="identifiant">
        {{ "Identifiant (EAN)" | transloco }}
        <p-sortIcon field="identifiant"></p-sortIcon>
      </th>
      <th pSortableColumn="internalCode">
        {{ "Code" | transloco }} <p-sortIcon field="internalCode"></p-sortIcon>
      </th>
      <th pSortableColumn="qty">
        {{ "Quantité" | transloco }} <p-sortIcon field="qty"></p-sortIcon>
      </th>
      <th pSortableColumn="unityName">
        {{ "Unité" | transloco }} <p-sortIcon field="unityName"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th></th>
      <th>
        <p-columnFilter field="client.id"  name="client_id" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="licences"
              placeholder="Toutes"
              (onChange)="filter($event.value)"
              optionLabel="displayName"
              optionValue="id"
              class="liste-recherche-table"
            >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="name"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="identifiant"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="internalCode"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="qty"
          matchMode="equals"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="unityName"
          matchMode="contains"
        ></p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-dishe>
    <tr>
      <td>
        <p-tableCheckbox [value]="[dishe.disId]"></p-tableCheckbox>
      </td>
      <td>{{ dishe.client.name }}</td>
      <td>{{ dishe.name }}</td>
      <td>{{ dishe.identifiant }}</td>
      <td>{{ dishe.internalCode }}</td>
      <td>{{ dishe.qty }}</td>
      <td>{{ dishe.unityName }}</td>
    </tr>
  </ng-template>
</p-table>

<div class="card flex justify-content-center">
<p-dialog
header="Header"
[(visible)]="visible"
[modal]="true"
[closable]="false"
[style]="{ width: '40rem', height: '40rem' }">
<ng-template pTemplate="header">
<div class="inline-flex align-items-center justify-content-center gap-2">
<span class="font-bold white-space-nowrap">
Copie de(s) recette(s) selectionnée(s)
</span>
</div>
</ng-template>
<span class="p-text-secondary block mb-5">
Sélectionner les licences cibles
</span>

<div class="flex align-items-center gap-3 mb-5">
  <p-multiSelect
    [(ngModel)]="selectedLicences"
    scrollHeight="250px"
    [options]="licences"
    [placeholder]="'Toutes' | transloco"
    (onChange)="onLicenceChange($event)"
    optionLabel="name"
    optionValue="id"
    class="liste-recherche-table"
    >
  </p-multiSelect>
  </div>
  <ng-template pTemplate="footer">
  <button
    pButton
      [label]="'Annuler' | transloco"
      icon="pi pi-minus"
      class="p-button-unactivate"
      (click)="visible = false; reset()"
    ></button>
    <button
      pButton
      [label]="'Enregistrer' | transloco"
      [disabled]="!enabledSave"
      icon="fa-solid fa-gear"
      class="p-button-success"
      (click)="copyDisheOnTargets()"
    ></button>
  </ng-template>
  </p-dialog>
</div>

} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
