import { Component } from '@angular/core';
import {NgIf} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";

import {ClientGroup} from "../../../../models/client-group";
import {GlobalDatas} from "../../../../models/global-datas";
import {Client} from "../../../../models/client";

import {SessionStorageService} from "../../../../services/storage/session-storage.service";
import {AuthService} from "../../../../services/auth/auth.service";
import {ApiGroupsService} from "../../../../services/api/api-groups";

import {StatisticsGraphComponent} from "../../../dashboards/statistics/statistics-graph.component";

import {TranslocoPipe} from "@ngneat/transloco";

import {CardModule} from "primeng/card";
import {ButtonModule} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import { PanelModule } from 'primeng/panel';
import { TreeModule } from 'primeng/tree';
import {TableModule} from "primeng/table";
import {TooltipModule} from "primeng/tooltip";
import {ConfirmationService, MessageService} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ToastModule} from "primeng/toast";

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrl: './groups-list.component.css',
  standalone: true,
  providers: [MessageService, ConfirmationService],
  imports: [
    TreeModule,
    CardModule,
    FormsModule,
    ButtonModule,
    CalendarModule,
    ReactiveFormsModule,
    StatisticsGraphComponent,
    TranslocoPipe,
    PanelModule,
    NgIf,
    TableModule,
    TooltipModule,
    ConfirmDialogModule,
    ToastModule
  ]
})
export class GroupsListComponent {
  globalDatas: GlobalDatas | null = null;
  treeNodes:any;

  groupIsSelected = false;
  sousGroupIsSelected = false;
  groupEmpty = false;
  dataLoaded = false;
  addSousGroupDisabled= true;
  addGroupDisabled = true;
  newSousGroup = false;
  groupUpdate = true;
  addaNewGroup = false;

  modifOrSave = '';
  headerSousGroupe = '';

  licences: Client[] = [];
  groups: ClientGroup[] = [];

  editForm: FormGroup;

  sousGroupList: any[] = [] ;
  selectedSousGroup = [];
  selectedNodes: any = [];
  selectedNodeData: any = {};

  forUpdate = 1;
  constructor(
    private sessionStorageService: SessionStorageService,
    private apiGroupsService: ApiGroupsService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
  ){
    this.treeNodes = [];

    this.editForm = this.formBuilder.group({
      param_name: [{ value: '', disabled: !this.groupUpdate }, Validators.required],
      param_sous_name: ['', Validators.required],
    });
  }
  ngOnInit() {
    this.globalDatas = this.sessionStorageService.get('globalDatas');
    this.licences = this.authService.getUserLicences();
    this.groups = this.authService.getUserGroups();
    this.reloadTree();
    
    this.dataLoaded = true;
  }

  reloadTree() {
    this.treeNodes = this.apiGroupsService.convertToTreeNodesNoFilter(
      this.globalDatas?.groups ?? [], 
      this.globalDatas?.clientsGroupsFiltered ?? [], 
      this.licences
    );
  }

  onNodeSelect(event: any) {
    this.selectedNodeData = event.node;
    this.sousGroupList = [];
    this.modifOrSave = "Modifier le nom";
    this.addGroupDisabled = false;
    this.addSousGroupDisabled = true;
    this.groupUpdate = true;
    this.addaNewGroup = false;
    //groupe de tête
    if (this.selectedNodeData.data.label && this.selectedNodeData.type == "groupe" && this.selectedNodeData.parent == undefined) {
      this.groupIsSelected = true;
      this.sousGroupIsSelected = true;
      this.editForm.controls['param_name'].setValue(this.selectedNodeData.data.label);
      this.displaySousGroup(this.selectedNodeData);
    }
    //sous-groupe
    else if (this.selectedNodeData.data.label && this.selectedNodeData.type == "groupe" && this.selectedNodeData.parent !== undefined) {
        this.editForm.controls['param_name'].setValue(this.selectedNodeData.data.label);
        this.groupIsSelected = true;
        this.sousGroupIsSelected = true;
      this.displaySousGroup(this.selectedNodeData);
    }
    //licence ou désélection
    else {
      this.groupIsSelected = false;
      this.sousGroupIsSelected = false;
      this.groupEmpty = false;
      this.editForm.controls['param_name'].setValue('');
    }
  }
  displaySousGroup(selectedNodeData: any) {
    for (const sousGroup of selectedNodeData.children) {
      this.sousGroupList.push({ data: sousGroup.data, type: sousGroup.type });
    }
    if (this.sousGroupList.length !== 0) {
      this.groupEmpty = false;
      if (this.sousGroupList[0]['type'] == 'licence') {
        this.headerSousGroupe = 'Licence(s)';
      } else{
        this.headerSousGroupe = 'Sous groupe(s)';
      }
    }else{
      this.headerSousGroupe = 'Sous groupe(s)';
      this.groupEmpty = true;
    }
  }
  updateGroupNameById(group: any, targetCgrId: number, newCgrName: string): any {
    if (group.cgr_id === targetCgrId) {
      group.cgr_name = newCgrName;
      return group;
    }

    if (Array.isArray(group.childs)) {
      for (const child of group.childs) {
        const result = this.updateGroupNameById(child, targetCgrId, newCgrName);
        if (result) {
          break;
        }
      }
    }

    return group;
  }
  updateGroup() {
    if (this.forUpdate === 1) {
    let formData = new FormData();
    const formControls = Object.keys(this.editForm.controls);
    formControls.forEach(controlName => {
      const control = this.editForm.get(controlName);
      if (control) {
        formData.append(controlName, control.value);
      }
    });
    const rowData: any = {
      cgr_id: this.selectedNodeData.data.id,
      cgr_name: formData.get('param_name'),
      cgr_name_docu: ""
    }
    if (rowData.cgr_name !== '') {
      this.apiGroupsService.updateGroup(rowData).subscribe((response) => {
        if (response) {
          if (this.globalDatas) {
            this.globalDatas.groups = this.globalDatas.groups.map((group: any) =>
              this.updateGroupNameById(group, rowData.cgr_id, rowData.cgr_name));
          }
          this.sessionStorageService.set('globalDatas', this.globalDatas);
          this.reloadTree();
          this.groupIsSelected = false;
          this.sousGroupIsSelected = false;
          console.log('Update OK');
        } else {
          console.log('Update KO', response);
        }
      })
    } else {
        this.messageService.add({ severity: 'warn', summary: 'Attention', detail: 'Tous les champs marqués d\'un astérisque sont obligatoires.' });
      }
  } else {
      this.addGroup()
    }
  }
  addGroup() {
    let formData = new FormData();
    let cgr_parent_id;
    const formControls = Object.keys(this.editForm.controls);
    formControls.forEach(controlName => {
      const control = this.editForm.get(controlName);
      if (control) {
        formData.append(controlName, control.value);
      }
    });
    if (this.selectedNodeData?.data) {
      cgr_parent_id = this.selectedNodeData.data.id;
    } else {
      cgr_parent_id = 0;
    }
    const rowData: any = {
      cgr_name:  formData.get('param_sous_name') || formData.get('param_name'),
      cgr_parent_id,
    };
    if (rowData.cgr_name !== '') {
      this.apiGroupsService.addGroup(rowData).subscribe((response) => {
        if (response) {
          if (rowData.cgr_parent_id === 0) {
            this.globalDatas?.groups.push({ cgr_id: response.cgr_id, cgr_name: response.cgr_name });
          } else {
            const parentGroup = this.globalDatas?.groups.find(group => group.cgr_id === rowData.cgr_parent_id);
            if (parentGroup) {
              parentGroup.childs = parentGroup.childs || [];
              parentGroup.childs.push({ cgr_id: response.cgr_id, cgr_name: response.cgr_name });
            }
          }
          this.sessionStorageService.set('globalDatas', this.globalDatas);
          this.reloadTree();
          this.groupIsSelected= false;
          this.sousGroupIsSelected= false;
          this.editForm.controls['param_sous_name'].setValue('');
          this.newSousGroup = false;
          this.addGroupDisabled = true;
          console.log('Update OK');
        } else {
          console.log('Update KO', response);
        }
      })
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Attention', detail: 'Tous les champs marqués d\'un astérisque sont obligatoires.' });
    }
  }
  addSousGroup() {
    this.newSousGroup = true;
    this.addGroupDisabled = false;
    this.groupUpdate = false;
    if (this.groupUpdate) {
      this.editForm.get('param_name')?.enable();
    } else {
      this.editForm.get('param_name')?.disable();
    }
  }
  removeMatchingGroupOrChild(group: any, targetCgrId: number): any | null {
    if (group.cgr_id === targetCgrId) {
      return null;
    }

    if (Array.isArray(group.childs)) {
      for (let i = 0; i < group.childs.length; i++) {
        const child = group.childs[i];
        const result = this.removeMatchingGroupOrChild(child, targetCgrId);
        if (result === null) {
          group.childs.splice(i, 1);
          break;
        }
      }
    }

    return group;
  }
  deleteGroup() {
    const rowData: any = {
      cgr_id: this.selectedNodeData.data.id,
    }
    this.apiGroupsService.deleteGroup(rowData).subscribe((response) => {
      if (response) {
        if (this.globalDatas) {
          this.globalDatas.groups = this.globalDatas.groups
            .map((group: any) => this.removeMatchingGroupOrChild(group, rowData.cgr_id))
            .filter((group: any) => group !== null);
        }
        this.sessionStorageService.set('globalDatas', this.globalDatas);
        this.reloadTree();
        this.editForm.controls['param_name'].setValue('');
        this.groupIsSelected= false;
        this.sousGroupIsSelected= false;
        this.addGroupDisabled = true;
        console.log('Update OK', rowData);
      } else {
        console.log('Update KO', response);
      }
    })
  }
  addLicence() {
    //console.log('addLicence', this.selectedNodeData)
    // this.router.navigate(['settings/ ....); Il faudra router vers la page ou l'on gère les licences.
  }
  onNodeUnSelect() {
    this.groupIsSelected = false;
    this.sousGroupIsSelected = false;
    this.addGroupDisabled = true;
    this.newSousGroup = false;
    this.selectedNodeData = '';
    this.editForm.controls['param_name'].setValue('');
  }
  displayAddCard() {
    this.groupIsSelected = true;
    this.groupEmpty = false;
    this.sousGroupIsSelected = false;
    this.addSousGroupDisabled = false;
    this.addaNewGroup = true;
    this.modifOrSave = "Enregistrer";
    this.forUpdate = 0;
    this.editForm.controls['param_name'].setValue('');
  }
  cancel() {
    this.editForm.controls['param_sous_name'].setValue('');
    this.addSousGroupDisabled = true;
    this.newSousGroup = false;
    this.groupUpdate = true;
    if (this.groupUpdate) {
      this.editForm.get('param_name')?.enable();
    } else {
      this.editForm.get('param_name')?.disable();
    }
  }
}
