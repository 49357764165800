<div [formGroup]="editForm">
    <div class="field">
      <label for="cli_logo">{{
        "Logo" | transloco
      }}</label>
      <p-fileUpload 
        mode="basic" 
        chooseLabel="Choisir une image" 
        chooseIcon="pi pi-upload"
        name="cli_logo" 
        [url]="uploadUrl" 
        accept="image/*" 
        maxFileSize="1000000"
        (onUpload)="onUpload($event)"   />
      
      <input type="hidden" formControlName="cli_image_path" />
      <input type="hidden" formControlName="cli_logo_changed" />

      @if(currLogoUrl != '') {
        <img [src]="currLogoUrl" class="logoClient mt-2" />
      }
    </div>
</div>