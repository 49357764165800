import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Subscription } from 'rxjs';

import { ApiDishesService } from '../../../../services/api/api-dishes.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { LoadingModalService } from '../../../../services/modal/loading-modal.service';

import { Dishe } from '../../../../models/dishe';
import { Client } from '../../../../models/client';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { Table, TableModule } from 'primeng/table';
import {DialogModule} from "primeng/dialog";
import {ConfirmationService, MessageService} from "primeng/api";

@Component({
  selector: 'app-dishes-list',
  templateUrl: './dishes-list.component.html',
  styleUrls: ['./dishes-list.component.css'],
  standalone: true,
  imports: [ButtonModule, CommonModule, FormsModule, MultiSelectModule, TableModule, TranslocoModule, DialogModule],
  providers: [
    { provide: MessageService, useClass: MessageService },
    { provide: ConfirmationService, useClass: ConfirmationService },
  ],
})
export class DishesListComponent implements OnInit, OnDestroy {
  constructor(
    private apiDishesService: ApiDishesService,
    private authService: AuthService,
    private loadingModalService: LoadingModalService,
    private translocoService: TranslocoService,
    private messageService: MessageService,
  ) {}
  dishesSubscription: Subscription = new Subscription();
  dishes: Dishe[] = [];
  licences : Client[] = [];
  dataLoaded: boolean = false;

  dialogStyle = { width: '45rem', height: '15rem' };
  selectedDishes: number[] = [];
  selectedLicences: number[] = [];
  licencesTarget : any = [];
  duplicateButton = false;
  visible: boolean = false;
  enabledSave = false;
  dishe_name: string = "";

  @ViewChild('dt') dt: Table | null= null;


  ngOnInit() {
    this.dishesSubscription = this.apiDishesService
      .getDishes()
      .subscribe((data: Dishe[]) => {
        this.dishes = data;
        this.dataLoaded = true;
      });
    this.licences = this.authService.getUserLicences();
  }

  // exportDisheDisabled() {
  //   return this.selectedDishes.length == 0;
  // }

  labelExport() {
    return this.translocoService.translate((this.selectedDishes.length == 0 ? 'Exporter l\'ensemble des recettes' : 'Exporter les recettes sélectionnées'));
  }
  labelDuplicate() {
    this.duplicateButton = this.selectedDishes.length > 0;
    return this.translocoService.translate((this.selectedDishes.length < 2 ? 'Copier la recette' : 'Copier les recettes'));
  }

  showDialog() {
    this.visible = true;
  }
  onLicenceChange(selectedValues: any) {
    this.licencesTarget = selectedValues;
    this.enabledSave = selectedValues.value.length > 0;
  }
  reset(): void {
    this.selectedDishes = [];
    this.selectedLicences = [];
  }

  copyDisheOnTargets() {
    const filters = this.dt?.filters;
    if (filters) {
      const cli_id = (filters['client.id'] as { value: any }).value ?? [];
      const cli_id_target = this.licencesTarget.value;

      const rowData = {
        cli_id_target: cli_id_target,
        dishe_ids: this.selectedDishes,
        cli_id: cli_id
      };

      this.apiDishesService.copyDisheOnTargets(rowData).subscribe((response) => {console.log('UresponseO', response);
        if (response.success) {
          console.log('Update OK');
          this.visible = false;
          this.dishesSubscription = this.apiDishesService
            .getDishes()
            .subscribe((data: Dishe[]) => {
              this.dishes = data;
              this.dataLoaded = true;
            });
          this.reset();
        } else {
          console.log('Update KO', response);
          this.messageService.add({
            severity: 'error',
            summary: this.translocoService.translate("Erreur"),
            detail: this.translocoService.translate("Une erreur est survenue lors de l'enregistrement")
          });
        }
      })
    }
  }

  exportDishes() {
    this.selectedDishes = this.selectedDishes.flat();
    let filtersDishes = {
      'selectedDishes': this.selectedDishes,
      'clients': [],
      'name': '',
      'internalCode':'',
      'identifiant': '',
      'qty': '',
      'unityName': ''
    };
    const filters = this.dt?.filters;

    if(this.selectedDishes.length == 0 && filters) {
      //si pas de recette sélectionnée on recupere les filtres éventuels
      filtersDishes['clients'] = (filters['client.id'] as { value: any }).value ?? [];
      filtersDishes['name'] =  (filters['name'] as { value: string }).value ?? '';
      filtersDishes['internalCode'] =  (filters['internalCode'] as { value: string }).value ?? '';
      filtersDishes['identifiant'] =  (filters['identifiant'] as { value: string }).value ?? '';
      filtersDishes['qty'] =  (filters['qty'] as { value: string }).value ?? '';
      filtersDishes['unityName'] =  (filters['unityName'] as { value: string }).value ?? '';
    }

    this.loadingModalService.show(
      this.translocoService.translate('Export des recettes'),
      this.translocoService.translate('Fichier en cours de génération, merci de patienter...')
    );
    console.log('this.filtersDishes', filtersDishes);
    this.apiDishesService.getDishesExport(filtersDishes).subscribe((data: any) => {
      const base64Data = data.data_file;

      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileURL = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = fileURL;
      a.download = 'export_plats.xlsx';
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);

      this.loadingModalService.hide();
    });
  }

  ngOnDestroy() {
    this.dishesSubscription.unsubscribe();
  }
}
