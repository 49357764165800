@defer (when dataLoaded) {
<p-table
  [columns]="selectedColumns" 
  [value]="monitorList"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateStorage="session"
  stateKey="monitoring-couchdb-list-session"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
>
  <ng-template pTemplate="caption">
    <p-multiSelect 
        display="chip" 
        [options]="cols" 
        [(ngModel)]="selectedColumns" 
        optionLabel="header"
        selectedItemsLabel="{0} colonnes sélectionnées" 
        [style]="{'width': '100%'}" 
        [maxSelectedLabels]="8"
        placeholder="Choisir les colonnes" />
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th pSortableColumn="client.name">Licence<p-sortIcon field="client.name"></p-sortIcon></th>      
      <th pSortableColumn="client.group.name">Groupe<p-sortIcon field="client.group.name"></p-sortIcon></th>
      <th pSortableColumn="client.groupsSecondary[0].name">Groupe secondaire<p-sortIcon field="client.groupsSecondary[0].name"></p-sortIcon>
      <th pSortableColumn="totalDocs">Total<p-sortIcon field="totalDocs"></p-sortIcon></th>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{col.header}}<p-sortIcon [field]="col.field"></p-sortIcon></th>
    </tr>
    <tr>
      <th>
        <p-columnFilter
          field="client.id"
          matchMode="in"
          [showMenu]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="licences"
              placeholder="Toutes"
              (onChange)="filter($event.value)"
              optionLabel="displayName"
              optionValue="id"
              class="liste-recherche-table"
            >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          field="client.group.id"
          matchMode="in"
          [showMenu]="false"
        >
          <ng-template
            pTemplate="filter"
            let-value
            let-filter="filterCallback"
          >
            <p-multiSelect
              [ngModel]="value"
              [options]="groups"
              [placeholder]="'Toutes' | transloco"
              (onChange)="filter($event.value)"
              optionLabel="name"
              optionValue="id"
              class="liste-recherche-table"
            >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          field="client.groupsSecondary[0].id"
          matchMode="in"
          [showMenu]="false"
        >
          <ng-template
            pTemplate="filter"
            let-value
            let-filter="filterCallback"
          >
            <p-multiSelect
              [ngModel]="value"
              [options]="groups"
              [placeholder]="'Toutes' | transloco"
              (onChange)="filter($event.value)"
              optionLabel="name"
              optionValue="id"
              class="liste-recherche-table"
            >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th *ngFor="let col of columns"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-monitorRow let-columns="columns">
    <tr>
      <td>{{ monitorRow.client.name }} ({{ monitorRow.client.id }})</td>
      <td>{{ monitorRow.client.group.name }}</td>
      <td>{{ monitorRow.client.groupsSecondary[0].name }}</td>
      <td>{{ monitorRow.totalDocs }}</td>
      <td *ngFor="let col of columns">
            @if(col.field == 'dbSize') {
                {{ monitorRow[col.field] | number:'1.2-2' }} Mo
            } @else if(col.field == 'majDate') {   
                {{ monitorRow[col.field] | date : "dd/MM/yyyy HH:mm" }} 
            } @else {
                {{ monitorRow[col.field] }}
            }
      </td>
    </tr>
  </ng-template>
</p-table>
} @placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
