<div [formGroup]="editForm">
  <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_stats_menu" (click)="clicModuleStats()" value="1"
    [label]="'Activer le module statistiques' | transloco"></p-checkbox>

  @if(detailStats) {
    <h3>
      <i class="fa fa-list-check mr-2"></i>
      {{ "Données affichées" | transloco }}
    </h3>
  <div class="field-checkbox flex flex-row align-items-start gap-3 mt-4">
    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_stats_tasks" value="1"
      [label]="'Tâches' | transloco"></p-checkbox>
    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_stats_temps" value="1"
      [label]="'Températures' | transloco"></p-checkbox>
    <p-checkbox class="mb-1" [binary]="true" formControlName="cpa_stats_ncs" value="1"
      [label]="'Non conformités' | transloco"></p-checkbox>
  </div>

  <div class="formgrid grid">
    <div class="field col-12 md:col-4 xl:col-4">
      <div class="field">
        <label for="cpa_stats_treatments">{{ 'Traitements clôts/non clôts' | transloco }}</label>
        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="processList" class="w-full"
          formControlName="cpa_stats_treatments" [filter]="true" optionLabel="name" optionValue="id"></p-dropdown>
      </div>
    </div>
    <div class="field col-12 md:col-4 xl:col-4">
      <div class="field">
        <label for="cpa_stats_photos">{{ 'Photos rattachées' | transloco }}</label>
        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="processList" class="w-full"
          formControlName="cpa_stats_photos" [filter]="true" optionLabel="name" optionValue="id"></p-dropdown>
      </div>
    </div>
    <div class="field col-12 md:col-4 xl:col-4">
      <div class="field">
        <label for="cpa_stats_lots">{{ 'Lots rattachés' | transloco }}</label>
        <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="processList" class="w-full"
          formControlName="cpa_stats_lots" [filter]="true" optionLabel="name" optionValue="id"></p-dropdown>
      </div>
    </div>
  </div>
  }
</div>