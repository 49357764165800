<form [formGroup]="editForm" style="display: block" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <div class="field">
      <label for="cli_id" class="label-required">{{ 'Licence' | transloco }}</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="licences"
        class="w-full"
        formControlName="cli_id"
        [filter]="true"
        optionLabel="displayName"
        optionValue="id"
        [placeholder]="'Sélectionnez une licence' | transloco"
        [required]="true"
      ></p-dropdown>
      @if(isErrorFormControl(editForm.controls['cli_id'])) {
      <small class="text-red-500">{{ 'Licence requise' | transloco }}</small>
      }
    </div>
    <div class="field">
      <label for="code" class="label-required">{{
        "Code" | transloco
      }}</label>
      <input
        type="text"
        pInputText
        id="code"
        pKeyFilter="alphanum"
        formControlName="code"
        required="true"
        autofocus
        class="p-inputtext w-full"
      />
      @if(isErrorFormControl(editForm.controls['code'])) {
      <small class="text-red-500">{{ "Code requis" | transloco }}</small>
      }
    </div>

    @if(saveError) {
    <div class="text-red-500">
      {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
    </div>
    }
    <div class="flex overflow-hidden">
      <div class="flex-grow-1 flex align-items-center justify-content-center">
        <p-button
          type="submit"
          icon="pi pi-save"
          [disabled]="!editForm.valid"
          [label]="'Enregistrer' | transloco"
        ></p-button>
      </div>
      <div
        class="flex-none flex align-items-center justify-content-center mr-3"
      >
        <div class="label-required">
          {{ "Champs obligatoires" | transloco }}
        </div>
      </div>
    </div>
  </p-panel>
</form>
