import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Workspace } from '../../models/workspace';
import { GlobalDatas } from '../../models/global-datas';
import { ApiIndicatorsService } from './api-indicators.service';
import { ApiEshopService } from './api-eshop.service';
import { Frequency } from '../../models/frequency';
import { Day } from '../../models/day';
import { TranslocoService } from '@ngneat/transloco';
import { Country } from '../../models/country';
import { Output } from '../../models/output';
import { ReceptionType } from '../../models/reception-type';
import { Trigger } from '../../models/trigger';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private apiIndicatorsService: ApiIndicatorsService,
    private apiEshopService: ApiEshopService,
    private translateService : TranslocoService
  ) {}

  login(username: string, passwd: string): Observable<any> {
    let formData = new FormData();
    formData.append('login', username);
    formData.append('password', passwd);

    return this.http.post<any>(
      environment.apiURL + 'espace_client/auth.php',
      formData
    );
  }

  loginWithToken(): Observable<any> {
    let formData = new FormData();

    return this.http.post<any>(
      environment.apiURL + 'espace_client/auth.php',
      formData
    );
  }

  getWorkspaces(): Observable<Workspace[]> {
    return this.http
      .get<any>(environment.apiURL + 'espace_client/get_workspaces.php')
      .pipe(
        map((response: any[]) => {
          return response.map((dataWorkspace) => {
            return new Workspace(
              parseInt(dataWorkspace.wrk_id),
              dataWorkspace.wrk_name,
              parseInt(dataWorkspace.wrk_cli_id),
              (dataWorkspace.wrk_parent_id ? parseInt(dataWorkspace.wrk_parent_id) : null)
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getGlobalDatas(): Observable<GlobalDatas | null> {

    return this.http
      .get<any>(environment.apiURL + 'espace_client/get_global_datas.php')
      .pipe(
        map((response: any) => {

          response.groups.push({'cgr_id': '0', 'cgr_name': 'Sans groupe'});

          // traduction et tri des types de questions
          response.surveysQuestionTypes.forEach((oneQt :any) => {
            oneQt.code = oneQt.qty_name;
            oneQt.qty_name = this.translateService.translate('survey.questionTypes.' + oneQt.qty_name);
          });
          response.surveysQuestionTypes.sort((a: any, b: any) => {
            return a.qty_name.localeCompare(b.qty_name);
          });

          // traduction et tri des modes de saisie
          response.surveysInputModes.forEach((oneImode :any) => {
            oneImode.code = oneImode.imo_name;
            oneImode.imo_name = this.translateService.translate('survey.inputModes.' + oneImode.imo_name);
          });
          response.surveysInputModes.sort((a: any, b: any) => {
            return a.imo_name.localeCompare(b.imo_name);
          });

          return new GlobalDatas(
            response.languages,
            response.userProfiles,
            response.alertes_types,
            response.alertes_frequencies,
            response.output_process_types,
            response.output_reception_types,
            response.output_process_path,
            response.output_reception_path,
            this.apiIndicatorsService.mapFieldTypes(response.indicatorsFieldsTypes),
            this.apiEshopService.mapCategories(response.eshopCategories),
            response.groups,
            response.groupsLicences,
            response.groupsLicencesFiltered,
            this.mapFrequencies(response.frequencies),
            this.mapDays(response.days),
            response.surveysQuestionTypes,
            response.surveysInputModes,
            this.mapCountries(response.countries),
            this.mapOutputs(response.outputs),
            this.mapReceptionTypes(response.receptionsTypes),
            this.mapTriggers(response.triggers)
          );
        }),
        catchError((error) => {
          return of(null);
        })
      );
  }

  mapFrequencies(apiData: any[]): Frequency[] {
    let frequencies: Frequency[] = [];

    apiData.forEach((dataC: any) => {
      frequencies.push(
        new Frequency(
          parseInt(dataC['fre_id']),
          dataC['fre_name'],
          parseInt(dataC['fre_delay']),
          dataC['fre_code']
        )
      );
    });

    return frequencies;
  }

  mapDays(apiData: any[]): Day[] {
    let days: Day[] = [];

    apiData.forEach((dataC: any) => {
      days.push(
        new Day(
          parseInt(dataC['day_id']),
          dataC['day_name'],
          parseInt(dataC['day_order'])
        )
      );
    });

    return days;
  }

  mapCountries(apiData: any[]): Country[] {
    let countries: Country[] = [];

    apiData.forEach((dataC: any) => {
      countries.push(
        new Country(
          parseInt(dataC['cot_id']),
          dataC['cot_name']
        )
      );
    });

    return countries;
  }

  mapOutputs(apiData: any[]): Output[] {
    let countries: Output[] = [];

    apiData.forEach((dataC: any) => {
      countries.push(
        new Output(
          parseInt(dataC['output_id']),
          dataC['output_name']
        )
      );
    });

    return countries;
  }

  mapReceptionTypes(apiData: any[]): ReceptionType[] {
    let recTypes: ReceptionType[] = [];

    apiData.forEach((dataC: any) => {
      recTypes.push(
        new ReceptionType(
          parseInt(dataC['rty_id']),
          dataC['rty_name'],
          parseInt(dataC['rty_men_id']),
        )
      );
    });

    return recTypes;
  }

  mapTriggers(apiData: any[]): Trigger[] {
    let triggers: Trigger[] = [];

    apiData.forEach((dataC: any) => {
      triggers.push(
        new Trigger(
          parseInt(dataC['tri_id']),
          dataC['tri_name']
        )
      );
    });

    return triggers;
  }
}
