import { Client } from "./client";
import { Sensor } from "./sensor";

export class SensorAlert {
    public id: number;
    public delayStart: number;
    public delayRepeat: number;
    public nbRepeat: number;

    public client: Client;
    public sensors: Array<Sensor>;
    public recipients: Array<string>;
    public lastAlert: moment.Moment | null;

    constructor(
        id: number,
        delayStart: number,
        delayRepeat: number,
        nbRepeat: number,
        client: Client,
        sensors: Array<Sensor>,
        recipients: Array<string>,
        lastAlert: moment.Moment | null,
        ) {
        this.id = id;
        this.delayStart = delayStart;
        this.delayRepeat = delayRepeat;
        this.nbRepeat = nbRepeat;
        this.client = client;
        this.lastAlert = lastAlert;

        //sondes
        this.sensors = sensors;
        
        //destinataires
        this.recipients = recipients;
    }
}