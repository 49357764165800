import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';

import {ActivatedRoute} from "@angular/router";
import {EditComponent} from "../../core/edit/edit.component";
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';
import moment from 'moment';

import {ButtonModule} from "primeng/button";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DropdownModule} from "primeng/dropdown";
import {ToastModule} from "primeng/toast";
import {PanelModule} from "primeng/panel";
import {InputNumberModule} from "primeng/inputnumber";
import {ConfirmationService, MessageService} from "primeng/api";
import {CalendarModule} from "primeng/calendar";
import { ToggleButtonModule } from 'primeng/togglebutton';

import { Client } from '../../../models/client';
import { AuthService } from '../../../services/auth/auth.service';
import {ApiLsproductsService} from "../../../services/api/api-ls-products";

@Component({
  selector: 'app-ls-products',
  templateUrl: './ls-products.component.html',
  styleUrls: ['./ls-products.component.css'],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    TranslocoModule,
    DropdownModule,
    ReactiveFormsModule,
    ToastModule,
    PanelModule,
    InputNumberModule,
    CalendarModule,
    ToggleButtonModule,
  ],
  providers: [
    { provide: MessageService, useClass: MessageService },
    { provide: ConfirmationService, useClass: ConfirmationService },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class LsProductsComponent extends EditComponent implements OnInit {

  licences: Client[] = [];

  editForm: FormGroup;
  doTransfert: boolean = false;
  targetLicence: boolean = false;
  cancelButton: boolean = true;
  dateError: any;
  choice: string = '';

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private messageService: MessageService,
    private apiLsproductsService: ApiLsproductsService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_LSCliSource: ['', Validators.required],
      param_LSDate: [new Date()],
      param_Transfert: [false],
      param_LSCliTarget: [''],

    })
  }

  override ngOnInit() {
    super.ngOnInit();

    this.licences = this.authService.getUserLicences().filter(licence => licence.isLS === 1);
  }

  licenceCliSelected() {
    this.cancelButton = false;
  }

  changeStartPeriod() {
    let paramLSDateValue = this.editForm.controls['param_LSDate'].value;
    if (!paramLSDateValue) {
      paramLSDateValue = new Date();
    } else {
      paramLSDateValue = new Date(paramLSDateValue);
    }
    this.editForm.controls['param_LSDate'].setValue(paramLSDateValue);
    this.dateError = paramLSDateValue;
  }

  doTransfertData(Transfert: any) {
    this.doTransfert = Transfert.checked;
    if (this.doTransfert) {
      this.targetLicence = true;
      this.editForm.controls['param_LSCliTarget'].setValidators([Validators.required]);
    } else {
      this.targetLicence = false;
      this.editForm.controls['param_LSCliTarget'].clearValidators();
      this.editForm.controls['param_LSCliTarget'].setValue('');
    }
    this.editForm.controls['param_LSCliTarget'].updateValueAndValidity();
  }

  save() {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression/le transfert des produits LS ?'
        ),
      header: 'Suppression/Transfert',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let formData = new FormData();
        const formControls = Object.keys(this.editForm.controls);
        formControls.forEach(controlName => {
          const control = this.editForm.get(controlName);
          if (control) {
            formData.append(controlName, control.value);
          }
        });

        const rowData: any = {
          LSCliSource: formData.get('param_LSCliSource'),
          DateError: moment(this.dateError).format('DD/MM/YYYY'),
          Transfert: formData.get('param_Transfert'),
          LSCliTarget: formData.get('param_LSCliTarget')
        };

        if (formData.get('param_Transfert') == "true") {
          this.choice = "transféré(s)";
        } else {
          this.choice = "supprimé(s)"
        }

        this.apiLsproductsService.delOrTransfert(rowData).subscribe((response) => {
          if (response.reponse) {
            let nbre = 0;
            if (response.nbreDL > 0) {
              nbre = response.nbreDL;
            } else {
              nbre = response.nbreTR;
            }
            this.messageService.add({
              severity: 'success',
              summary: this.translocoService.translate("Succés"),
              detail: this.translocoService.translate("Opération réalisée avec succés ! \n" + nbre +" produit(s) a(ont) été " + this.choice)
            });
            console.log('DEL OK');
          } else {
            this.messageService.add({
              severity: 'error',
              summary: this.translocoService.translate("Erreur"),
              detail: this.translocoService.translate("Une erreur est survenue ou aucun produit à cette date !")
            });
            console.log('DEL KO');
          }
        });
      }
    });
  }

  cancelDelete() {
      this.editForm.reset();
    this.targetLicence = false;
      this.changeStartPeriod();
    this.cancelButton = true;
  }

}
