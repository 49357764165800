import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule  } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel';

import { EditComponent } from '../../../../core/edit/edit.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ActivatedRoute } from '@angular/router';
import { SessionStorageService } from '../../../../../services/storage/session-storage.service';
import { Output } from '../../../../../models/output';
import { FileUploadModule, UploadEvent } from 'primeng/fileupload';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-licences-edit-logo',
  templateUrl: './licences-edit-logo.component.html',
  styleUrl: './licences-edit-logo.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    FileUploadModule,
    FormsModule,
    InputNumberModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
})
export class LicencesEditLogoComponent extends EditComponent implements OnInit {
  @Input() editForm!: FormGroup;
  @Input() cliId: string = '';

  detailTracea:boolean = false;

  outputs: Output[] = [];
  uploadUrl: string = '';
  currLogoUrl: string = '';

  constructor(
      private route: ActivatedRoute,
      private sessionStorageService: SessionStorageService,
    ) {
        super(route);
    }

  override ngOnInit(): void {
    let globalDatas = this.sessionStorageService.get('globalDatas');

    this.outputs = globalDatas.outputs;

    if(this.cliId == '') {
        //préselection de l'output aucun
        const objAucun = this.outputs.find((oneOutput: Output) => oneOutput.name == 'Aucun');
  
        if(objAucun) {
          this.editForm.controls['cpa_output'].setValue(objAucun.id);
        }
      }

    this.uploadUrl = environment.apiURL + "espace_client/post_client_logo.php";

    this.clicModuleTraceability();
  }

  clicModuleTraceability() {
    this.detailTracea = this.editForm.controls['cpa_traceability_module'].value;
  }
  onUpload(event: any) {
    if( event.originalEvent?.body?.fullPath) {
      this.currLogoUrl = environment.v2URL + event.originalEvent?.body?.fullPath;
      this.editForm.controls['cli_image_path'].setValue(event.originalEvent?.body?.fileName);
      this.editForm.controls['cli_logo_changed'].setValue("1");
    }    
  }
  
}
