import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';

import { AuthService } from '../../../../services/auth/auth.service';

import {ActivatedRoute, Router} from '@angular/router';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { Client } from '../../../../models/client';

import { EditComponent } from '../../../core/edit/edit.component';

import {InputTextModule} from "primeng/inputtext";
import {TooltipModule} from "primeng/tooltip";

import {ProcessOutputList} from "../../../../models/process-output-list";
import {ApiProcessOutputListService} from "../../../../services/api/api-process-list-outputs";
import {ProcessOutputType} from "../../../../models/process-output-type";
import {ConfirmationService, MessageService} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {SessionStorageService} from "../../../../services/storage/session-storage.service";
import {GlobalDatas} from "../../../../models/global-datas";
import {ProcessListParameters} from "../../../../models/process-list-parameters";
import {ApiProcessListParametersService} from "../../../../services/api/api-process-list-parameters.service";

@Component({
  selector: 'app-process-list-traceability-outputs',
  templateUrl: './process-list-traceability-outputs.component.html',
  styleUrls: ['./process-list-traceability-outputs.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    RippleModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    ConfirmDialogModule,
  ],
  providers: [
    { provide: MessageService, useClass: MessageService },
    { provide: ConfirmationService, useClass: ConfirmationService },
  ],
})
export class ProcessListTraceabilityOutputsComponent extends EditComponent implements OnInit, OnDestroy {
  ProcessListOutputSubscription: Subscription = new Subscription();
  ProcessOutputPrintTypeSubscription: Subscription = new Subscription();
  ProcessOutputPathSubscription: Subscription = new Subscription();
  ProcessListParametersSubscription: Subscription = new Subscription();

  processOutputList!: ProcessOutputList[];
  outputType: ProcessOutputType[] = [];
  parameters!: ProcessListParameters[];

  name: string = '';
  licences : Client[] = [];
  tprId: any;
  clientId: string = '';
  touId: any;
  topId: any;
  tpaId: any;
  selectedOutput = [];
  selectedRows= false;
  outputIsSelected= false;
  outputSelected: any;

  editForm: FormGroup;
  dropdownPrintType: any[] | undefined;
  ouiNon: { label: string, value: number }[];
  globalDatas: GlobalDatas | null = null;
  selectedOutputProcessType: any;
  path: any[] | undefined;
  addEnabled= true;
  cancelEnabled = false;
  modifInProgress= false;
  areButtonsDisabled = false;
  setting = '';
  outputParameterIsSelected = false;
  outputParameterAddIsSelected= false;

  editFormParam: FormGroup;
  dropdownParamNameAdd: any[] | undefined;
  dropdownParamFont: { label: string, value: string }[];
  dropdownParamWeight: { label: string, value: string | null }[];
  outputParameterSettedIsSelected = false;
  isTableClickable = true;


  constructor(
    private apiProcessOutputListService: ApiProcessOutputListService,
    private apiProcessListParametersService: ApiProcessListParametersService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_printType: ['', Validators.required],
      param_printName: ['', Validators.required],
      param_required: ['', Validators.required],
      param_outputPath: ['', Validators.required],
      param_order: ['', Validators.required],
    })
    this.dropdownPrintType = [];
    this.path = [];
    this.ouiNon = [];
    this.selectedOutputProcessType = [];

    this.editFormParam = this.formBuilder.group({
      param_ParamOrder: ['1', Validators.required],
      param_ParamName: [{ value: '', disabled: true }],
      param_ParamNameAdd: [''],
      param_ParamFont: ['Arial', Validators.required],
      param_ParamSize: ['8', Validators.required],
      param_ParamWeight: [null],

    })
    this.dropdownParamNameAdd = [];
    this.dropdownParamFont = [];
    this.dropdownParamWeight = [];
  }

  override ngOnInit() {
    super.ngOnInit();

    this.globalDatas = this.sessionStorageService.get('globalDatas');
    this.tprId = this.route.snapshot.paramMap.get('tpr_id') ?? '';
    this.clientId = this.route.snapshot.paramMap.get('clientId') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
    this.licences = this.authService.getUserLicences();

    this.ProcessListOutputSubscription = this.apiProcessOutputListService
      .getProcessList(this.tprId)
      .subscribe((data: ProcessOutputList[]) => {
        this.processOutputList = data;
      });

    this.dropdownPrintType = this.globalDatas?.output_process_types;
    this.path = Array.isArray(this.globalDatas?.output_process_path[0])
      ? this.globalDatas?.output_process_path[0]
        .filter(item => item && item.trim() !== '')
        .map(path => ({ label: path, value: path }))
      : [];

    this.ouiNon = [
      { label: 'Oui', value : 1 },
      { label: 'Non', value : 0 },
    ];
    this.dropdownParamFont = [
      { label: 'Arial', value : 'Arial' },
      { label: 'Tahoma', value : 'Tahoma' },
    ]
    this.dropdownParamWeight= [
      { label: 'Aucun', value : null },
      { label: 'Gras', value : 'B' },
      { label: 'Souligné', value : 'U' },
      { label: 'Italique', value : 'I' },
      { label: 'Gras et souligné', value : 'BU' },
      { label: 'Gras et Italique', value : 'BI' },
      { label: 'Gras et Italique souligné', value : 'BUI' },
      { label: 'Souligné et Italique', value : 'UI' },
    ]

    this.ProcessListParametersSubscription = this.apiProcessListParametersService
      .getProcessListParameters(this.tprId)
      .subscribe((selectedProcess: ProcessListParameters[]) => {
        const groupedParameters = this.groupBy(selectedProcess, 'tpa_id');
        this.parameters = Object.values(groupedParameters).map(group => group[0]);

        this.sortParametersByOrder();
      });

    this.dataLoaded = true;
  }
  groupBy(array: any[], key: string): { [key: string]: any[] } {
    return array.reduce((result, currentItem) => {
      const groupKey = currentItem[key];
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentItem);
      return result;
    }, {});
  }

  sortParametersByOrder() {
    this.parameters.sort((a, b) => a.tpa_order - b.tpa_order);
  }

  accessToParameters() {
    if(this.clientId != '') {
      this.router.navigate(['settings/process/process-list-traceability-parameters/', this.tprId, this.clientId]);
    } else {
      this.router.navigate(['settings/process/process-list-traceability-parameters/', this.tprId]);
    }
  }

  onRowSelect(event: any, touId: any) {
    this.setting = "Modification de l'output";
    this.addEnabled = false;
    this.outputIsSelected = true;
    this.areButtonsDisabled = true;

    if (this.tprId != '') {
      this.ProcessListOutputSubscription = this.apiProcessOutputListService
        .getProcessList(this.tprId)
        .subscribe((dataparam: ProcessOutputList[]) => {
          this.touId = touId;
          for (const item of dataparam) {
            if (item.tou_id == event.tou_id) {
              this.editForm.controls['param_printType'].setValue(String(event.tou_tot_id));
              this.editForm.controls['param_printName'].setValue(event.tou_name);
              this.editForm.controls['param_required'].setValue(event.tou_compulsory);
              this.editForm.controls['param_outputPath'].setValue(event.tou_exec_path);
              this.editForm.controls['param_order'].setValue(event.tou_order);
            }
          }
        });
    }
  }
  newOutput() {
    this.setting = "Nouvel output";
    this.addEnabled = false;
    this.outputIsSelected = true;
    this.areButtonsDisabled = true;
    this.touId =  -1;

    this.editForm.controls['param_printType'].setValue(1);
    this.editForm.controls['param_printName'].setValue('');
    this.editForm.controls['param_required'].setValue(1);
    this.editForm.controls['param_outputPath'].setValue('');
    this.editForm.controls['param_order'].setValue(1);
  }

  deleteThisOutput(event: any) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Vous êtes sur le point de supprimer définitivement cet ouput !'),
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiProcessOutputListService.deleteOutput( parseInt(event.tou_id)).subscribe((response) => {
          if (response) {
            this.processOutputList = this.processOutputList.filter(
              (val: ProcessOutputList) => val.tou_id !== event.tou_id
            );
            console.log('Deletion OK', event.tou_id);
          } else{
            console.log('Deletion KO', event.tou_id);
          }
        });
      },
    });
  }
  isNaN(value: any): boolean {
    return isNaN(value);
  }
  addOutputParameter(event: any, rowData: any) {
    this.setting = "Ajout/Modification de(s) paramètre(s) à l'output";
    this.addEnabled = false;
    this.outputIsSelected = false;
    this.areButtonsDisabled = true;
    this.outputParameterIsSelected = true;
    this.touId = event.tou_id;
    this.isTableClickable = true;

    this.parameters.forEach(param => {
      param.checked = param.top_tou_id === this.touId;
      param.top_weight = (param.top_weight === null || param.top_weight === "") ? "Aucun" : param.top_weight;
    });
    const notChecked: any[] = [];
    this.parameters.forEach(item => {
      if (!item.checked) {
        this.dropdownParamNameAdd?.push(item);
      } else{
        notChecked.push(item);
      }
    });
    const toptopSetA = new Set(notChecked.map(item => item.top_tpa_id));
    this.dropdownParamNameAdd = this.dropdownParamNameAdd?.filter(item => !toptopSetA.has(item.top_tpa_id));
  }
  onRowSelectParam(event: any): void {
    this.outputParameterSettedIsSelected = true;
    this.areButtonsDisabled = true;
    this.selectedRows = true;
    this.topId = event.data.top_id;
    this.tpaId = event.data.tpa_id;

    this.editFormParam.controls['param_ParamOrder'].setValue(event.data.top_order);
    this.editFormParam.controls['param_ParamName'].setValue(event.data.tpa_name_clean);
    this.editFormParam.controls['param_ParamFont'].setValue(event.data.top_font);
    this.editFormParam.controls['param_ParamSize'].setValue(event.data.top_size);
    this.editFormParam.controls['param_ParamWeight'].setValue(event.data.top_weight);
  }

  addNewoutputParam() {
    this.topId = 0;
    this.outputParameterSettedIsSelected = true;
    this.outputParameterAddIsSelected = true;

    this.editFormParam.controls['param_ParamOrder'].setValue('1');
    this.editFormParam.controls['param_ParamNameAdd'].setValue('');
    this.editFormParam.controls['param_ParamFont'].setValue('Arial');
    this.editFormParam.controls['param_ParamSize'].setValue('10');
    this.editFormParam.controls['param_ParamWeight'].setValue(null);
  }

  deleteOutputParameter(event: any): void  {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Vous êtes sur le point de supprimer ce paramètre !'),
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let formData = new FormData();
        formData.append('top_order', '-1');
        formData.append('top_id', event.top_id);

        this.apiProcessOutputListService.updateOutputTrackingParameters(formData).subscribe(() => {
          this.ProcessListParametersSubscription = this.apiProcessListParametersService
            .getProcessListParameters(this.tprId)
            .subscribe((selectedProcess: ProcessListParameters[]) => {
              this.parameters = selectedProcess;
              this.sortParametersByOrder();
            });
          this.cancel();
          this.cancelAdd();
        });
      }
    })
  }
  getTpaId(event:any){
    this.tpaId = event.value;
  }
  toggleTableClickable(): void {
    this.isTableClickable = !this.isTableClickable;
  }
  cancel() {
    this.addEnabled = true;
    this.outputIsSelected = false;
    this.areButtonsDisabled = false;
    this.outputParameterSettedIsSelected = false;
    this.selectedOutput = [];
    this.isTableClickable = true;
    this.outputParameterAddIsSelected = false;
    this.outputParameterIsSelected = false;
    this.dropdownParamNameAdd = [];

    this.editForm.controls['param_printType'].setValue('');
    this.editForm.controls['param_printName'].setValue('');
    this.editForm.controls['param_required'].setValue('');
    this.editForm.controls['param_outputPath'].setValue('');
    this.editForm.controls['param_order'].setValue('');

    this.editFormParam.controls['param_ParamOrder'].setValue('');
    this.editFormParam.controls['param_ParamName'].setValue('');
    this.editFormParam.controls['param_ParamFont'].setValue('');
    this.editFormParam.controls['param_ParamSize'].setValue('');
    this.editFormParam.controls['param_ParamWeight'].setValue('');
  }
  cancelAdd() {
    this.addEnabled = true;
    this.outputIsSelected = false;
    this.outputParameterSettedIsSelected = false;
    this.selectedOutput = [];
    this.isTableClickable = true;
    this.outputParameterAddIsSelected= false;
    this.areButtonsDisabled = this.outputParameterIsSelected;
    this.editFormParam.controls['param_ParamOrder'].setValue('');
    this.editFormParam.controls['param_ParamName'].setValue('');
    this.editFormParam.controls['param_ParamFont'].setValue('');
    this.editFormParam.controls['param_ParamSize'].setValue('');
    this.editFormParam.controls['param_ParamWeight'].setValue('');
  }
  save() {
    let formData = new FormData();
    let formDataparam = new FormData();
    if (this.selectedRows || this.outputParameterAddIsSelected) {
      formDataparam.append('top_order', this.editFormParam.controls['param_ParamOrder'].value);
      if (this.selectedRows) {
        formDataparam.append('tpa_name', this.editFormParam.controls['param_ParamName'].value);
      }else{
        formDataparam.append('tpa_name', this.editFormParam.controls['param_ParamNameAdd'].value);
      }
      formDataparam.append('top_font', this.editFormParam.controls['param_ParamFont'].value);
      formDataparam.append('top_size', this.editFormParam.controls['param_ParamSize'].value);
      formDataparam.append('top_weight', this.editFormParam.controls['param_ParamWeight'].value);
      formDataparam.append('top_id', this.topId);
      formDataparam.append('tou_id', this.touId);
      formDataparam.append('tpa_id', this.tpaId);
      formDataparam.append('top_ignore_empty', '0');
      this.apiProcessOutputListService.updateOutputTrackingParameters(formDataparam).subscribe((response) => {
        if (response) {
          this.ProcessListParametersSubscription = this.apiProcessListParametersService
            .getProcessListParameters(this.tprId)
            .subscribe((selectedProcess: ProcessListParameters[]) => {
              this.parameters = selectedProcess;
              this.sortParametersByOrder();
            });
          this.cancel();
          this.cancelAdd();
          console.log('Update OK', formDataparam);
        } else {
          console.log('Update KO', response);
        }
      });
    } else
    {
      if (this.touId) {
        formData.append('tou_id', this.touId);
      } else {
        formData.append('tou_id', '-1');
      }
      formData.append('tou_name', this.editForm.controls['param_printName'].value);
      formData.append('tou_tpr_id', this.tprId);
      formData.append('tot_id', this.editForm.controls['param_printType'].value);
      formData.append('tou_compulsory', this.editForm.controls['param_required'].value);
      formData.append('tou_exec_path', this.editForm.controls['param_outputPath'].value);
      formData.append('tou_order', this.editForm.controls['param_order'].value);

      this.apiProcessOutputListService.updateOutput(formData).subscribe((response) => {
        if (response) {
          this.ProcessListOutputSubscription = this.apiProcessOutputListService
            .getProcessList(this.tprId)
            .subscribe((data: ProcessOutputList[]) => {
              this.processOutputList = data;
            });
          this.cancel();
          console.log('Update OK', formData);
        } else {
          console.log('Update KO', response);
        }
      });
    }
  }

  ngOnDestroy() {
    this.ProcessOutputPrintTypeSubscription.unsubscribe();
    this.ProcessOutputPathSubscription.unsubscribe();
    this.ProcessListOutputSubscription.unsubscribe()
  }
}
