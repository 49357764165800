import { Component, OnDestroy, OnInit, ChangeDetectorRef  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

import {Subscription, take, forkJoin} from 'rxjs';

import {ApiProcessListService} from "../../../../services/api/api-process-list.service";
import { AuthService } from '../../../../services/auth/auth.service';

import { Client } from '../../../../models/client';
import { ProcessList } from '../../../../models/process-list';

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import {TooltipModule} from "primeng/tooltip";
import {ConfirmationService, MessageService} from 'primeng/api';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import { DialogModule } from 'primeng/dialog';
import { ChipsModule } from 'primeng/chips';
import {ProcessListParameters} from "../../../../models/process-list-parameters";
import {ApiProcessListParametersService} from "../../../../services/api/api-process-list-parameters.service";
import {ProcessOutputList} from "../../../../models/process-output-list";
import {ApiProcessOutputListService} from "../../../../services/api/api-process-list-outputs";
import {CheckboxModule} from "primeng/checkbox";

@Component({
  selector: 'app-process-list-traceability',
  templateUrl: './process-list-traceability.component.html',
  styleUrl: './process-list-traceability.component.css',
  providers: [MessageService, ConfirmationService],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
    TooltipModule,
    DialogModule,
    ChipsModule,
    CheckboxModule,
  ],
})
export class ProcessListTraceabilityComponent implements OnDestroy, OnInit {
  licences: Client[] = [];
  ProcessListSubscription: Subscription = new Subscription();
  sensorSubscription: Subscription = new Subscription();
  processList: ProcessList[] = [];
  selectedProcess = [];
  dataSource : any = [];
  listValues: string[] = [];
  dataLoaded: boolean = false;
  processToBeCopied: any = null;
  parameters!: ProcessListParameters[];
  processOutputList!: ProcessOutputList[];

  dialogStyle = { width: '45rem', height: '15rem' };
  visible: boolean = false;
  process_name: string = "";
  selectedLicences: number[] = [];
  licencesTarget : any = [];
  enabledSave = false;
  groupIndex: number = 0;
  cliId: string = '';

  constructor(
    private apiProcessListService: ApiProcessListService,
    private authService: AuthService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
    private apiProcessListParametersService: ApiProcessListParametersService,
    private apiProcessOutputListService: ApiProcessOutputListService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.cliId = this.route.snapshot.paramMap.get('id') ?? '';

    // chargement des process de chaque licences
    this.ProcessListSubscription = this.apiProcessListService
      .getProcessList(this.cliId)
      .pipe(take(1))
      .subscribe((data: ProcessList[]) => {
        this.processList = data;
        this.dataLoaded = true;
      });
    this.licences = this.authService.getUserLicences();
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  getGroupClass(): string {
    const isEvenGroup = this.groupIndex % 2 === 0;
    this.groupIndex++; // Incrémente à chaque nouvelle ligne groupée
    return isEvenGroup ? 'bg-white' : 'bg-gray';
  }
  deleteTheProcess(processData: ProcessList){
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression DEFINITIVE du process'
        ) +
        ' "' +
        processData.tpr_name_clean +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiProcessListService.deleteProcess( processData.tpr_id).subscribe((response) => {
          if (response) {
            this.processList = this.processList.filter(
              (val: ProcessList) => val.tpr_id !== processData.tpr_id
            );
          }
        });
      },
    });
  }
  updateTheProcess(processData: ProcessList){
    this.router.navigate(['settings/process/process-traceability-edit', processData.tpr_id, this.cliId]);
  }
  goToParamProcess(processData: ProcessList){
    if(this.cliId != '') {
      this.router.navigate(['settings/process/process-list-traceability-parameters/', processData.tpr_id, this.cliId]);
    } else {
      this.router.navigate(['settings/process/process-list-traceability-parameters/', processData.tpr_id]);
    }
  }
  hideTheProcess(processData: ProcessList, processHidden: number){
    const actionTitle = (processHidden == 0 ? 'sera de nouveau visible': 'ne sera plus visible');

    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Le process'
        ) +
        ' "' +
        processData.tpr_name_clean +
        '" ' + actionTitle,
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiProcessListService.hideProcess( processData.tpr_id, processHidden).subscribe((response) => {
          if (response) {
            this.ProcessListSubscription = this.apiProcessListService
              .getProcessList(this.cliId)
              .pipe(take(1))
              .subscribe((data: ProcessList[]) => {
                this.processList = data;
                this.dataLoaded = true;
              });
          }
        });
      },
    });
  }

  showDialog(processData: ProcessList) {
    this.visible = true;
    this.process_name = processData.tpr_name_clean;
  }
  copyProcess(processData: ProcessList) {
    //data de la ligne selectionnée
    this.processToBeCopied= processData;
    //liste des paramètre du process selectioné
    const processParameters$ = this.apiProcessListParametersService
      .getProcessListParameters(this.processToBeCopied.tpr_id);
    //liste des output du process selectioné
    const processOutputList$ = this.apiProcessOutputListService
      .getProcessList(this.processToBeCopied.tpr_id);
    //liste Globale
    this.ProcessListSubscription = forkJoin([processParameters$, processOutputList$])
      .subscribe(([parameters, outputList]) => {
        // Assignation des résultats
        this.parameters = parameters;
        this.processOutputList = outputList;

        // Pousser dans dataSource une fois les données chargées
        this.dataSource.push({
          process: this.processToBeCopied,
          parameters: this.parameters,
          outputList: this.processOutputList
        });
      });
  }

  onLicenceChange(selectedValues: any) {
    this.licencesTarget = selectedValues;
    this.enabledSave = selectedValues.value.length > 0;
  }

  copyProcessOnTargets() {
    const tpr_id = this.dataSource[0]['process']['tpr_id']|| [];
    const cli_id = this.licencesTarget.value|| null;

    const rowData = {
      tpr_id: tpr_id,
      cli_id: cli_id,
    };

    this.apiProcessListParametersService.copyTrackingProcess(rowData).subscribe((response) => {
      if (response) {
        this.ProcessListSubscription = this.apiProcessListService
          .getProcessList(this.cliId)
          .pipe(take(1))
          .subscribe((data: ProcessList[]) => {
            this.processList = data;
            this.dataLoaded = true;
          });
        console.log('Update OK', rowData);
      } else {
        console.log('Update KO', response);
      }
    });

    this.visible = false;
  }

  simulateEnterKey() {
    const event = new KeyboardEvent('keydown', {
      key: 'Enter',
      code: 'Enter',
      bubbles: true,
      cancelable: true
    });
    const chipsInput = document.querySelector('p-chips input') as HTMLInputElement;
    if (chipsInput) {
      chipsInput.dispatchEvent(event);
    }
  }

  getTheList(): void {
    this.simulateEnterKey();
    const listValue = this.listValues;
    console.log(listValue)
  }

  outputSettings(processData: ProcessList) {
    if(this.cliId != '') {
      this.router.navigate(['settings/process/process-list-traceability-outputs/', processData.tpr_id, this.cliId]);
    } else {
      this.router.navigate(['settings/process/process-list-traceability-outputs/', processData.tpr_id]);
    }
   }

  ngOnDestroy(): void {
    if (this.ProcessListSubscription) {
      this.ProcessListSubscription.unsubscribe();
    }
  }
}
