export class ReceptionType {
    public id: number;
    public name: string;
    public menuId: number;

    constructor(
        id: number,
        name: string,
        menuId: number,
    ) {
        this.id = id;
        this.name = name;
        this.menuId = menuId;
    }
}