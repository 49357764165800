import {Component, OnDestroy, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {CommonModule} from "@angular/common";
import {FormBuilder, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {TableModule} from "primeng/table";
import {TranslocoModule, TranslocoService} from "@ngneat/transloco";
import {TooltipModule} from "primeng/tooltip";
import {ToastModule} from "primeng/toast";
import {PanelModule} from "primeng/panel";
import {InputNumberModule} from "primeng/inputnumber";
import {ConfirmationService, MessageService} from "primeng/api";
import {EditComponent} from "../../../core/edit/edit.component";
import {AuthService} from "../../../../services/auth/auth.service";
import {ActivatedRoute} from "@angular/router";
import {Anomalies} from "../../../../models/anomalies";
import { MultiSelectModule } from 'primeng/multiselect';
import {Client} from "../../../../models/client";
import {Subscription} from "rxjs";
import {ApiAnomaliesListService} from "../../../../services/api/api-anomalies.services";
import { ChartModule } from 'primeng/chart';
@Component({
  selector: 'app-anomalies-deletion',
  templateUrl: './anomalies-deletion.component.html',
  styleUrls: ['./anomalies-deletion.component.css'],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    TableModule,
    TranslocoModule,
    TooltipModule,
    ReactiveFormsModule,
    ToastModule,
    PanelModule,
    InputNumberModule,
    MultiSelectModule,
    ChartModule
  ],
  providers: [
    { provide: MessageService, useClass: MessageService },
    { provide: ConfirmationService, useClass: ConfirmationService },
  ],
})
export class AnomaliesDeletionComponent extends EditComponent implements OnInit, OnDestroy {

  AnomaliesSubscription: Subscription = new Subscription();

  anomaliesSelected = [];
  anomalies : Anomalies[] = [];
  anomaliesDetail: Anomalies[] = [];
  licences: Client[] = [];

  tabDetails = false;
  tabGlobal = true;
  loading: boolean = false;
  statVisible= false;
  anoIdFilter: any;

  basicData: any;
  basicOptions: any;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private messageService: MessageService,
    private apiAnomaliesService: ApiAnomaliesListService,
  ) {
    super(route);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.AnomaliesSubscription = this.apiAnomaliesService
      .getAnomaliesList()
      .subscribe((data: Anomalies[]) => {
        this.anomalies = data;
        this.dataLoaded = true;
      });

    this.licences = this.authService.getUserLicences();
  }
  canWrite(): boolean {
    return this.authService.canWrite();
  }

  deleteAnomalies(anomalies: any) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression DEFINITIVE des anomalies ?'
        ),
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiAnomaliesService.deleteAnomalies(anomalies.cli_id).subscribe((response) => {
          if (response) {
            this.anomalies = this.anomalies.filter(
              (val: Anomalies) => val.cli_id !== anomalies.cli_id
            );
            console.log('DEL OK');
          } else {
            console.log('DEL KO');
          }
        });
    this.AnomaliesSubscription = this.apiAnomaliesService
      .getAnomaliesList()
      .subscribe((data: Anomalies[]) => {
        this.anomalies = data;
      });
      },
    });
  }

  seeAnomalies(anomalie: any) {
    this.anoIdFilter = anomalie.cli_id;
    this.anomalies = [];
    this.loading = true;
    this.AnomaliesSubscription = this.apiAnomaliesService
      .getAnomaliesDetails(this.anoIdFilter)
      .subscribe((data: Anomalies[]) => {
        this.anomaliesDetail = data;
      });
    this.tabGlobal = false;
    setTimeout(() => {
      this.loading = false;
      this.tabDetails = true;
    }, 2000);
  }

  backToGlobal() {
    this.tabGlobal = true;
    this.loading = false;
    this.tabDetails = false;
    this.statVisible = false;
    this.anomaliesDetail = [];
    this.AnomaliesSubscription = this.apiAnomaliesService
      .getAnomaliesList()
      .subscribe((data: Anomalies[]) => {
        this.anomalies = data;
      });
    this.basicData = {};
  }

  stats(anomaliesDetail: any, what: any) {
    this.statVisible = true;
    const documentStyle = getComputedStyle(document.documentElement);
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.AnomaliesSubscription = this.apiAnomaliesService
      .getAnomaliesDetails(this.anoIdFilter)
      .subscribe((data: Anomalies[]) => {
        this.anomaliesDetail = data;
        let wrkNames: string[] = [];

        if (what === "zone") {
          wrkNames = this.anomaliesDetail.map(item => {
            const wrkName = item.wrk_name || item.ano_name;
            return wrkName;
          });
        } else if (what === "element") {
          wrkNames = this.anomaliesDetail.map(item => {
            const combinedName = `${item.wrk_name || ''} - ${item.obj_name || ''}`;
            return combinedName.trim() === '-' ? item.ano_name || '' : combinedName;
          });
        } else if (what === "date"){
          wrkNames = this.anomaliesDetail.map(item => {
            const createDate = item.ano_create_date
              ? item.ano_create_date.format('YYYY-MM-DD')
              : '';
            return createDate;
          });
        } else {
          wrkNames = this.anomaliesDetail.map(item => {
            const wichUser = item.usr_full_name
              ? item.usr_full_name
              : '';
            return wichUser;
          });
        }

        const WhatNames = Array.from(new Set(wrkNames));

    this.basicData = {
      labels: WhatNames,
      datasets: [
        {
          label: ' ',
          data: WhatNames.map(name => wrkNames.filter(n => n === name).length),
          backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
          borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
          borderWidth: 1
        }
      ]
    };

    this.basicOptions = {
      plugins: {
        legend: {
          labels: {
            generateLabels: () => [],
          }
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  })
  }
  ngOnDestroy() {
    this.AnomaliesSubscription.unsubscribe();
  }
}
