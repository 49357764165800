
@defer (when dataLoaded) {
    @if(canWrite()) {
        <div class="grid">
            <div class="col text-right">
                <button pButton  [label]="'Ajouter une licence' | transloco" icon="pi pi-plus" class="p-button-success" (click)="addLicence()" ></button>  
            </div>
        </div>
    }
    <p-table 
        [value]="licences" 
        styleClass="p-datatable-gridlines p-datatable-striped"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        stateStorage="session"
        stateKey="licences-list-session"
        [currentPageReportTemplate]="'Lignes {first} à {last} sur un total de {totalRecords}' | transloco"
        >
        <ng-template pTemplate="caption">
            <p-checkbox class="mb-1" [(ngModel)]="displayCanceled" [binary]="true" value="1"
            [label]="'Afficher les licences résiliées' | transloco" (click)="loadClients()"></p-checkbox>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="id">{{ 'Id' | transloco }} <p-sortIcon field="id"></p-sortIcon></th>
                <th pSortableColumn="group.name">{{ 'Groupe' | transloco }} <p-sortIcon field="group.name"></p-sortIcon></th>
                <th pSortableColumn="name">{{ 'Nom' | transloco }} <p-sortIcon field="name"></p-sortIcon></th>
                <th pSortableColumn="city">{{ 'Ville' | transloco }} <p-sortIcon field="city"></p-sortIcon></th>
                <th pSortableColumn="creationDate">{{ 'Créée le' | transloco }} <p-sortIcon field="creationDate"></p-sortIcon></th>
                <th pSortableColumn="cancelDate">{{ 'Résiliée le' | transloco }} <p-sortIcon field="cancelDate"></p-sortIcon></th>
                <th></th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter type="text" field="id" matchMode="startsWith"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter field="group.id" matchMode="in" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-multiSelect
                            [options]="groups"
                            [placeholder]="'Toutes' | transloco"
                            (onChange)="filter($event.value)"
                            optionLabel="name"
                            optionValue="id"
                            class="liste-recherche-table"
                        >
                        </p-multiSelect>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="name" matchMode="contains"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="city" matchMode="contains"></p-columnFilter>
                </th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-licence>
            <tr>
                <td>{{ licence.id }}</td>
                <td>{{ licence.group?.name }}</td>
                <td>{{ licence.name }}</td>
                <td>{{ licence.city }}</td>
                <td>{{ licence.creationDate | date : "dd/MM/yyyy"  }}</td>
                <td>{{ licence.cancelDate | date : "dd/MM/yyyy"  }}</td>
                @if(canWrite()) {
                    <td class="flex align-items-center justify-content-center">
                        <button pButton icon="pi pi-pencil" class="flex p-button-rounded p-button-success mr-2" [title]="'Modifier la licence' | transloco" (click)="editLicence(licence)"></button>
                    
                        @if (isRoot()) {
                            <button
                            pButton
                            icon="fa fa-user-secret fa-xl"
                            class="flex p-button-rounded mr-2"
                            (click)="connectV3(licence)"
                            [title]="'Se connecter à la licence' | transloco"
                            ></button>
                            <button
                            pButton
                            icon="fa fa-rotate fa-xl"
                            class="flex p-button-rounded"
                            (click)="synchro(licence)"
                            [title]="'Lancer une synchro' | transloco"
                            ></button>
                        }
                    </td>
                }
            </tr>
        </ng-template>
    </p-table>
}
@placeholder {
    <div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-toast />
<p-dialog 
    [(visible)]="modalVisible" 
    [header]="'Lancer une synchro' | transloco " 
    [modal]="true" [style]="{ width: '30rem' }">
    
    @if(synchroLaunched == 0) {
        <span class="p-text-secondary block mb-5">
            {{ 'Sélectionner le type de synchro :' | transloco }}
        </span>

        <div class="flex flex-column gap-3">
            <button pButton label="Synchro 200 (nuit)" (click)="launchSynchro(200)" class="p-button-gray" [disabled]="true"></button>
            <button pButton label="Synchro 300 (30 minutes)" (click)="launchSynchro(300)" class="p-button-gray"></button>
        </div>
    }
    @else if(synchroLaunched == 1) {
        <div class="text-center">{{ 'Synchronisation, veuillez patienter ...' | transloco }}</div>
    } 
    @else if(synchroLaunched == 2) {
        <textarea
              pInputTextarea
              class="p-inputtext w-full"
              rows="7"
              [value]="retourSynchro"
            ></textarea>
    }
    
</p-dialog>