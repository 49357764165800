import { Client } from "./client";
import moment from "moment/moment";

export class Anomalies {

  public ano_id: number = 0;
  public ano_tas_id: number = 0;
  public ano_rec_id: number = 0;
  public ano_tty_id: number = 0;
  public ano_name: string = '';
  public ano_details: string= '';
  public ano_create_date: moment.Moment | null = null;
  public ano_create_usr_name: number= 0;
  public ano_sen_id: number= 0;
  public obj_name: string= '';
  public usr_full_name: string= '';
  public client?: Client | undefined;
  public nbAnomalies?: number | undefined;
  public cli_id?: number | undefined;
  public cli_name?: string | undefined;
  public wrk_name?: string | undefined;

  constructor(init?: Partial<Anomalies>) {
    Anomalies.assignProperties(this, init);
  }

  private static assignProperties(target: any, source?: object) {
    if (source) {
      Object.assign(target, source);
    }
  }
}
