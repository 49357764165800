@defer (when dataLoaded) {
<div>
<p-panel [header]="'Configuration des Alertes' | transloco">
  <form [formGroup]="editForm">
    <div class="formgrid grid">
      <div class="col text-right" style="margin-bottom: 15px;">
        <button
          pButton
          [label]="'Ajouter une Alerte' | transloco"
          icon="pi pi-plus"
          class="p-button-success"
          (click)="addAlerte()"
          [disabled]="!addEnabled"
        ></button>
      </div>
    </div>

  <div>
    <p-table
    [value]="alertList"
    selectionMode="single"
    [tableStyle]="{'align-item': 'center', 'max-width': '100%'}"
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    [stateKey]="stateSessionKey"
    currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
    dataKey="ale_id"
    [(selection)]="selectedAlert"
    selectionMode="single"
    [paginator]="true"
    [rows]="20"
    [showCurrentPageReport]="false">

    <ng-template pTemplate="header">
      <tr>
      <th class="text-center">{{ 'Alerte concernant' | transloco }}</th>
      <th class="text-center">{{ 'Niveau d\'alerte' | transloco }}</th>
      <th class="text-center">{{ 'Fréquence' | transloco }}</th>
      <th class="text-center">{{ 'Délai en minutes' | transloco }}</th>
      <th class="text-center">{{ 'Message' | transloco }}</th>
      <th class="text-center">{{ 'Action' | transloco }}</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body"
    let-alerte
    let-ri="rowIndex"
    style="{'align-item': 'center'}"
    >
    <tr>
    <td class="text-left">{{ (alerte.ale_name).substring(2) }}</td>
    <td>
      <div class="myinline">
        <p-triStateCheckbox
        [disabled] = "true"
        [ngClass]="{
        'info-checkbox': alerte.cal_importance === 'Information',
        'important-checkbox': alerte.cal_importance === 'Important',
        'tres-important-checkbox': alerte.cal_importance === 'Très important'
        }"
        ></p-triStateCheckbox>
        <label for="tricheckbox-{{alerte.cal_importance}}" style="margin-left: 5px;">
        {{ alerte.cal_importance }}
        </label>
      </div>
    </td>
    <td class="text-left">{{ alerte.alertsFrequencies.afr_name }}</td>
    <td class="text-left">{{ alerte.cal_repeat_delay }}</td>
    <td class="text-left text-ellipsis">{{ alerte.cal_message_perso }}</td>
    <td>
      <div class="flex align-items-center justify-content-center gap-2">
        <button
          pButton
          type="button"
          icon="pi pi-pencil"
          class="flex p-button-rounded p-button-success mr-2"
          [pTooltip]="'Modifier cette Alerte' | transloco"
          tooltipPosition="left"
          (click)="onRowSelect(alerte)"
          [disabled]="!addEnabled"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-trash"
          class="flex p-button-rounded p-button-unactivate mr-2"
          (click)="deleteAlerte(alerte)"
          [pTooltip]="'Supprimer cette Alerte' | transloco"
          tooltipPosition="left"
          [disabled]="!addEnabled"
        ></button>
      </div>
    </td>
    </tr>
    </ng-template>
    </p-table>
  </div>

  <div *ngIf="displayTableau" class="py-5">
    <p-panel [header]='setting | transloco'>
      <form [formGroup]="editForm" (ngSubmit)="save()" >
        <div class="formgrid grid">
          <div class="field col-12 md:col-6 xl:col-6">
            <label for="alertsType" class="label-required">{{
            "Alerte concernant: " | transloco
            }}</label>
            <p-dropdown
            [style]="{ width: '100%' }"
            [options]="dropdownAlertsType"
            class="w-full"
            formControlName="param_alertsType"
            [(ngModel)]="selectedAlertTypeId"
            [filter]="true"
            optionLabel="label"
            optionValue="value"
            [required]="true"
            ></p-dropdown>
            @if(isErrorFormControl(editForm.controls['param_alertType'])) {
            <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
            }
          </div>
          <div class="field col-6"></div>
          <div class="field col-6">
            <label for="alertsFrequencies" class="label-required">{{
              "Quand générer la pop-up ?" | transloco
              }}</label>
            <p-dropdown
              [style]="{ width: '100%' }"
              [options]="dropdownAlertsFrequencies"
              class="w-full"
              formControlName="param_alertsFrequencies"
              [filter]="true"
              optionLabel="label"
              optionValue="value"
              [required]="true"
            ></p-dropdown>
            @if(isErrorFormControl(editForm.controls['param_alertFrequencies'])) {
            <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
            }
          </div>
          <div class="field col-6"></div>
          <div class="field col-6" *ngIf="editForm.controls['param_alertsFrequencies'].value !== 1">
            <label for="alertsDelay" class="label-required"
            >{{ "Fréquence d'apparition" | transloco }}
            <i class="fa fa-clock fa-lg text-red-500"></i
            ></label>
            <br />
            <p-inputNumber
              inputId="alertsDelayHours"
              formControlName="param_alertsDelay_H"
              suffix=" heure(s)"
              [min]="0"
              [max]="24"
              [showButtons]="true"
              [required]="true"
            ></p-inputNumber>
            <p-inputNumber
              inputId="alertsDelayMinutes"
              formControlName="param_alertsDelay_M"
              suffix=" minute(s)"
              [min]="0"
              [max]="59"
              [showButtons]="true"
              [required]="true"
              class="margin-left-10"
            ></p-inputNumber>
            </div>
          </div>
        <div class="field col-6"></div>
        <div class="formgrid grid">
        <div class="field col-6">
          <label for="messagePerso">{{ "Message" | transloco }}</label>
          <br />
          <textarea
            pInputTextarea
            formControlName="param_messagePerso"
            class="w-full mr-2"
            rows="4"
          ></textarea>
          </div>
        </div>
        <div class="flex align-items-center">
          <label class=" mr-4">{{ "Message de type:" | transloco }}</label>
          <div class="flex align-items-center mr-6">
            <p-radioButton
              name="alertGroup"
              value="Information"
              formControlName="alertGroup"
              class="mr-2"
              [pTooltip]="'Informatif' | transloco"
              [(ngModel)]="selectedBouttonRadio"
              tooltipPosition="left"
              inputId="Informatif"
            ></p-radioButton>
            <label for="circle-exclamation" class="ml-2 flex align-items-center alert-info">
              <i class="fa fa-circle-exclamation mr-2"></i>
              Information
            </label>
          </div>
          <div class="flex align-items-center mr-6">
            <p-radioButton
              name="alertGroup"
              value="Important"
              formControlName="alertGroup"
              class="mr-2"
              [pTooltip]="'Important' | transloco"
              [(ngModel)]="selectedBouttonRadio"
              tooltipPosition="left"
              inputId="Important"
            ></p-radioButton>
            <label for="triangle-exclamation" class="ml-2 flex align-items-center alert-warning">
              <i class="fa fa-triangle-exclamation mr-2"></i>
              Important
            </label>
          </div>
          <div class="flex align-items-center mr-6">
            <p-radioButton
              name="alertGroup"
              value="Très important"
              formControlName="alertGroup"
              class="mr-2"
              [pTooltip]="'Très important' | transloco"
              [(ngModel)]="selectedBouttonRadio"
              tooltipPosition="left"
              inputId="Tres_Important"
            ></p-radioButton>
            <label for="exclamation-triangle" class="ml-2 flex align-items-center alert-danger">
              <i class="fa fa-triangle-exclamation mr-2"></i>
              Très Important
            </label>
          </div>
        </div>
        <div class="grid">
          <div class="col-12"></div>
            <div class="col-12 md:col-12 xl:col-12 text-center">
              <button
                pButton
                [label]="'Annuler' | transloco"
                icon="pi pi-minus"
                class="p-button-unactivate"
                [disabled]="!cancelEnabled"
                (click)="cancel()"
              ></button>
              <button
                pButton
                [label]="'Enregistrer' | transloco"
                icon="pi pi-save"
                class="p-button-success"
                style = "margin-left: 15px"
                [disabled]="!editForm.valid || !modifInProgress"
                (click)="save()"
              ></button>
            </div>
        </div>
      </form>
    </p-panel>
    </div>
    </form>
  </p-panel>
</div>

}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast position="top-center"></p-toast>
