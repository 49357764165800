@defer (when dataLoaded) { 
  <p-table
  #dt
  [value]="orders"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateStorage="session"
  stateKey="orders-list-session"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
  [sortField]="'id'"
  [sortOrder]="-1"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="id">
        {{ "N°" | transloco }}
        <p-sortIcon field="id"></p-sortIcon>
      </th>
      <th pSortableColumn="date">
        {{ "Date" | transloco }}
        <p-sortIcon field="date"></p-sortIcon>
      </th>
      <th pSortableColumn="name">
        {{ "Nom" | transloco }}
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="first_name">
        {{ "Prénom" | transloco }}
        <p-sortIcon field="first_name"></p-sortIcon>
      </th>
      <th pSortableColumn="company">
        {{ "Société" | transloco }}
        <p-sortIcon field="company"></p-sortIcon>
      </th>
      <th pSortableColumn="status">
        {{ "Statut" | transloco }}
        <p-sortIcon field="status"></p-sortIcon>
      </th>
      <th pSortableColumn="payment_method">
        {{ "Mode de paiement" | transloco }}
        <p-sortIcon field="payment_method"></p-sortIcon>
      </th>
      <th pSortableColumn="total">
        {{ "Total" | transloco }}
        <p-sortIcon field="total"></p-sortIcon>
      </th>
      <th></th>
    </tr>
    @if(isRoot()) {
    <tr>
      <th>
        <p-columnFilter
          type="numeric"
          field="id"
          matchMode="equals"
          [showButtons]="false"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="date"
          field="dateOnly"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="name"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="first_name"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="company"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="status" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [options]="statuts"
              [placeholder]="'Tous' | transloco"
              (onChange)="filter($event.value)"
              class="liste-recherche-table"
            >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="payment_method" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [options]="modesPaiement"
              [placeholder]="'Tous' | transloco"
              (onChange)="filter($event.value)"
              class="liste-recherche-table"
            >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>      
      <th>
        <p-columnFilter
          type="numeric"
          field="total"
          matchMode="equals"
          [showButtons]="false"
        ></p-columnFilter>
      </th>
      <th></th>
    </tr>
  }
  </ng-template>
  <ng-template pTemplate="body" let-order>
    <tr>
      <td>{{ order.id }}</td>
      <td>{{ order.date | date : "dd/MM/yyyy HH:mm" }}</td>
      <td>{{ order.name }}</td>
      <td>{{ order.first_name }}</td>
      <td>{{ order.company }}</td>
      <td>
        {{ LIBELLE_STATUT[order.status] }}
      </td>
      <td>{{ LIBELLE_PAIEMENT[order.payment_method] }}</td>
      <td>{{ order.total.toFixed(2) }}</td>
      <td class="flex align-items-center">
        <button
          pButton
          icon="fa fa-magnifying-glass fa-lg"
          class="flex p-button-rounded p-button-success mr-2"
          (click)="detailOrder(order)"
          [title]="'Voir le détail' | transloco"
        ></button>
        @if(order.payment_date) {
          <button
            pButton
            icon="fa fa-file-pdf fa-lg"
            class="flex p-button-rounded p-button-gray mr-2"
            (click)="downloadInvoice(order)"
            [title]="'Télécharger la facture' | transloco"
          ></button>
        }
      </td>
    </tr>
  </ng-template>
</p-table>
 } @placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
