<div [formGroup]="editForm">
  <div class="field">
    <label for="cli_cgr_id">{{ 'Groupe principal' | transloco }}</label>
    <p-dropdown autoWidth="false" [style]="{ width: '100%' }" [options]="groups" class="w-full"
      formControlName="cli_cgr_id" [filter]="true" optionLabel="name" optionValue="id" (change)="checkMaster()"></p-dropdown>
  </div>

  
  <div class="field-checkbox flex flex-row align-items-start gap-4">
    <p-checkbox class="mb-1" [binary]="true" formControlName="ccg_is_master" value="1" (click)="checkMaster()" 
      [label]="'Master du groupe' | transloco"></p-checkbox>
      <p-toast></p-toast>

    <p-checkbox class="mb-1" [binary]="true" formControlName="ccg_tlo_share_out" value="1"
      [label]="'Partage ses lots' | transloco"></p-checkbox>

    <p-checkbox class="mb-1" [binary]="true" formControlName="ccg_tlo_share_in" value="1"
      [label]="'Récupère les lots' | transloco"></p-checkbox>
  </div>

  <label>{{ 'Groupe(s) secondaire(s)' | transloco }}</label>

  <div class="field mt-2">
    <p-pickList [source]="sourceGroups" [target]="targetGroups" [sourceHeader]="'Groupes disponibles' | transloco"
      [targetHeader]="'Groupes sélectionnés' | transloco" [dragdrop]="true" [responsive]="true"
      [showSourceControls]="false" [showTargetControls]="false" [sourceStyle]="{ height: '15rem' }"
      [targetStyle]="{ height: '15rem' }" filterBy="name">
      <ng-template let-group pTemplate="item">
        <span>
          {{ group.name }}
        </span>
      </ng-template>
    </p-pickList>
  </div>
</div>
