import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';
import {TooltipModule} from "primeng/tooltip";

import { AuthService } from '../../../../services/auth/auth.service';

import { ActivatedRoute, Router } from '@angular/router';

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { Client } from '../../../../models/client';

import { EditComponent } from '../../../core/edit/edit.component';

import {InputTextModule} from "primeng/inputtext";

import {ApiReceptionOutputListService} from "../../../../services/api/api-reception-output-list.service";
import {ReceptionOutputList} from "../../../../models/reception-output-list";
import {ConfirmationService, MessageService} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ToastModule} from "primeng/toast";
import {SessionStorageService} from "../../../../services/storage/session-storage.service";
import {GlobalDatas} from "../../../../models/global-datas";
import {ProcessListParameters} from "../../../../models/process-list-parameters";
import {ApiProcessListParametersService} from "../../../../services/api/api-process-list-parameters.service";
import {ReceptionListParameters} from "../../../../models/reception-list-parameters";
import {ApiReceptionListParametersService} from "../../../../services/api/api-reception-list-parameters.service";
import { ClientReceptionType } from '../../../../models/client-reception-type';

@Component({
  selector: 'app-process-list-reception-output-parameters',
  templateUrl: './process-list-reception-output-parameters.component.html',
  styleUrls: ['./process-list-reception-output-parameters.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    RippleModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    ConfirmDialogModule,
    ToastModule,
  ],
  providers: [
    { provide: MessageService, useClass: MessageService },
    { provide: ConfirmationService, useClass: ConfirmationService },
  ],
})
export class ProcessListReceptionOutputParametersComponent extends EditComponent implements OnInit, OnDestroy {
  ReceptionOutputTypeSubscription: Subscription = new Subscription();
  ReceptionOutputPrintTypeSubscription: Subscription = new Subscription();
  ReceptionOutputPathSubscription: Subscription = new Subscription();
  ReceptionOutputListSubscription: Subscription = new Subscription();
  ReceptionListParametersSubscription: Subscription = new Subscription();

  name: string = '';
  licences : Client[] = [];
  receptionOutputList: ReceptionOutputList[] = [];
  parameters!: ReceptionListParameters[];
  receptionType: ClientReceptionType[] = [];
  cliId: any;
  cprId: any;
  rouId: string = "";
  ropId: any;
  rpaId: any;
  selectedRows= false;

  editForm: FormGroup;
  ouiNon: { label: string, value: number }[];
  selectedOutputReceptionType: any;
  selectedOutput: any[] = [];
  path: any[] | undefined;
  dropdownPrintType: any[] | undefined;
  dropdownReceptionType: any[];
  globalDatas: GlobalDatas | null = null;

  addEnabled= true;
  cancelEnabled = false;
  displayTableau = false;
  modifInProgress = false;
  areButtonsDisabled = false;
  output: any;
  setting = '';

  yesNoOptions: { label: string, value: number }[] = [
    {label: 'Oui', value: 1},
    {label: 'Non', value: 0}
  ];

  outputParameterAddIsSelected= false;
  outputParameterIsSelected = false;
  outputIsSelected= false;

  editFormParam: FormGroup;
  dropdownParamNameAdd: any[] | undefined;
  dropdownParamFont: { label: string, value: string }[];
  dropdownParamWeight: { label: string, value: string | null }[];
  outputParameterSettedIsSelected = false;
  isTableClickable = true;

  constructor(
    private apiReceptionOutputListService: ApiReceptionOutputListService,
    private apiReceptionListParametersService: ApiReceptionListParametersService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private sessionStorageService: SessionStorageService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_receptionType: [{ value: '', disabled: true }],
      param_printType: ['', Validators.required],
      param_printName: ['', Validators.required],
      param_required: ['', Validators.required],
      param_outputPath: ['', Validators.required],
      param_order: ['', Validators.required],
    })
    this.dropdownPrintType = [];
    this.dropdownReceptionType = [];
    this.path = [];
    this.ouiNon = [];
    this.selectedOutputReceptionType = [];

    this.editFormParam = this.formBuilder.group({
      param_ParamOrder: ['1', Validators.required],
      param_ParamName: [{ value: '', disabled: true }],
      param_ParamNameAdd: [''],
      param_ParamFont: ['Arial', Validators.required],
      param_ParamSize: ['8', Validators.required],
      param_ParamWeight: [null],

    })
    this.dropdownParamNameAdd = [];
    this.dropdownParamFont = [];
    this.dropdownParamWeight = [];
  }

  override ngOnInit() {
    super.ngOnInit();

    this.globalDatas = this.sessionStorageService.get('globalDatas');
    this.cliId = this.route.snapshot.paramMap.get('cli_id') ?? '';
    this.cprId = this.route.snapshot.paramMap.get('cpr_id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];

    this.licences = this.authService.getUserLicences();

    this.ReceptionOutputListSubscription = this.apiReceptionOutputListService
      .getReceptionOutputListByLicence(this.cprId)
      .subscribe((data: ReceptionOutputList[]) => {
        this.receptionOutputList = data;
      });

    this.ReceptionOutputPrintTypeSubscription = this.apiReceptionOutputListService
      .getReceptionType(this.cliId)
      .subscribe((data => {
          this.dropdownReceptionType = data;
        })
      )

    this.dropdownPrintType = this.globalDatas?.output_reception_types;
    this.path = Array.isArray(this.globalDatas?.output_reception_path[0])
      ? this.globalDatas?.output_reception_path[0]
        .filter(item => item && item.trim() !== '')
        .map(path => ({ label: path, value: path }))
      : [];

    this.ouiNon = [
      { label: 'Oui', value : 1 },
      { label: 'Non', value : 0 },
    ];

    this.dropdownParamFont = [
      { label: 'Arial', value : 'Arial' },
      { label: 'Tahoma', value : 'Tahoma' },
    ]
    this.dropdownParamWeight= [
      { label: 'Aucun', value : null },
      { label: 'Gras', value : 'B' },
      { label: 'Souligné', value : 'U' },
      { label: 'Italique', value : 'I' },
      { label: 'Gras et souligné', value : 'BU' },
      { label: 'Gras et Italique', value : 'BI' },
      { label: 'Gras et Italique souligné', value : 'BUI' },
      { label: 'Souligné et Italique', value : 'UI' },
    ]

    this.ReceptionListParametersSubscription = this.apiReceptionListParametersService
      .getReceptionListParameters(this.cliId, this.cprId)
      .subscribe((selectedProcess: ReceptionListParameters[]) => {
        const groupedParameters = this.groupBy(selectedProcess, 'rpa_id');
        this.parameters = Object.values(groupedParameters).map(group => group[0]);

        this.sortParametersByOrder();
      });

    this.dataLoaded = true;
  }

  groupBy(array: any[], key: string): { [key: string]: any[] } {
    return array.reduce((result, currentItem) => {
      const groupKey = currentItem[key];
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentItem);
      return result;
    }, {});
  }

  sortParametersByOrder() {
    this.parameters.sort((a, b) => a.rpa_order - b.rpa_order);
  }

  accessToParameters() {
    this.router.navigate(['settings/process/process-reception/process-list-reception/', this.cliId,this.cprId]);
  }

  onRowSelect(event: any, cprId: any) {
    this.setting = "Modification de l'output";
    this.displayTableau = true;
    this.cancelEnabled = false;
    this.addEnabled = false;
    this.areButtonsDisabled = true;
    this.modifInProgress = true;
    this.output = event;
    this.rouId = event.rou_id;
    if (this.output.cli_id !== '') {
      this.ReceptionOutputListSubscription = this.apiReceptionOutputListService
        .getReceptionOutputListByLicence(this.cprId)
        .subscribe((data: ReceptionOutputList[]) => {
          this.cprId = cprId;
          for (const item of data) {
            if (item.cpr_id == event.cpr_id) {
              this.editForm.controls['param_receptionType'].setValue(event.cpr_name);
              this.editForm.controls['param_printType'].setValue(String(event.rou_rot_id));
              this.editForm.controls['param_printName'].setValue(event.rou_name);
              this.editForm.controls['param_required'].setValue(event.rou_compulsory);
              this.editForm.controls['param_outputPath'].setValue(event.rou_exec_path);
              this.editForm.controls['param_order'].setValue(event.rou_order);
            }
          }
        });
    }
  }

  addOutputReception() {
    this.setting = "Nouvel output";
    this.displayTableau = true;
    this.areButtonsDisabled = true;
    this.cancelEnabled = false;
    this.addEnabled = false;
    this.modifInProgress = true;
    this.rouId = "-1";
    this.editForm.controls['param_order'].setValue(1);
    this.editForm.controls['param_receptionType'].setValue(this.receptionOutputList[0].cpr_name);
  }
  deleteReceptionOutput(rowData: any) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression DEFINITIVE du process'
        ) +
        ' "' +
        rowData.rou_name +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiReceptionOutputListService.deleteOutput(rowData.rou_id).subscribe((response) => {
          if (response) {
            this.receptionOutputList = this.receptionOutputList.filter(
              (val: ReceptionOutputList) => val.rou_id !== rowData.rou_id
            );
            console.log('DEL OK');
          } else {
            console.log('DEL KO');
          }
        });
      },
    });
  }

  isNaN(value: any): boolean {
    return isNaN(value);
  }
  addOutputParameter(event: any, rowData: any) {
    this.setting = "Ajout/Modification de(s) paramètre(s) à l'output";
    this.addEnabled = false;
    this.outputIsSelected = false;
    this.areButtonsDisabled = true;
    this.outputParameterIsSelected = true;
    this.rouId = event.rou_id;
    this.isTableClickable = true;
    console.log('param', this.parameters)
    this.parameters.forEach(param => {
      param.checked = param.rop_rou_id === parseInt(this.rouId);
      param.rop_weight = (param.rop_weight === null || param.rop_weight === "") ? "Aucun" : param.rop_weight;
    });
    const notChecked: any[] = [];
    this.parameters.forEach(item => {
      if (!item.checked) {
        this.dropdownParamNameAdd?.push(item);
      } else{
        notChecked.push(item);
      }
    });
    const toptopSetA = new Set(notChecked.map(item => item.rop_rpa_id));
    this.dropdownParamNameAdd = this.dropdownParamNameAdd?.filter(item => !toptopSetA.has(item.rop_rpa_id));
  }
  onRowSelectParam(event: any): void {
    this.outputParameterSettedIsSelected = true;
    this.areButtonsDisabled = true;
    this.selectedRows = true;
    this.ropId = event.data.rop_id;
    this.rpaId = event.data.rpa_id;

    this.editFormParam.controls['param_ParamOrder'].setValue(event.data.rop_order);
    this.editFormParam.controls['param_ParamName'].setValue(event.data.rpa_name);
    this.editFormParam.controls['param_ParamFont'].setValue(event.data.rop_font);
    this.editFormParam.controls['param_ParamSize'].setValue(event.data.rop_size);
    this.editFormParam.controls['param_ParamWeight'].setValue(event.data.rop_weight);
  }

  addNewoutputParam() {
    this.ropId = 0;
    this.outputParameterSettedIsSelected = true;
    this.outputParameterAddIsSelected = true;

    this.editFormParam.controls['param_ParamOrder'].setValue('1');
    this.editFormParam.controls['param_ParamNameAdd'].setValue('');
    this.editFormParam.controls['param_ParamFont'].setValue('Arial');
    this.editFormParam.controls['param_ParamSize'].setValue('10');
    this.editFormParam.controls['param_ParamWeight'].setValue(null);
  }

  deleteOutputParameter(event: any): void  {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Vous êtes sur le point de supprimer ce paramètre !'),
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let formData = new FormData();
        formData.append('rop_order', '-1');
        formData.append('rop_id', event.rop_id);

        this.apiReceptionListParametersService.updateOutputReceptionParameter(formData).subscribe(() => {
          this.ReceptionListParametersSubscription = this.apiReceptionListParametersService
            .getReceptionListParameters(this.cliId, this.cprId)
            .subscribe((selectedProcess: ReceptionListParameters[]) => {
              this.parameters = selectedProcess;
              this.sortParametersByOrder();
            });
          this.cancel();
          this.cancelAdd();
        });
      }
    })
  }
  getRpaId(event:any){
    this.rpaId = event.value;
  }
  toggleTableClickable(): void {
    this.isTableClickable = !this.isTableClickable;
  }
  cancel() {
    this.addEnabled = true;
    this.outputIsSelected = false;
    this.areButtonsDisabled = false;
    this.outputParameterSettedIsSelected = false;
    this.selectedOutput = [];
    this.isTableClickable = true;
    this.outputParameterAddIsSelected = false;
    this.outputParameterIsSelected = false;
    this.dropdownParamNameAdd = [];

    this.editForm.controls['param_printType'].setValue('');
    this.editForm.controls['param_printName'].setValue('');
    this.editForm.controls['param_required'].setValue('');
    this.editForm.controls['param_outputPath'].setValue('');
    this.editForm.controls['param_order'].setValue('');

    this.editFormParam.controls['param_ParamOrder'].setValue('');
    this.editFormParam.controls['param_ParamName'].setValue('');
    this.editFormParam.controls['param_ParamFont'].setValue('');
    this.editFormParam.controls['param_ParamSize'].setValue('');
    this.editFormParam.controls['param_ParamWeight'].setValue('');
  }

  cancelAdd(){
    this.displayTableau = false;
    this.cancelEnabled = true;
    this.outputIsSelected = false;
    this.addEnabled = true;
    this.outputParameterSettedIsSelected = false;
    this.selectedOutput = [];
    this.isTableClickable = true;
    this.outputParameterAddIsSelected= false;
    this.areButtonsDisabled = this.outputParameterIsSelected;

    this.editForm.controls['param_receptionType'].setValue('');
    this.editForm.controls['param_printType'].setValue('');
    this.editForm.controls['param_printName'].setValue('');
    this.editForm.controls['param_required'].setValue('');
    this.editForm.controls['param_outputPath'].setValue('');
    this.editForm.controls['param_order'].setValue('');
  }

  save() {
    let formData = new FormData();
    let formDataparam = new FormData();
    if (this.selectedRows || this.outputParameterAddIsSelected) {
      formDataparam.append('rop_order', this.editFormParam.controls['param_ParamOrder'].value);
      if (this.selectedRows) {
        formDataparam.append('rpa_name', this.editFormParam.controls['param_ParamName'].value);
      } else {
        formDataparam.append('rpa_name', this.editFormParam.controls['param_ParamNameAdd'].value);
      }
      formDataparam.append('rop_font', this.editFormParam.controls['param_ParamFont'].value);
      formDataparam.append('rop_size', this.editFormParam.controls['param_ParamSize'].value);
      formDataparam.append('rop_weight', this.editFormParam.controls['param_ParamWeight'].value);
      formDataparam.append('rop_id', this.ropId);
      formDataparam.append('rou_id', this.rouId);
      formDataparam.append('rpa_id', this.rpaId);
      formDataparam.append('rop_ignore_empty', '0');
      this.apiReceptionListParametersService.updateOutputReceptionParameter(formDataparam).subscribe((response) => {
        if (response) {
          this.ReceptionListParametersSubscription = this.apiReceptionListParametersService
            .getReceptionListParameters(this.cliId, this.cprId)
            .subscribe((selectedProcess: ReceptionListParameters[]) => {
              this.parameters = selectedProcess;
              this.sortParametersByOrder();
            });
          this.cancel();
          this.cancelAdd();
          console.log('Update OK', formDataparam);
        } else {
          console.log('Update KO', response);
        }
      });
    } else {
      const formControls = Object.keys(this.editForm.controls);
      formControls.forEach(controlName => {
        const control = this.editForm.get(controlName);
        if (control) {
          formData.append(controlName, control.value);
        }
      });
      const rowData: any = {
        rou_id: this.rouId,
        rou_cpr_id: this.cprId,
        cli_id: this.cliId,
        rot_id: formData.get('param_printType'),
        rou_name: formData.get('param_printName'),
        rou_compulsory: formData.get('param_required'),
        rou_exec_path: formData.get('param_outputPath'),
        rou_order: formData.get('param_order')
      };

      this.apiReceptionOutputListService.updateOutput(rowData).subscribe((response) => {
        if (response) {
          this.ReceptionOutputListSubscription = this.apiReceptionOutputListService
            .getReceptionOutputListByLicence(this.cprId)
            .subscribe((data: ReceptionOutputList[]) => {
              this.receptionOutputList = data;
            });
          this.cancel();
          console.log('Update OK', rowData);
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Attention',
            detail: 'Tous les champs marqués d\'un astérisque sont obligatoires.'
          });
          console.log('Update KO', response);
        }
      });
    }
  }

  ngOnDestroy() {
    this.ReceptionOutputTypeSubscription.unsubscribe();
    this.ReceptionOutputPrintTypeSubscription.unsubscribe();
    this.ReceptionOutputPathSubscription.unsubscribe();
  }
}
