import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import moment from 'moment';

import { environment } from '../../../environments/environment';
import { Client } from '../../models/client';
import { ClientMonitoring } from '../../models/client-monitoring';
import { ClientGroup } from '../../models/client-group';
import { ClientStatsTarget } from '../../models/client-stats-target';
import { ClientParametersReception } from '../../models/client-parameters-reception';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class ApiClientsService {
  constructor(private http: HttpClient) {}

  getMonitoring(): Observable<ClientMonitoring[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_clients_monitoring.php'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataMonitoring) => {
            return new ClientMonitoring(
              new Client(
                parseInt(dataMonitoring.ccs_cli_id),
                dataMonitoring.cli_name,
                '',
                new ClientGroup(parseInt(dataMonitoring['group1_id']), dataMonitoring['group1_name']),
                [new ClientGroup(parseInt(dataMonitoring['group2_id']), dataMonitoring['group2_name'])]
              ),
              moment(new Date(dataMonitoring.ccs_maj_date)),
              parseInt(dataMonitoring.ccs_total_docs),
              parseInt(dataMonitoring.ccs_deleted_docs),
              parseFloat(dataMonitoring.ccs_db_size),
              parseInt(dataMonitoring.ccs_anomalie),
              parseInt(dataMonitoring.ccs_treatment),
              parseInt(dataMonitoring.ccs_treatment_histo),
              parseInt(dataMonitoring.ccs_lot),
              parseInt(dataMonitoring.ccs_treatment_image),
              parseInt(dataMonitoring.ccs_treatment_image_delete),
              parseInt(dataMonitoring.ccs_traca),
              parseInt(dataMonitoring.ccs_reception),
              parseInt(dataMonitoring.ccs_ls_client),
              parseInt(dataMonitoring.ccs_task),
              parseInt(dataMonitoring.ccs_temperature),
              parseInt(dataMonitoring.ccs_oil_change),
              parseInt(dataMonitoring.ccs_oil_control),
              parseInt(dataMonitoring.ccs_measure),
              parseInt(dataMonitoring.ccs_task_histo),
              parseInt(dataMonitoring.ccs_temperature_histo),
              parseInt(dataMonitoring.ccs_oil_change_histo),
              parseInt(dataMonitoring.ccs_oil_control_histo),
              parseInt(dataMonitoring.ccs_measure_histo)
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getStatTargets(): Observable<ClientStatsTarget[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_clients_stats_targets.php'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataStatsTargets) => {
            return new ClientStatsTarget(
              new Client(
                parseInt(dataStatsTargets.cli_id),
                dataStatsTargets.cli_name
              ),
              parseInt(dataStatsTargets.cpa_stats_target_tasks),
              parseInt(dataStatsTargets.cpa_stats_target_temperatures),
              parseInt(dataStatsTargets.cpa_stats_target_ncs),
              parseInt(dataStatsTargets.cpa_stats_target_treatments),
              parseInt(dataStatsTargets.cpa_stats_target_lots),
              parseInt(dataStatsTargets.cpa_stats_target_photos)
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  postStatTarget(formData: any): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_clients_stats_targets.php',
      formData
    );
  }

  getReceptionParameters(cprId:number): Observable<ClientParametersReception> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_clients_parameters_reception.php?cprId=' +
          cprId
      )
      .pipe(
        map((dataProcess: any) => {
          return new ClientParametersReception(
            parseInt(dataProcess.cpr_id),
            dataProcess.cpr_name,
            parseInt(dataProcess.cpr_tlo),
            parseInt(dataProcess.cpr_img_force),
            parseInt(dataProcess.cpr_dlc),
            parseInt(dataProcess.cpr_numlot),
            parseInt(dataProcess.cpr_temperature),
            parseInt(dataProcess.cpr_output),
            parseInt(dataProcess.cpr_supplier),
            parseInt(dataProcess.cpr_auto_close_lot),
            parseInt(dataProcess.cpr_rty_id),
            parseInt(dataProcess.cpr_cme_id)
          );
        })
      );
  }

  postReceptionParameters(formData: any): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_clients_parameters_reception.php',
      formData
    );
  }

  getClients(displayCanceled:boolean): Observable<Client[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_clients.php?displayCanceled=' + (displayCanceled ? '1' : 0)
      )
      .pipe(
        map((response: any[]) => {
          return response.map((datasClients) => {
            let oClient = new Client(
                parseInt(datasClients.cli_id),
                datasClients.cli_name,
                datasClients.cli_city,
              null,
              [],
              parseInt(datasClients.cpa_ls_module),
                parseInt(datasClients.cpa_traceability_module),
              );

            if(datasClients.cli_last_login_date) {
              oClient.lastLoginDate = moment(new Date(datasClients.cli_last_login_date));
            }

            if(datasClients.cli_create_date) {
              oClient.creationDate = moment(new Date(datasClients.cli_create_date));
            }
            if(datasClients.cli_cancel_date) {
              oClient.cancelDate = moment(new Date(datasClients.cli_cancel_date));
            }

            if(datasClients.cgr_id) {
              oClient.group = new ClientGroup(parseInt(datasClients.cgr_id), datasClients.cgr_name);
            }
            
            return oClient;
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getClient(clientId:string): Observable<Client> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_clients.php?clientId=' +
          clientId
      )
      .pipe(
        map((datasClients: any) => {
          console.log('datasClients', datasClients);
          let oClient = new Client(
              parseInt(datasClients.cli_id),
              datasClients.cli_name,
              datasClients.cli_city
            );

          if(datasClients.cli_last_login_date) {
            oClient.lastLoginDate = moment(new Date(datasClients.cli_last_login_date));
          }

          oClient.address = datasClients.cli_address;
          oClient.addressCpt = datasClients.cli_address_cpt;
          oClient.countryId = parseInt(datasClients.cli_cot_id);
          oClient.gln = datasClients.cli_gln;
          oClient.gsm = datasClients.cli_gsm;
          oClient.imagePath = datasClients.cli_image_path;
          oClient.mail = datasClients.cli_mail;
          oClient.postCode = datasClients.cli_post_code;
          oClient.tel = datasClients.cli_tel;

          oClient.isMaster = datasClients.ccg_is_master == 1;
          oClient.shareLotIn = datasClients.ccg_tlo_share_in == 1;
          oClient.shareLotOut = datasClients.ccg_tlo_share_out == 1;

          oClient.cpaOutput = datasClients.cpa_output ? parseInt(datasClients.cpa_output) : 0;
          oClient.cpaSynchroDays = parseInt(datasClients.cpa_synchro_days);
          oClient.cpaStatsMenu = datasClients.cpa_stats_menu == 1;
          oClient.cpaStatsTasks = datasClients.cpa_stats_tasks == 1;
          oClient.cpaStatsTemps = datasClients.cpa_stats_temps == 1;
          oClient.cpaStatsNcs = datasClients.cpa_stats_ncs == 1;
          oClient.cpaAuditModule = datasClients.cpa_audit_module == 1;
          oClient.cpaLsModule = datasClients.cpa_ls_module == 1;
          oClient.cpaTraceaModule = datasClients.cpa_traceability_module == 1;
          oClient.cpaAllowChangeResp = datasClients.cpa_allow_change_resp == 1;
          oClient.cpaFreeEdition = datasClients.cpa_free_edition == 1;
          oClient.cpaAllowV2 = datasClients.cpa_allow_v2 == 1;
          oClient.cpaAllowV3 = datasClients.cpa_allow_v3 == 1;

          if(datasClients.cgr_id) {
            oClient.group = new ClientGroup(parseInt(datasClients.cgr_id), datasClients.cgr_name);
          }

          oClient.groupsSecondary = datasClients.groupsSecondary.map((onegroup:any) => {
            return new ClientGroup(parseInt(onegroup.ccg_cgr_id), onegroup.cgr_name);
          });

          oClient.parametersReception = datasClients.parametersReceptions.map((oneCpr: any) => {
            return new ClientParametersReception(
              parseInt(oneCpr.cpr_id),
              oneCpr.cpr_name,
              parseInt(oneCpr.cpr_tlo),
              parseInt(oneCpr.cpr_img_force),
              parseInt(oneCpr.cpr_dlc),
              parseInt(oneCpr.cpr_numlot),
              parseInt(oneCpr.cpr_temperature),
              parseInt(oneCpr.cpr_output),
              parseInt(oneCpr.cpr_supplier),
              parseInt(oneCpr.cpr_auto_close_lot),
              parseInt(oneCpr.rty_id),
              parseInt(oneCpr.cpr_cme_id)
            );
          });

          
          if(datasClients.triggers) {
            datasClients.triggers.forEach((dataTrigger:any) => {
              oClient.triggersIds.push(parseInt(dataTrigger.ctr_tri_id));
            });
          }


          return oClient;
        })
      );
  }

  getToken(clientId: number): Observable<string> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_client_token.php?clientId=' +
          clientId
      )
      .pipe(
        map((dataUsr: any) => {
          return dataUsr;
        })
      );
  }

  postClient(formData: any): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_client.php',
      formData
    );
  }

  getClientUsers(clientId:string): Observable<Number[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_client_users.php?clientId=' + clientId
      )
      .pipe(
        map((response: any[]) => {
          return response.map((usrId) => {

            return parseInt(usrId);
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }
    
  moveUser(clientId: number, userId: number, addUser: boolean): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/post_client_user.php?clientId=' +
        clientId + '&userId='+ userId + '&addUser=' + (addUser ? 1 : 0)
    );
  }
}
