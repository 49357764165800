import { Component, OnInit, Input } from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule  } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PickListModule } from 'primeng/picklist';
import { ToastModule } from 'primeng/toast';

import { EditComponent } from '../../../../core/edit/edit.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../../services/auth/auth.service';
import { ClientGroup } from '../../../../../models/client-group';
import { ApiGroupsService } from '../../../../../services/api/api-groups';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-licences-edit-groups',
  templateUrl: './licences-edit-groups.component.html',
  styleUrl: './licences-edit-groups.component.css',
  standalone: true,
  providers: [MessageService],
  imports: [
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    FormsModule,
    PickListModule,
    ReactiveFormsModule,
    ToastModule,
    TranslocoModule
  ],
})
export class LicencesEditGroupsComponent extends EditComponent implements OnInit {
  @Input() editForm!: FormGroup;
  @Input() cliId: string = '';

  groups: ClientGroup[] = [];

  // pour les groupes secondaires
  sourceGroups: ClientGroup[] = [];
  targetGroups: ClientGroup[] = [];

  constructor(
      private apiGroupsService: ApiGroupsService,
      private authService: AuthService,
      private messageService: MessageService,
      private route: ActivatedRoute,
      private translateService: TranslocoService
    ) {
        super(route);
    }

  override ngOnInit(): void {
    this.groups = this.authService.getUserGroups();

    this.sourceGroups = JSON.parse(JSON.stringify(this.groups));
  }

  checkMaster() {
    if(this.editForm.controls['ccg_is_master'].value) {
      this.apiGroupsService.getMasterOfGroup(parseFloat(this.editForm.controls['cli_cgr_id'].value)).subscribe((clientId: string) => {
        if(clientId != '0' && clientId != this.cliId) {
          this.editForm.controls['ccg_is_master'].setValue(false);
          this.messageService.add({ 
            severity: 'error', 
            summary: this.translateService.translate('Erreur'), 
            detail: this.translateService.translate('Le groupe a déjà un master. Un seul master par groupe est autorisé.') 
          });
    
        }        
      });    
    }
  }
  
}
