import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Bandeau } from '../../models/bandeau';
import moment from "moment/moment";


@Injectable({
  providedIn: 'root',
})
export class ApiBandeauService {
  constructor(private http: HttpClient) {}

  getBandeau(): Observable<any[]> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_bandeau.php')
      .pipe(
        map((response: any[]) => {
          return response.map((dataBandeau) => {
            return new Bandeau(
              parseInt(dataBandeau.headband_id),
              dataBandeau.headband_name,
              dataBandeau.headband_message,
              moment(dataBandeau.headband_date_begin),
              moment(dataBandeau.headband_date_end),
              parseInt(dataBandeau.headband_active),
              dataBandeau.headband_flag,
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  updateBandeau(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_update_bandeau.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }
  deleteBandeau(headband_id: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
      'espace_client/delete_bandeau.php?headband_id=' +
      headband_id
    );
  }
}
