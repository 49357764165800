import { Component } from '@angular/core';

import { ApiIndicatorsService } from '../../../../services/api/api-indicators.service';
import { AffectUsersComponent } from '../../../global/affect-users/affect-users.component';

@Component({
  selector: 'app-indicators-users',
  standalone: true,
  imports: [
    AffectUsersComponent
  ],
  template: `
    <app-affect-users
      [getMethod]="apiIndicatorsService.getIndicatorUsers.bind(apiIndicatorsService)"
      [moveMethod]="apiIndicatorsService.moveUser.bind(apiIndicatorsService)"
    ></app-affect-users>
  `
})
export class IndicatorsUsersComponent  {
  constructor(
    protected apiIndicatorsService: ApiIndicatorsService,
  ) {}
}
