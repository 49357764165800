@defer (when dataLoaded) {
<p-table
  #dt
  [value]="statTargets"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateStorage="session"
  stateKey="stats-targets-list-session"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="client.name">{{ 'Licence' | transloco }} <p-sortIcon field="client.name"></p-sortIcon></th>
      <th pSortableColumn="tasks">
        {{ "Tâches" | transloco }} 
        <p-sortIcon field="tasks"></p-sortIcon>
      </th>
      <th pSortableColumn="temperatures">
        {{ "Températures" | transloco }}
        <p-sortIcon field="temperatures"></p-sortIcon>
      </th>
      <th pSortableColumn="ncs">
        {{ "Non conformités" | transloco }}
        <p-sortIcon field="ncs"></p-sortIcon>
      </th>
      <th pSortableColumn="treatments">
        {{ "Traitements clos" | transloco }}
        <p-sortIcon field="treatments"></p-sortIcon>
      </th>
      <th pSortableColumn="lots">
        {{ "Lots rattachés" | transloco }}
        <p-sortIcon field="lots"></p-sortIcon>
      </th>
      <th pSortableColumn="photos">
        {{ "Photos rattachées" | transloco }}
        <p-sortIcon field="photos"></p-sortIcon>
      </th>
      <th></th>
    </tr>
    <tr>
      <th>
          <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect 
                      [ngModel]="value" 
                      [options]="licences" 
                      placeholder="Toutes" 
                      (onChange)="filter($event.value)" 
                      optionLabel="displayName" 
                      optionValue="id"
                      class="liste-recherche-table"
                      >
                  </p-multiSelect>
              </ng-template>
          </p-columnFilter>
      </th>
      <th colspan="7"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-statTarget>
    <tr>
      <td>{{ statTarget.client.name }}</td>
      <td class="text-center">
        <p-inputNumber
          class="table-input-number"
          [(ngModel)]="statTarget.tasks"
          buttonLayout="horizontal" 
          suffix="%"
          [min]="0"
          [max]="100"
          [showButtons]="true"
          [required]="true"
          decrementButtonClass="p-button-lightgray"
          incrementButtonClass="p-button-lightgray"
          incrementButtonIcon="pi pi-plus" 
          decrementButtonIcon="pi pi-minus" 
        ></p-inputNumber>
      </td>
      <td class="text-center">
        <p-inputNumber
          class="table-input-number"
          [(ngModel)]="statTarget.temperatures"
          buttonLayout="horizontal" 
          suffix="%"
          [min]="0"
          [max]="100"
          [showButtons]="true"
          [required]="true"
          decrementButtonClass="p-button-lightgray"
          incrementButtonClass="p-button-lightgray"
          incrementButtonIcon="pi pi-plus" 
          decrementButtonIcon="pi pi-minus" 
        ></p-inputNumber>
      </td>
      <td class="text-center">
        <p-inputNumber
          class="table-input-number"
          [(ngModel)]="statTarget.ncs"
          buttonLayout="horizontal" 
          suffix="%"
          [min]="0"
          [max]="100"
          [showButtons]="true"
          [required]="true"
          decrementButtonClass="p-button-lightgray"
          incrementButtonClass="p-button-lightgray"
          incrementButtonIcon="pi pi-plus" 
          decrementButtonIcon="pi pi-minus" 
        ></p-inputNumber>
      </td>
      <td class="text-center">
        <p-inputNumber
          class="table-input-number"
          [(ngModel)]="statTarget.treatments"
          buttonLayout="horizontal" 
          suffix="%"
          [min]="0"
          [max]="100"
          [showButtons]="true"
          [required]="true"
          decrementButtonClass="p-button-lightgray"
          incrementButtonClass="p-button-lightgray"
          incrementButtonIcon="pi pi-plus" 
          decrementButtonIcon="pi pi-minus" 
        ></p-inputNumber>
      </td>
      <td class="text-center">
        <p-inputNumber
          class="table-input-number"
          [(ngModel)]="statTarget.lots"
          buttonLayout="horizontal" 
          suffix="%"
          [min]="0"
          [max]="100"
          [showButtons]="true"
          [required]="true"
          decrementButtonClass="p-button-lightgray"
          incrementButtonClass="p-button-lightgray"
          incrementButtonIcon="pi pi-plus" 
          decrementButtonIcon="pi pi-minus" 
        ></p-inputNumber>
      </td>
      <td class="text-center">
        <p-inputNumber
          class="table-input-number"
          [(ngModel)]="statTarget.photos"
          buttonLayout="horizontal" 
          suffix="%"
          [min]="0"
          [max]="100"
          [showButtons]="true"
          [required]="true"
          decrementButtonClass="p-button-lightgray"
          incrementButtonClass="p-button-lightgray"
          incrementButtonIcon="pi pi-plus" 
          decrementButtonIcon="pi pi-minus" 
        ></p-inputNumber>
      </td>
      <td class="flex align-items-center justify-content-center">
        @if(canWrite()) {
        <button
          pButton
          icon="pi pi-save"
          class="flex p-button-rounded p-button-success mr-2"
          (click)="saveStatTarget(statTarget)"
        ></button>
      }
      </td>
    </tr>
  </ng-template>
</p-table>

<br>
<p-panel header="Aide" >
    Pour désactiver l'affichage de l'objectif renseignez la valeur 0.
</p-panel>

<p-toast />
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
