import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder, Validators, ReactiveFormsModule  } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'; 
import { EditComponent } from '../../../core/edit/edit.component';
import { ApiClientsService } from '../../../../services/api/api-clients.service';
import { TabViewModule } from 'primeng/tabview';
import { LicencesEditDetailsComponent } from './licences-edit-details/licences-edit-details.component';
import { LicencesEditGroupsComponent } from './licences-edit-groups/licences-edit-groups.component';
import { ClientGroup } from '../../../../models/client-group';
import { LicencesEditStatsComponent } from './licences-edit-stats/licences-edit-stats.component';
import { LicencesEditOptionsComponent } from './licences-edit-options/licences-edit-options.component';
import { LicencesEditLogoComponent } from './licences-edit-logo/licences-edit-logo.component';
import { ReceptionType } from '../../../../models/reception-type';
import { SessionStorageService } from '../../../../services/storage/session-storage.service';
import { Subscription } from 'rxjs';
import { Client } from '../../../../models/client';
import { ClientParametersReception } from '../../../../models/client-parameters-reception';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AlertsForUsersAddComponent } from '../../alerts-for-users-add/alerts-for-users-add.component';
import { AuthService } from '../../../../services/auth/auth.service';
import { SensorsListComponent } from '../../sensors/sensors-list/sensors-list.component';
import { Trigger } from '../../../../models/trigger';
import { ProcessListTraceabilityComponent } from '../../process/process-list-traceability/process-list-traceability.component';
import { LicencesEditUsersComponent } from './licences-edit-users/licences-edit-users.component';
import { environment } from '../../../../../environments/environment';
import { LicencesEditCloneComponent } from './licences-edit-clone/licences-edit-clone.component';

@Component({
  selector: 'app-licences-edit',
  templateUrl: './licences-edit.component.html',
  styleUrl: './licences-edit.component.css',
  standalone: true,
  imports: [
    AlertsForUsersAddComponent,
    ButtonModule,
    LicencesEditCloneComponent,
    LicencesEditDetailsComponent,
    LicencesEditGroupsComponent,
    LicencesEditLogoComponent,
    LicencesEditStatsComponent,
    LicencesEditOptionsComponent,
    LicencesEditUsersComponent,
    FormsModule,
    PanelModule,
    ProcessListTraceabilityComponent,
    ReactiveFormsModule,
    SensorsListComponent,
    TabViewModule,
    ToastModule,
    TranslocoModule,
  ],
  providers:[MessageService]
})
export class LicencesEditComponent extends EditComponent implements OnInit, OnDestroy {
  cliId: string = '';
  editForm: FormGroup;

  saveError:boolean = false;
  receptionTypes: ReceptionType[] = [];
  activeTabIndex: number = 0;
  triggers: Trigger[] = [];

  licenceSubscription:Subscription = new Subscription();

  cloneLicence:boolean = false;

  @ViewChild(LicencesEditGroupsComponent) groupsComponent!: LicencesEditGroupsComponent;
  @ViewChild(LicencesEditStatsComponent) statsComponent!: LicencesEditStatsComponent;
  @ViewChild(LicencesEditOptionsComponent) optionsComponent!: LicencesEditOptionsComponent;
  @ViewChild(LicencesEditLogoComponent) logoComponent!: LicencesEditLogoComponent;
  
  constructor(
    private apiClientsService: ApiClientsService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private translocoService: TranslocoService,
  ) {
      super(route);
      
      let objForm: any = {
        cli_name: ['', Validators.required],
        cli_tel: [''],
        cli_gsm: [''],
        cli_mail: ['', Validators.email],
        cli_gln: [null],
        cli_address: [''],
        cli_address_cpt: [''],
        cli_post_code: [''],
        cli_city: ['', Validators.required],
        cli_cot_id: ['', Validators.required],
        cli_cgr_id: [''],
        cpa_stats_menu: [false],
        cpa_stats_tasks: [false],
        cpa_stats_temps: [false],
        cpa_stats_ncs: [false],
        cpa_audit_module: [false],
        cpa_ls_module: [false],
        cpa_traceability_module: [false],
        cpa_allow_change_resp: [false],
        cpa_free_edition: [false],
        cpa_allow_v2: [false],
        cpa_allow_v3: [true],
        cpa_output: ['', Validators.required],
        cpa_synchro_days: ['30', Validators.required],
        cli_image_path: [''],
        cli_logo_changed: ['0'],

        ccg_is_master: [false],
        ccg_tlo_share_out: [false],
        ccg_tlo_share_in: [false],
        
        cpa_stats_treatments: [''],
        cpa_stats_photos: [''],
        cpa_stats_lots: [''],

        pms_cli_id: [''], //licence modèle
      }

      let globalDatas = this.sessionStorageService.get('globalDatas');
      this.receptionTypes = globalDatas.receptionTypes;
      this.triggers = globalDatas.triggers;

      this.receptionTypes.forEach((oneRecType : ReceptionType) => {
        objForm['rty_'+oneRecType.id] = false;
        objForm['cpr_cme_id_'+oneRecType.id] = oneRecType.menuId.toString();
        objForm['cpr_name_'+oneRecType.id] = oneRecType.name;        
        objForm['cpr_id_'+oneRecType.id] = -1;
        objForm['cpr_img_force_'+oneRecType.id] = false;
        objForm['cpr_temperature_'+oneRecType.id] = false;
        objForm['cpr_supplier_'+oneRecType.id] = false;
        objForm['cpr_output_'+oneRecType.id] = false;
        objForm['cpr_dlc_'+oneRecType.id] = 0;
        objForm['cpr_numlot_'+oneRecType.id] = 0;
        objForm['cpr_tlo_'+oneRecType.id] = 0;
        objForm['cpr_auto_close_lot_'+oneRecType.id] = '';
      });

      this.triggers.forEach((oneTrigger : Trigger) => {
        objForm['trigger_'+oneTrigger.id] = false;
      });
     

      this.editForm = this.formBuilder.group(objForm); 
   }

  changeLicenceModele() {
    this.cloneLicence = (this.editForm.controls['pms_cli_id'].value ?? '') != '';
  }

  setActiveTab(fragment: string): void {
    const tabMapping: { [key: string]: number } = {
      'process': 6,
      'sensors': 7,      
    };

    if (tabMapping.hasOwnProperty(fragment)) {
      this.activeTabIndex = tabMapping[fragment];
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    
    this.cliId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
    
    this.dataLoaded = true;

    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        this.setActiveTab(fragment);
      }
    });
 
    if(this.cliId != '') {
      // recuperer les données de la licence   
      this.licenceSubscription = this.apiClientsService.getClient(this.cliId).subscribe((oneClient: Client) => {
        this.editForm.controls['cli_name'].setValue(oneClient.name);
        this.editForm.controls['cli_tel'].setValue(oneClient.tel);
        this.editForm.controls['cli_gsm'].setValue(oneClient.gsm);
        this.editForm.controls['cli_mail'].setValue(oneClient.mail);
        this.editForm.controls['cli_gln'].setValue(oneClient.gln == "" ? null : oneClient.gln);
        this.editForm.controls['cli_address'].setValue(oneClient.address);
        this.editForm.controls['cli_address_cpt'].setValue(oneClient.addressCpt);
        this.editForm.controls['cli_post_code'].setValue(oneClient.postCode);
        this.editForm.controls['cli_city'].setValue(oneClient.city);
        this.editForm.controls['cli_cot_id'].setValue(oneClient.countryId);
        this.editForm.controls['cli_image_path'].setValue(oneClient.imagePath);

        this.editForm.controls['ccg_is_master'].setValue(oneClient.isMaster);
        this.editForm.controls['ccg_tlo_share_out'].setValue(oneClient.shareLotOut);
        this.editForm.controls['ccg_tlo_share_in'].setValue(oneClient.shareLotIn);

        //logo
        if(oneClient.imagePath != '') {
          this.editForm.controls['cli_image_path'].setValue(oneClient.imagePath);
          this.logoComponent.currLogoUrl = environment.v2URL + oneClient.imagePath;
        }

        if(oneClient.group) {
          this.editForm.controls['cli_cgr_id'].setValue(oneClient.group.id);
        }

        if(oneClient.groupsSecondary) {
          this.groupsComponent.targetGroups = oneClient.groupsSecondary;
          this.groupsComponent.sourceGroups = this.groupsComponent.sourceGroups.filter(
            (sourceGroup) => !this.groupsComponent.targetGroups.some(
              (targetGroup) => targetGroup.id === sourceGroup.id // Assurez-vous que `id` est la clé unique pour comparer les groupes
            )
          );
        }

        oneClient.triggersIds.forEach((oneTriggerId:number) => {
          this.editForm.controls['trigger_'+oneTriggerId].setValue(true);
        });

        //parametres
        this.editForm.controls['cpa_output'].setValue(oneClient.cpaOutput);
        this.editForm.controls['cpa_synchro_days'].setValue(oneClient.cpaSynchroDays);
        this.editForm.controls['cpa_stats_menu'].setValue(oneClient.cpaStatsMenu);
        this.editForm.controls['cpa_stats_tasks'].setValue(oneClient.cpaStatsTasks);
        this.editForm.controls['cpa_stats_temps'].setValue(oneClient.cpaStatsTemps);
        this.editForm.controls['cpa_stats_ncs'].setValue(oneClient.cpaStatsNcs);
        this.editForm.controls['cpa_audit_module'].setValue(oneClient.cpaAuditModule);
        this.editForm.controls['cpa_ls_module'].setValue(oneClient.cpaLsModule);
        this.editForm.controls['cpa_traceability_module'].setValue(oneClient.cpaTraceaModule);
        this.editForm.controls['cpa_allow_change_resp'].setValue(oneClient.cpaAllowChangeResp);
        this.editForm.controls['cpa_free_edition'].setValue(oneClient.cpaFreeEdition);
        this.editForm.controls['cpa_allow_v2'].setValue(oneClient.cpaAllowV2);
        this.editForm.controls['cpa_allow_v3'].setValue(oneClient.cpaAllowV3);

        this.statsComponent.clicModuleStats();
        this.optionsComponent.clicModuleTraceability();

        //paramètres de reception
        oneClient.parametersReception.forEach((oneRec : ClientParametersReception) => {
          this.editForm.controls['rty_'+oneRec.recTypeId].setValue(true);
          this.editForm.controls['cpr_cme_id_'+oneRec.recTypeId].setValue(oneRec.clientMenuId);    
          this.editForm.controls['cpr_name_'+oneRec.recTypeId].setValue(oneRec.name);        
          this.editForm.controls['cpr_id_'+oneRec.recTypeId].setValue(oneRec.id);
          this.editForm.controls['cpr_img_force_'+oneRec.recTypeId].setValue(oneRec.imageForce == 1);
          this.editForm.controls['cpr_temperature_'+oneRec.recTypeId].setValue(oneRec.temperature == 1);
          this.editForm.controls['cpr_supplier_'+oneRec.recTypeId].setValue(oneRec.supplier == 1);
          this.editForm.controls['cpr_output_'+oneRec.recTypeId].setValue(oneRec.output == 1);
          this.editForm.controls['cpr_dlc_'+oneRec.recTypeId].setValue(oneRec.dlc);
          this.editForm.controls['cpr_numlot_'+oneRec.recTypeId].setValue(oneRec.numLot);
          this.editForm.controls['cpr_tlo_'+oneRec.recTypeId].setValue(oneRec.tlo);
          this.editForm.controls['cpr_auto_close_lot_'+oneRec.recTypeId].setValue(oneRec.autoCloseLot);

          this.optionsComponent.clicModule(oneRec.recTypeId);
        }); 
      });  
    }
  }
  ngOnDestroy() {
      this.licenceSubscription.unsubscribe();
  }

  save() {    
    const tabSousGroupes = this.groupsComponent.targetGroups.map((oneGroup:ClientGroup) => { return oneGroup.id; })

    let formData = new FormData();

    formData.append('pms_cli_id', this.editForm.controls['pms_cli_id'].value); // clone licence

    formData.append('cli_id', this.cliId);
    formData.append('cli_name', this.editForm.controls['cli_name'].value);
    formData.append('cli_tel', this.editForm.controls['cli_tel'].value);
    formData.append('cli_gsm', this.editForm.controls['cli_gsm'].value);
    formData.append('cli_mail', this.editForm.controls['cli_mail'].value);
    formData.append('cli_gln', this.editForm.controls['cli_gln'].value ?? '');
    formData.append('cli_address', this.editForm.controls['cli_address'].value);
    formData.append('cli_address_cpt', this.editForm.controls['cli_address_cpt'].value);
    formData.append('cli_post_code', this.editForm.controls['cli_post_code'].value);
    formData.append('cli_city', this.editForm.controls['cli_city'].value);
    formData.append('cli_cot_id', this.editForm.controls['cli_cot_id'].value);
    formData.append('cli_image_path', this.editForm.controls['cli_image_path'].value);
    formData.append('cli_logo_changed', this.editForm.controls['cli_logo_changed'].value);

    formData.append('cli_cgr_id', this.editForm.controls['cli_cgr_id'].value);
    formData.append('ccg_cgr_ids', tabSousGroupes.join(','));

    formData.append('cpa_output', this.editForm.controls['cpa_output'].value);
    formData.append('cpa_synchro_days', this.editForm.controls['cpa_synchro_days'].value);
    formData.append('cpa_stats_menu', (this.editForm.controls['cpa_stats_menu'].value ? '1' : '0'));
    formData.append('cpa_stats_tasks', (this.editForm.controls['cpa_stats_tasks'].value ? '1' : '0'));
    formData.append('cpa_stats_temps', (this.editForm.controls['cpa_stats_temps'].value ? '1' : '0'));
    formData.append('cpa_stats_ncs', (this.editForm.controls['cpa_stats_ncs'].value ? '1' : '0'));
    formData.append('cpa_audit_module', (this.editForm.controls['cpa_audit_module'].value ? '1' : '0'));
    formData.append('cpa_ls_module', (this.editForm.controls['cpa_ls_module'].value ? '1' : '0'));
    formData.append('cpa_traceability_module', (this.editForm.controls['cpa_traceability_module'].value ? '1' : '0'));
   
    formData.append('cpa_allow_change_resp', (this.editForm.controls['cpa_allow_change_resp'].value ? '1' : '0'));
    formData.append('cpa_free_edition', (this.editForm.controls['cpa_free_edition'].value ? '1' : '0'));
    formData.append('cpa_allow_v2', (this.editForm.controls['cpa_allow_v2'].value ? '1' : '0'));
    formData.append('cpa_allow_v3', (this.editForm.controls['cpa_allow_v3'].value ? '1' : '0')); 

    formData.append('ccg_is_master', (this.editForm.controls['ccg_is_master'].value ? '1' : '0'));
    formData.append('ccg_tlo_share_out', (this.editForm.controls['ccg_tlo_share_out'].value ? '1' : '0'));
    formData.append('ccg_tlo_share_in', (this.editForm.controls['ccg_tlo_share_in'].value ? '1' : '0'));

    //process de reception
    this.receptionTypes.forEach((oneRecType : ReceptionType) => {
      formData.append('rty_'+oneRecType.id, (this.editForm.controls['rty_'+oneRecType.id].value ? '1' : '0'));
      formData.append('cpr_id_'+oneRecType.id, this.editForm.controls['cpr_id_'+oneRecType.id].value);     
      formData.append('cpr_cme_id_'+oneRecType.id, this.editForm.controls['cpr_cme_id_'+oneRecType.id].value);
      formData.append('cpr_name_'+oneRecType.id, this.editForm.controls['cpr_name_'+oneRecType.id].value);
      formData.append('cpr_img_force_'+oneRecType.id, (this.editForm.controls['cpr_img_force_'+oneRecType.id].value ? '1' : '0'));
      formData.append('cpr_temperature_'+oneRecType.id, (this.editForm.controls['cpr_temperature_'+oneRecType.id].value ? '1' : '0'));
      formData.append('cpr_supplier_'+oneRecType.id, (this.editForm.controls['cpr_supplier_'+oneRecType.id].value ? '1' : '0'));
      formData.append('cpr_output_'+oneRecType.id, (this.editForm.controls['cpr_output_'+oneRecType.id].value ? '1' : '0'));     
      formData.append('cpr_dlc_'+oneRecType.id, this.editForm.controls['cpr_dlc_'+oneRecType.id].value);
      formData.append('cpr_numlot_'+oneRecType.id, this.editForm.controls['cpr_numlot_'+oneRecType.id].value);
      formData.append('cpr_tlo_'+oneRecType.id, this.editForm.controls['cpr_tlo_'+oneRecType.id].value);
      formData.append('cpr_auto_close_lot_'+oneRecType.id, this.editForm.controls['cpr_auto_close_lot_'+oneRecType.id].value);
    });

    //triggers
    this.triggers.forEach((oneTrigger : Trigger) => {
      formData.append('trigger_'+oneTrigger.id, (this.editForm.controls['trigger_'+oneTrigger.id].value ? '1' : '0'));
    });
        
    this.apiClientsService.postClient(formData)
    .subscribe(response => {
      this.saveError = !response;

      if(response && response.client) {
        if(this.cliId == '') {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translocoService.translate("Succès"), 
            detail: this.translocoService.translate("Licence ajoutée avec succès") 
          });

          //on actualise la liste des licences dans la session
          this.authService.setLicences(response.licences);

          this.router.navigate(['settings/licences-edit', response.client.cli_id]);
        } else {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translocoService.translate("Succès"), 
            detail: this.translocoService.translate("Enregistrement effectué avec succès") 
          });
        }
      }
    });
  }
}
