import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel';

import { EditComponent } from '../../../../core/edit/edit.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ActivatedRoute } from '@angular/router';
import { SessionStorageService } from '../../../../../services/storage/session-storage.service';
import { Output } from '../../../../../models/output';
import { CardModule } from 'primeng/card';
import { Trigger } from '../../../../../models/trigger';

@Component({
  selector: 'app-licences-edit-options',
  templateUrl: './licences-edit-options.component.html',
  styleUrl: './licences-edit-options.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    CheckboxModule,
    DropdownModule,
    FormsModule,
    InputNumberModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
})
export class LicencesEditOptionsComponent extends EditComponent implements OnInit {
  @Input() editForm!: FormGroup;
  @Input() cliId: string = '';

  hideHomeReceipt: boolean = false;
  detailTracea: boolean = false;

  lstModules: Array<any> = [
    {id: 1, display: false, name:'Réception de marchandises'},
    {id: 2, display: false, name:'Réception de marchandises'},
    {id: 3, display: false, name:'Contrôle picking'},
    {id: 4, display: false, name:'Ouvert le'},
  ];

  outputs: Output[] = [];
  triggers: Trigger[] = [];

  receptionsOptions: Array<any> = [
    {
      "id": 0,
      "name": "Masqué"
    },
    {
      "id": 1,
      "name": "Affiché et facultatif"
    },
    {
      "id": 2,
      "name": "Affiché et obligatoire"
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
  ) {
    super(route);
  }

  override ngOnInit(): void {
    let globalDatas = this.sessionStorageService.get('globalDatas');

    this.outputs = globalDatas.outputs;
    this.triggers = globalDatas.triggers;

    if (this.cliId == '') {
      //préselection de l'output aucun
      const objAucun = this.outputs.find((oneOutput: Output) => oneOutput.name == 'Aucun');

      if (objAucun) {
        this.editForm.controls['cpa_output'].setValue(objAucun.id);
      }
    }

    this.clicModuleTraceability();
  }


  clicModuleTraceability() {
    this.detailTracea = this.editForm.controls['cpa_traceability_module'].value;
    if(this.detailTracea) {
      //si coché on décoche la reception globale
      this.editForm.controls['rty_1'].setValue(false);
    } else{
      this.editForm.controls['rty_2'].setValue(false);
      this.editForm.controls['rty_3'].setValue(false);
      this.editForm.controls['rty_4'].setValue(false);
    }
    this.hideHomeReceipt = this.detailTracea;
    this.displayReceiptHome();
  }

  clicModuleReceipt() {
    this.displayReceiptHome();
  }

  clicModule(moduleId:number) {
    let moduleObj = this.lstModules.find((oneModule:any) => oneModule.id == moduleId);
    moduleObj.display = this.editForm.controls['rty_'+moduleId].value;
  }

  displayReceiptHome() {
    //on affiche la reception de l'accueil si traça non cochée
    let module1 = this.lstModules.find((oneModule:any) => oneModule.id == 1);
    module1.display = this.editForm.controls['rty_1'].value;
  }
}
