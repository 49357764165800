import { Component, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../../services/auth/auth.service';

import { Client } from '../../../../models/client';

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';
import {ButtonModule} from "primeng/button";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {TableModule} from "primeng/table";
import {TooltipModule} from "primeng/tooltip";
import {DropdownModule} from "primeng/dropdown";
import {ToastModule} from "primeng/toast";
import {PanelModule} from "primeng/panel";
import {InputNumberModule} from "primeng/inputnumber";
import {ConfirmationService, MessageService} from "primeng/api";
import {EditComponent} from "../../../core/edit/edit.component";
import {ActivatedRoute} from "@angular/router";
import {Bandeau} from "../../../../models/bandeau";
import {ApiBandeauService} from "../../../../services/api/api-bandeau.service";
import {CalendarModule} from "primeng/calendar";
import moment from 'moment';

@Component({
  selector: 'app-bandeau-list',
  templateUrl: './bandeau-list.component.html',
  styleUrls: ['./bandeau-list.component.css'],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    TableModule,
    TranslocoModule,
    TooltipModule,
    DropdownModule,
    ReactiveFormsModule,
    ToastModule,
    PanelModule,
    InputNumberModule,
    CalendarModule,
  ],
  providers: [
    { provide: MessageService, useClass: MessageService },
    { provide: ConfirmationService, useClass: ConfirmationService },
  ],
})
export class BandeauListComponent extends EditComponent implements OnInit, OnDestroy  {

  BandeauSubscription: Subscription = new Subscription();

  licences: Client[] = [];
  bandeauSelected = [];
  bandeauList : Bandeau[] = [];
  bandeauId: any;
  dateDebut: any;
  dateFin: any;

  editForm: FormGroup;
  ouiNon: { label: string, value: number }[];

  addBandeauDisabled = false;
  areButtonsDisabled = false;
  displayTableau = false;
  cancelButtonDisabled = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private messageService: MessageService,
    private apiBandeauService: ApiBandeauService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_bandeauTitre: ['', Validators.required],
      param_bandeauMessage: ['', Validators.required],
      param_bandeauDateDebut: [null, Validators.required],
      param_bandeauDateFin: [null, Validators.required],
      param_bandeauActif: ['', Validators.required],
    })
    this.ouiNon = [];
  }

  override ngOnInit() {
    super.ngOnInit();

    this.BandeauSubscription = this.apiBandeauService
      .getBandeau()
      .subscribe((data: Bandeau[]) => {
        this.bandeauList = data;
        this.dataLoaded = true;
      });

    this.ouiNon = [
      { label: 'Oui', value : 1 },
      { label: 'Non', value : 0 },
    ];

    this.licences = this.authService.getUserLicences();
  }

  onRowSelect(event: any, bandeau_id: any) {
    this.displayTableau = true;
    this.addBandeauDisabled = true;
    this.bandeauId = bandeau_id;

    this.editForm.controls['param_bandeauTitre'].setValue(event.headband_name);
    this.editForm.controls['param_bandeauMessage'].setValue(event.headband_message);
    this.editForm.controls['param_bandeauDateDebut'].setValue(event.headband_date_begin.toDate());
    this.editForm.controls['param_bandeauDateFin'].setValue(event.headband_date_end.toDate());
    this.editForm.controls['param_bandeauActif'].setValue(event.headband_active);
  }
  changeStartPeriod() {
    let tmpDate: Date = new Date(this.editForm.controls['param_bandeauDateDebut'].value);
    tmpDate.setDate(tmpDate.getDate());
    this.editForm.controls['param_bandeauDateDebut'].setValue(tmpDate);
    this.dateDebut = tmpDate;console.log(  this.dateDebut)
  }
  changeEndPeriod() {
    let tmpDate: Date = new Date(this.editForm.controls['param_bandeauDateFin'].value);
    tmpDate.setDate(tmpDate.getDate());
    this.editForm.controls['param_bandeauDateFin'].setValue(tmpDate);
    this.dateFin = tmpDate;console.log(  this.dateFin)
  }

  displayAddBandeau() {
    this.displayTableau = true;
    this.addBandeauDisabled = true;
    this.bandeauId = -1;
    const today = new Date();

    this.editForm.controls['param_bandeauTitre'].setValue('');
    this.editForm.controls['param_bandeauMessage'].setValue('');
    this.editForm.controls['param_bandeauDateDebut'].setValue(today);
    this.editForm.controls['param_bandeauDateFin'].setValue(today);
    this.editForm.controls['param_bandeauActif'].setValue(0);
  }

  deleteBandeau(id: any) {console.log(id)
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression DEFINITIVE cette alerte ?'
        ) ,
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiBandeauService.deleteBandeau(id).subscribe((response) => {
          if (response) {
            this.bandeauList = this.bandeauList.filter(
              (val: Bandeau) => val.headband_id !== id
            );
            console.log('DEL OK');
          } else {
            console.log('DEL KO');
          }
        });
      },
    });
  }

  cancel() {
    this.displayTableau = false;
    this.addBandeauDisabled = false;
  }

  save() {
    let formData = new FormData();
    const formControls = Object.keys(this.editForm.controls);
    formControls.forEach(controlName => {
      const control = this.editForm.get(controlName);
      if (control) {
        formData.append(controlName, control.value);
      }
    });

    const rowData: any = {
      headband_id: this.bandeauId,
      headband_name: formData.get('param_bandeauTitre'),
      headband_message: formData.get('param_bandeauMessage'),
      headband_date_begin: moment(this.dateDebut).format('DD/MM/YYYY'),
      headband_date_end: moment(this.dateFin).format('DD/MM/YYYY'),
      headband_active: formData.get('param_bandeauActif')
    };

    this.apiBandeauService.updateBandeau(rowData).subscribe((response) => {
      if (response) {
        this.BandeauSubscription = this.apiBandeauService
          .getBandeau()
          .subscribe((data: Bandeau[]) => {
            this.bandeauList = data;
          });
        this.cancel();
        console.log('Update OK');
      }
      else{
        console.log('Update KO');
      }
    });
  }
  ngOnDestroy() {
    this.BandeauSubscription.unsubscribe();
  }

}
