import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SensorsListComponent } from './components/settings/sensors/sensors-list/sensors-list.component';
import { SensorsEditComponent } from './components/settings/sensors/sensors-edit/sensors-edit.component';
import { SensorsHistoComponent } from './components/settings/sensors/sensors-histo/sensors-histo.component';
import { DishesListComponent } from './components/settings/dishes/dishes-list/dishes-list.component';

import { SensorsTriggeredAlertComponent } from './components/dashboards/sensors-triggered-alert/sensors-triggered-alert.component';
import { MonitoringCouchdbComponent } from './components/dashboards/monitoring-couchdb/monitoring-couchdb.component';


import { UsersListComponent } from './components/settings/users/users-list/users-list.component';
import { UsersEditComponent } from './components/settings/users/users-edit/users-edit.component';
import { IndicatorsListComponent } from './components/settings/indicators/indicators-list/indicators-list/indicators-list.component';
import { IndicatorsEditComponent } from './components/settings/indicators/indicators-edit/indicators-edit/indicators-edit.component';
import { IndicatorsFieldsEditComponent } from './components/settings/indicators/indicators-fields-edit/indicators-fields-edit/indicators-fields-edit.component';
import { IndicatorsUsersComponent } from './components/settings/indicators/indicators-users/indicators-users.component';
import { ProcessListTraceabilityComponent } from "./components/settings/process/process-list-traceability/process-list-traceability.component";
import { ProcessListTraceabilityParametersComponent } from "./components/settings/process/process-list-traceability-parameters/process-list-traceability-parameters.component";
import { ProcessListTraceabilityOutputsComponent } from "./components/settings/process/process-list-traceability-outputs/process-list-traceability-outputs.component";
import { ProcessListReceptionOutputComponent } from "./components/settings/process/process-list-reception-output/process-list-reception-output.component";
import { ProcessListReceptionOutputParametersComponent } from "./components/settings/process/process-list-reception-output-parameters/process-list-reception-output-parameters.component";
import { ProcessListReceptionComponent } from "./components/settings/process/process-list-reception/process-list-reception.component";
import { IndicatorsStatsComponent } from './components/settings/indicators/indicators-stats/indicators-stats.component';
import { StatsTargetsListComponent } from './components/settings/stats/stats-targets/stats-targets-list/stats-targets-list.component';
import { AlertsForUsersComponent } from "./components/settings/alerts-for-users/alerts-for-users.component";
import { EshopComponent } from './components/eshop/eshop/eshop.component';
import { EshopProductComponent } from './components/eshop/eshop-product/eshop-product.component';
import { EshopCartComponent } from './components/eshop/eshop-cart/eshop-cart.component';
import { EshopOrderComponent } from './components/eshop/eshop-order/eshop-order.component';
import { EshopPaymentComponent } from './components/eshop/eshop-payment/eshop-payment.component';
import { EshopPaymentSuccessComponent } from './components/eshop/eshop-payment-success/eshop-payment-success.component';
import { EshopPaymentErrorComponent } from './components/eshop/eshop-payment-error/eshop-payment-error.component';
import { EshopOrdersListComponent } from './components/eshop/eshop-orders-list/eshop-orders-list.component';
import { EshopOrderDetailComponent } from './components/eshop/eshop-order-detail/eshop-order-detail.component';
import { EshopStatsComponent } from './components/eshop/eshop-stats/eshop-stats.component';
import { AlertsForUsersAddComponent } from "./components/settings/alerts-for-users-add/alerts-for-users-add.component";
import { EshopProductEditComponent } from './components/eshop/eshop-product-edit/eshop-product-edit.component';
import { StatisticsComponent } from './components/dashboards/statistics/statistics.component';
import { UsersLicencesComponent } from './components/settings/users/users-licences/users-licences.component';
import { GroupsListComponent } from "./components/settings/groups/groups-list/groups-list.component";
import { SurveysListComponent } from './components/settings/audits/surveys-list/surveys-list.component';
import { SurveysEditComponent } from './components/settings/audits/surveys-edit/surveys-edit.component';
import { SurveysPagesEditComponent } from './components/settings/audits/surveys-pages-edit/surveys-pages-edit.component';
import { SurveysQuestionsEditComponent } from './components/settings/audits/surveys-questions-edit/surveys-questions-edit.component';
import { TablesListComponent } from './components/settings/tables/tables-list/tables-list.component';
import { TablesEditComponent } from './components/settings/tables/tables-edit/tables-edit.component';
import { TablesDatasComponent } from './components/settings/tables/tables-datas/tables-datas.component';
import { TablesDatasEditComponent } from './components/settings/tables/tables-datas-edit/tables-datas-edit.component';
import { ProcessReceptionEditComponent } from './components/settings/process/process-reception-edit/process-reception-edit.component';
import { ProcessTraceabilityEditComponent } from './components/settings/process/process-traceability-edit/process-traceability-edit.component';
import { BandeauListComponent } from './components/settings/bandeau/bandeau-list/bandeau-list.component'
import { EmailsListComponent } from './components/settings/emails/emails-list.component';
import { MailReportsLicencesComponent } from './components/settings/emails/mail-reports-licences/mail-reports-licences.component';
import { MailReportsEditComponent } from './components/settings/emails/mail-reports-edit/mail-reports-edit.component';
import { SensorsAlertEditComponent } from './components/settings/emails/sensors-alert-edit/sensors-alert-edit.component';
import {
  AnomaliesDeletionComponent
} from "./components/settings/anomalies/anomalies-deletion/anomalies-deletion.component";
import { LsProductsComponent } from "./components/settings/ls-products/ls-products.component";
import { LicencesListComponent } from './components/settings/licences/licences-list/licences-list.component';
import { LicencesEditComponent } from './components/settings/licences/licences-edit/licences-edit.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'use-token/:dataToken',
    component: LoginComponent,
    data: { tokenLogin: true },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { path: undefined },
  },

  {
    path: 'dashboards/sensors-triggered-alert',
    component: SensorsTriggeredAlertComponent,
    data: { path: [{ label: 'Tableaux de bord' }, { label: 'Alertes' }] },
  },
  {
    path: 'dashboards/sensors-histo/:id',
    component: SensorsHistoComponent,
    data: {
      path: [
        { label: 'Tableaux de bord' },
        { label: 'Alertes', routerLink: 'dashboards/sensors-triggered-alert' },
        { label: 'Historique des mesures' },
      ],
    },
  },
  {
    path: 'dashboards/statistics',
    component: StatisticsComponent,
    data: { path: [{ label: 'Tableaux de bord' }, { label: 'Statistiques' }] },
  },
  {
    path: 'dashboards/monitoring-couchdb',
    component: MonitoringCouchdbComponent,
    data: { path: [{ label: 'Tableaux de bord' }, { label: 'Monitoring CouchDB' }] },
  },
  {
    path: 'eshop/all',
    component: EshopComponent,
    data: { path: [{ label: 'Boutique' }, { label: 'Tous les produits' }] },
  },
  {
    path: 'eshop/category/:id/:name',
    component: EshopComponent,
    data: {
      path: [
        { label: 'Boutique' },
        { label: '[ESHOP_CATEGORY_TITLE]' },
      ],
    },
  },
  {
    path: 'eshop/orders-list',
    component: EshopOrdersListComponent,
    data: { path: [{ label: 'Boutique' }, { label: 'Commandes' }] },
  },
  {
    path: 'eshop/order-detail/:id',
    component: EshopOrderDetailComponent,
    data: { path: [{ label: 'Boutique' }, { label: 'Commandes', routerLink: 'eshop/orders-list' }, { label: 'Détail d\'une commande' }] },
  },
  {
    path: 'eshop/cart',
    component: EshopCartComponent,
    data: {
      path: [
        { label: 'Boutique' },
        { label: 'Tous les produits', routerLink: 'eshop/all' },
        { label: 'Mon panier' }
      ],
    },
  },
  {
    path: 'eshop/stats',
    component: EshopStatsComponent,
    data: {
      path: [
        { label: 'Boutique' },
        { label: 'Statistiques' }
      ],
    },
  },
  {
    path: 'eshop/order',
    component: EshopOrderComponent,
    data: {
      path: [
        { label: 'Boutique' },
        { label: 'Panier', routerLink: 'eshop/cart' },
        { label: 'Validation de la commande' }
      ],
    },
  },
  {
    path: 'eshop/payment',
    component: EshopPaymentComponent,
    data: {
      path: [
        { label: 'Boutique' },
        { label: 'Panier', routerLink: 'eshop/cart' },
        { label: 'Commande', routerLink: 'eshop/order' },
        { label: 'Paiement' }
      ],
    },
  },
  {
    path: 'eshop/payment-success',
    component: EshopPaymentSuccessComponent,
    data: {
      path: [
        { label: 'Boutique' },
        { label: 'Panier', routerLink: 'eshop/cart' },
        { label: 'Commande', routerLink: 'eshop/order' },
        { label: 'Validation du paiement' }
      ],
    },
  },
  {
    path: 'eshop/payment-error',
    component: EshopPaymentErrorComponent,
    data: {
      path: [
        { label: 'Boutique' },
        { label: 'Panier', routerLink: 'eshop/cart' },
        { label: 'Commande', routerLink: 'eshop/order' },
        { label: 'Erreur lors du paiement' }
      ],
    },
  },
  {
    path: 'eshop/product/:id/:name',
    component: EshopProductComponent,
    data: {
      path: [
        { label: 'Boutique' },
        { label: 'Tous les produits', routerLink: 'eshop/all' },
        { label: 'Fiche produit' },
      ],
    },
  },
  {
    path: 'eshop/product-edit/:id',
    component: EshopProductEditComponent,
    data: {
      path: [
        { label: 'Boutique' },
        { label: 'Modifier un produit' },
      ],
    },
  },
  {
    path: 'eshop/product-add',
    component: EshopProductEditComponent,
    data: {
      path: [
        { label: 'Boutique' },
        { label: 'Ajouter un produit' },
      ],
    },
  },
  {
    path: 'settings/sensors',
    component: SensorsListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Sondes' }] },
  },
  {
    path: 'settings/sensors-add',
    component: SensorsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Sondes', routerLink: 'settings/sensors' },
        { label: 'Ajouter une sonde' },
      ],
    },
  },
  {
    path: 'settings/sensors-add/:clientId',
    component: SensorsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Licences', routerLink: 'settings/licences' },
        { label: 'Modifier une licence', routerLink: 'settings/licences-edit/:clientId', fragment: 'sensors' },
        { label: 'Ajouter une sonde' },
      ],
    },
  },
  {
    path: 'settings/sensors-edit/:id',
    component: SensorsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Sondes', routerLink: 'settings/sensors' },
        { label: 'Modifier une sonde' },
      ],
    },
  },
  {
    path: 'settings/sensors-edit/:id/:clientId',
    component: SensorsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Licences', routerLink: 'settings/licences' },
        { label: 'Modifier une licence', routerLink: 'settings/licences-edit/:clientId', fragment: 'sensors' },
        { label: 'Modifier une sonde' },
      ],
    },
  },
  {
    path: 'settings/sensors-histo/:id',
    component: SensorsHistoComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Sondes', routerLink: 'settings/sensors' },
        { label: 'Historique des mesures' },
      ],
    },
  },
  {
    path: 'settings/sensors-histo/:id/:clientId',
    component: SensorsHistoComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Licences', routerLink: 'settings/licences' },
        { label: 'Modifier une licence', routerLink: 'settings/licences-edit/:clientId', fragment: 'sensors' },
        { label: 'Sonde : Historique des mesures' },
      ],
    },
  },
  {
    path: 'settings/sensors-alert-add',
    component: SensorsAlertEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Rapports mails', routerLink: 'settings/emails' },
        { label: 'Ajouter une alerte' },
      ],
    },
  },
  {
    path: 'settings/sensors-alert-edit/:id',
    component: SensorsAlertEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Rapports mails', routerLink: 'settings/emails' },
        { label: 'Modifier une alerte' },
      ],
    },
  },
  {
    path: 'settings/process/process-list-traceability',
    component: ProcessListTraceabilityComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Process' }] },
  },
  {
    path: 'settings/process/process-traceability-edit/:id',
    component: ProcessTraceabilityEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process de traçabilité', routerLink: 'settings/process/process-list-traceability' },
        { label: 'Modifier un process' },
      ],
    },
  },
  {
    path: 'settings/process/process-traceability-edit/:id/:clientId',
    component: ProcessTraceabilityEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Licences', routerLink: 'settings/licences' },
        { label: 'Modifier une licence', routerLink: 'settings/licences-edit/:clientId', fragment: 'process' },
        { label: 'Modifier un process' },
      ],
    },
  },
  {
    path: 'settings/process/process-list-traceability-parameters/:tpr_id',
    component: ProcessListTraceabilityParametersComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process', routerLink: 'settings/process/process-list-traceability' },
        { label: 'Modifier les paramètres' },
      ],
    },
  },
  {
    path: 'settings/process/process-list-traceability-parameters/:tpr_id/:clientId',
    component: ProcessListTraceabilityParametersComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Licences', routerLink: 'settings/licences' },
        { label: 'Modifier une licence', routerLink: 'settings/licences-edit/:clientId', fragment: 'process' },
        { label: 'Process de traçabilité : modifier les paramètres' },
      ],
    },
  },
  {
    path: 'settings/process/process-traceability-edit/:id',
    component: ProcessTraceabilityEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process de traçabilité', routerLink: 'settings/process/process-list-traceability' },
        { label: 'Modifier un process' },
      ],
    },
  },
  {
    path: 'settings/process/process-list-traceability-outputs/:tpr_id',
    component: ProcessListTraceabilityOutputsComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process', routerLink: 'settings/process/process-list-traceability' },
        { label: 'Paramètrage des Outputs' },
      ],
    },
  },
  {
    path: 'settings/process/process-list-traceability-outputs/:tpr_id/:clientId',
    component: ProcessListTraceabilityOutputsComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Licences', routerLink: 'settings/licences' },
        { label: 'Modifier une licence', routerLink: 'settings/licences-edit/:clientId', fragment: 'process' },
        { label: 'Process de traçabilité : paramétrage des outputs' },
      ],
    },
  },
  {
    path: 'settings/process/process-reception', //
    component: ProcessListReceptionOutputComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process de réception' }]
    },
  },
  {
    path: 'settings/process/process-reception-edit/:id',
    component: ProcessReceptionEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process de réception', routerLink: 'settings/process/process-reception' },
        { label: 'Modifier un process' },
      ],
    },
  },
  {
    path: 'settings/process/process-reception/process-list-reception-output-parameters/:cli_id/:cpr_id',
    component: ProcessListReceptionOutputParametersComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process de réception', routerLink: 'settings/process/process-reception' },
        { label: 'Ajout/Modification d\'output(s)' },
      ],
    },
  },
  {
    path: 'settings/process/process-reception-edit/:id',
    component: ProcessReceptionEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process de réception', routerLink: 'settings/process/process-reception' },
        { label: 'Modifier un process' },
      ],
    },
  },
  {
    path: 'settings/process/process-reception/process-list-reception/:cli_id/:cpr_id',
    component: ProcessListReceptionComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process de réception', routerLink: 'settings/process/process-reception' },
        { label: 'Ajout/Modification de paramètre(s)' },
      ],
    },
  },
  {
    path: 'settings/anomalies',
    component: AnomaliesDeletionComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Anomalies', routerLink: 'settings/anomalies' },
        { label: 'Supprimer les anomalies' },
      ],
    },
  },
  {
    path: 'settings/emails',
    component: EmailsListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Rapports mails' }] },
  },
  {
    path: 'settings/mail-reports-add',
    component: MailReportsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Rapports mails', routerLink: 'settings/emails' },
        { label: 'Ajouter un rapport mail (statistiques)' },
      ],
    },
  },
  {
    path: 'settings/mail-reports-edit/:id',
    component: MailReportsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Rapports mails', routerLink: 'settings/emails' },
        { label: 'Modifier un rapport mail' },
      ],
    },
  },
  {
    path: 'settings/mail-reports-licences/:id',
    component: MailReportsLicencesComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Rapports mails', routerLink: 'settings/mail-reports' },
        { label: 'Modifier les licences d\'un rapport mail' },
      ],
    },
  },
  {
    path: 'settings/dishes',
    component: DishesListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Recettes' }] },
  },
  {
    path: 'settings/tables',
    component: TablesListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Tables' }] },
  },
  {
    path: 'settings/tables-add',
    component: TablesEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Tables', routerLink: 'settings/tables' },
        { label: 'Ajouter une table' },
      ],
    },
  },
  {
    path: 'settings/tables-datas/:id',
    component: TablesDatasComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Tables', routerLink: 'settings/tables' },
        { label: 'Données' },
      ],
    },
  },
  {
    path: 'settings/tables-datas-add/:tableId',
    component: TablesDatasEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Tables', routerLink: 'settings/tables' },
        { label: 'Ajouter une donnée' },
      ],
    },
  },
  {
    path: 'settings/tables-datas-edit/:tableId/:dataId',
    component: TablesDatasEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Tables', routerLink: 'settings/tables' },
        { label: 'Modifier une donnée' },
      ],
    },
  },
  {
    path: 'settings/users',
    component: UsersListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Utilisateurs' }] },
  },
  {
    path: 'settings/users-add',
    component: UsersEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Utilisateurs', routerLink: 'settings/users' },
        { label: 'Ajouter un utilisateur' },
      ],
    },
  },
  {
    path: 'settings/users-edit/:id',
    component: UsersEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Utilisateurs', routerLink: 'settings/users' },
        { label: 'Modifier un utilisateur' },
      ],
    },
  },
  {
    path: 'settings/users-licences/:id',
    component: UsersLicencesComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Utilisateurs', routerLink: 'settings/users' },
        { label: 'Modifier les licences d\'un utilisateur' },
      ],
    },
  },
  {
    path: 'settings/groups/groups-list',
    component: GroupsListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Groupes' }] },
  },
  {
    path: 'settings/surveys',
    component: SurveysListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Audits' }] },
  },
  {
    path: 'settings/surveys-add',
    component: SurveysEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Audits', routerLink: 'settings/surveys' },
        { label: 'Ajouter un audit' },
      ],
    },
  },
  {
    path: 'settings/surveys-edit/:id',
    component: SurveysEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Audits', routerLink: 'settings/surveys' },
        { label: 'Modifier un audit' },
      ],
    },
  },
  {
    path: 'settings/surveys-pages-add/:surveyId/:parentFieldId/:nextOrder',
    component: SurveysPagesEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Audits', routerLink: 'settings/surveys' },
        { label: 'Modifier un audit', routerLink: 'settings/surveys-edit/:surveyId' },
        { label: 'Ajouter une page' },
      ],
    },
  },
  {
    path: 'settings/surveys-pages-edit/:surveyId/:id',
    component: SurveysPagesEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Audits', routerLink: 'settings/surveys' },
        { label: 'Modifier un audit', routerLink: 'settings/surveys-edit/:surveyId' },
        { label: 'Modifier une page' },
      ],
    },
  }, {
    path: 'settings/surveys-questions-add/:surveyId/:pageId/:nextOrder',
    component: SurveysQuestionsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Audits', routerLink: 'settings/surveys' },
        { label: 'Modifier un audit', routerLink: 'settings/surveys-edit/:surveyId' },
        { label: 'Modifier une page', routerLink: 'settings/surveys-pages-edit/:surveyId/:pageId' },
        { label: 'Ajouter une question' },
      ],
    },
  },
  {
    path: 'settings/surveys-questions-edit/:surveyId/:pageId/:id',
    component: SurveysQuestionsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Audits', routerLink: 'settings/surveys' },
        { label: 'Modifier un audit', routerLink: 'settings/surveys-edit/:surveyId' },
        { label: 'Modifier une page', routerLink: 'settings/surveys-pages-edit/:surveyId/:pageId' },
        { label: 'Modifier une question' },
      ],
    },
  },
  {
    path: 'settings/indicators',
    component: IndicatorsListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Indicateurs de satisfaction' }] },
  },
  {
    path: 'settings/indicators-add',
    component: IndicatorsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Ajouter un indicateur' },
      ],
    },
  },
  {
    path: 'settings/indicators-edit/:id',
    component: IndicatorsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Modifier un indicateur' },
      ],
    },
  },
  {
    path: 'settings/indicators-fields-add/:indicatorId/:parentFieldId/:nextOrder',
    component: IndicatorsFieldsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Modifier un indicateur', routerLink: 'settings/indicators-edit/:indicatorId' },
        { label: 'Ajouter un champ' },
      ],
    },
  },
  {
    path: 'settings/indicators-fields-edit/:indicatorId/:id',
    component: IndicatorsFieldsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Modifier un indicateur', routerLink: 'settings/indicators-edit/:indicatorId' },
        { label: 'Modifier un champ' },
      ],
    },
  },
  {
    path: 'settings/indicators-users/:id',
    component: IndicatorsUsersComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Utilisateurs' },
      ],
    },
  },
  {
    path: 'settings/indicators-stats/:id',
    component: IndicatorsStatsComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Statistiques' },
      ],
    },
  },
  {
    path: 'settings/stats-targets',
    component: StatsTargetsListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Objectifs' }] },
  },
  {
    path: 'settings/alerts-for-users',
    component: AlertsForUsersComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Alertes utilisateurs' }]
    },
  },
  {
    path: 'settings/alerts-for-users-add/alerts-for-users-add/:id',
    component: AlertsForUsersAddComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Alertes utilisateurs', routerLink: 'settings/alerts-for-users' },
        { label: 'Gestion des Alertes' },
      ],
    },
  },
  {
    path: 'settings/bandeau/bandeau-list',
    component: BandeauListComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Bandeau' }]
    },
  },
  {
    path: 'settings/ls-products',
    component: LsProductsComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Produits LS' }]
    },
  },
  {
    path: 'settings/licences',
    component: LicencesListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Licences' }] },
  },
  {
    path: 'settings/licences-add',
    component: LicencesEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Licences', routerLink: 'settings/licences' },
        { label: 'Ajouter une licence' },
      ],
    },
  },
  {
    path: 'settings/licences-edit/:id',
    component: LicencesEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Licences', routerLink: 'settings/licences' },
        { label: 'Modifier une licence' },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
