export class ReceptionListParameters {
    public rpa_id: number;
    public rpa_cpr_id: number;
    public rpa_type: string;
    public rpa_length: number;
    public rpa_validation: string;
    public rpa_default: string;
    public rpa_default_when: number;
    public rpa_readonly: number;
    public rpa_placeholder: string;
    public rpa_input_mode: string;
    public rpa_values_list: string;
    public rpa_required: number;
    public rpa_order: number;
    public rpa_from_id: number;
    public rpa_script: string;
    public rpa_is_dlc: number;
    public rpa_flag: string;
    public rpa_name: string;
    public rop_id: number;
    public rop_rou_id: number;
    public rop_rpa_id: number;
    public rop_order: number;
    public rop_font: string;
    public rop_size: number;
    public rop_weight: string;
    checked: boolean;

    constructor(
        rpa_id: number,
        rpa_cpr_id: number,
        rpa_type: string,
        rpa_length: number,
        rpa_validation: string,
        rpa_default: string,
        rpa_default_when: number,
        rpa_readonly: number,
        rpa_placeholder: string,
        rpa_input_mode: string,
        rpa_values_list: string,
        rpa_required: number,
        rpa_order: number,
        rpa_from_id: number,
        rpa_script: string,
        rpa_is_dlc: number,
        rpa_flag: string,
        rpa_name: string,
        rop_id: number,
        rop_rou_id: number,
        rop_rpa_id: number,
        rop_order: number,
        rop_font: string,
        rop_size: number,
        rop_weight: string,
        checked: boolean,
    ){
        this.rpa_id = rpa_id;
        this.rpa_cpr_id = rpa_cpr_id;
        this.rpa_type = rpa_type;
        this.rpa_length = rpa_length;
        this.rpa_validation = rpa_validation;
        this.rpa_default = rpa_default;
        this.rpa_default_when = rpa_default_when;
        this.rpa_readonly = rpa_readonly;
        this.rpa_placeholder = rpa_placeholder;
        this.rpa_input_mode = rpa_input_mode;
        this.rpa_values_list = rpa_values_list;
        this.rpa_required = rpa_required;
        this.rpa_order = rpa_order;
        this.rpa_from_id = rpa_from_id;
        this.rpa_script = rpa_script;
        this.rpa_is_dlc = rpa_is_dlc;
        this.rpa_flag = rpa_flag;
        this.rpa_name = rpa_name;
        this.rop_id = rop_id
        this.rop_rou_id = rop_rou_id
        this.rop_rpa_id = rop_rpa_id
        this.rop_order = rop_order
        this.rop_font = rop_font
        this.rop_size = rop_size
        this.rop_weight = rop_weight
        this.checked = false;
    }
}
