import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Client } from '../../models/client';
import { Anomalies } from '../../models/anomalies';
import moment from "moment/moment";

@Injectable({
  providedIn: 'root',
})
export class ApiAnomaliesListService {
  constructor(private http: HttpClient) {
  }

  private mapAnomalies(data: any): Anomalies {
    return new Anomalies({
      ano_id: parseInt(data.ano_id),
      ano_tas_id: parseInt(data.ano_tas_id),
      ano_rec_id: parseInt(data.ano_rec_id),
      ano_tty_id: parseInt(data.ano_tty_id),
      ano_name: data.ano_name,
      ano_details: data.ano_details,
      ano_create_date: data.ano_create_date ? moment(data.ano_create_date) : null,
      ano_create_usr_name: parseInt(data.ano_create_usr_name),
      ano_sen_id: parseInt(data.ano_sen_id),
      obj_name: data.obj_name,
      usr_full_name: data.usr_full_name,
      client: data.client ? new Client(parseInt(data.id), data.name) : undefined,
      cli_id: data.cli_id ? parseInt(data.cli_id) : undefined,
      cli_name: data.cli_name,
      wrk_name: data.wrk_name,
      nbAnomalies: data.nbAnomalies ? parseInt(data.nbAnomalies) : undefined,
    });
  }

  getAnomaliesList(): Observable<Anomalies[]> {
    return this.http
      .get<any[]>(`${environment.apiURL}espace_client/get_anomalies.php`)
      .pipe(
        map((response: any[]) => response.map(this.mapAnomalies)),
        catchError((error) => of([]))
      );
  }

  getAnomaliesDetails(cli_id: any): Observable<Anomalies[]> {
    return this.http
      .get<any[]>(`${environment.apiURL}espace_client/get_anomalies_details.php?cli_id=${cli_id}`)
      .pipe(
        map((response: any[]) => response.map(this.mapAnomalies)),
        catchError((error) => of([]))
      );
  }

  deleteAnomalies(cli_id: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
      'espace_client/delete_anomalies.php?cli_id=' +
      cli_id
    );
  }
}
