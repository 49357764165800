import { Country } from "./country";
import { Day } from "./day";
import { EshopCategory } from "./eshop-category";
import { Frequency } from "./frequency";
import { IndicatorFieldType } from "./indicator-field-type";
import { Output } from "./output";
import { ReceptionType } from "./reception-type";
import { Trigger } from "./trigger";

export class GlobalDatas {
    public languages: Array<any>;
    public userProfiles: Array<any>;
    public alertes_types: Array<any>;
    public alertes_frequencies: Array<any>;
    public output_process_types: Array<any>;
    public output_reception_types: Array<any>;
    public output_process_path: Array<any>;
    public output_reception_path: Array<any>;
    public indicatorsFieldsTypes: Array<IndicatorFieldType>;
    public eshopCategories: Array<EshopCategory>;
    public groups: Array<any>;
    public clientsGroups: Array<any>;
    public clientsGroupsFiltered: Array<any>;
    public frequencies: Array<Frequency>;
    public days: Array<Day>;
    public surveysQuestionTypes: Array<any>;
    public surveysInputModes: Array<any>;
    public countries: Array<Country>;
    public outputs: Array<Output>;
    public receptionTypes: Array<ReceptionType>;
    public triggers: Array<Trigger>;

    constructor(
        languages: Array<any>,
        userProfiles: Array<any>,
        alertes_types: Array<any>,
        alertes_frequencies: Array<any>,
        output_process_types: Array<any>,
        output_reception_types: Array<any>,
        output_process_path: Array<any>,
        output_reception_path: Array<any>,
        indicatorsFieldsTypes: Array<IndicatorFieldType>,
        eshopCategories: Array<EshopCategory>,
        groups: Array<any>,
        clientsGroups: Array<any>,
        clientsGroupsFiltered: Array<any>,
        frequencies: Array<Frequency>,
        days: Array<Day>,
        surveysQuestionTypes: Array<any>,
        surveysInputModes: Array<any>,
        countries: Array<Country>,
        outputs: Array<Output>,
        receptionTypes: Array<ReceptionType>,
        triggers: Array<Trigger>,
        ) {
        this.languages = languages;
        this.userProfiles = userProfiles;
        this.alertes_types = alertes_types;
        this.alertes_frequencies = alertes_frequencies;
        this.output_process_types = output_process_types;
        this.output_reception_types = output_reception_types;
        this.output_process_path = output_process_path;
        this.output_reception_path = output_reception_path;
        this.indicatorsFieldsTypes = indicatorsFieldsTypes;
        this.eshopCategories = eshopCategories;
        this.groups = groups;
        this.clientsGroups = clientsGroups;
        this.clientsGroupsFiltered = clientsGroupsFiltered; // si la licence a une licence secondaire elle n'apparait pas dans le groupe principal
        this.frequencies = frequencies;
        this.days = days;
        this.surveysQuestionTypes = surveysQuestionTypes;
        this.surveysInputModes = surveysInputModes;
        this.countries = countries;
        this.outputs = outputs;
        this.receptionTypes = receptionTypes;
        this.triggers = triggers;
    }
}
