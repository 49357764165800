import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import moment from 'moment';

import { environment } from '../../../environments/environment';
import { Sensor } from '../../models/sensor';
import { SensorMeasure } from '../../models/sensor-measure';
import { SensorAlert } from '../../models/sensor-alert';
import { SensorTriggeredAlert } from '../../models/sensor-triggered-alert';
import { Client } from '../../models/client';
import { Workspace } from '../../models/workspace';

@Injectable({
  providedIn: 'root',
})
export class ApiSensorsService {
  constructor(private http: HttpClient) {}

  getSensors(clientId:string=''): Observable<Sensor[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_sensors.php?clientId=' + clientId
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataSensor) => {
            return new Sensor(
              parseInt(dataSensor.sen_id),
              dataSensor.sen_external_code,
              dataSensor.sen_label,
              dataSensor.sen_min_temp
                ? parseFloat(dataSensor.sen_min_temp)
                : null,
              dataSensor.sen_max_temp
                ? parseFloat(dataSensor.sen_max_temp)
                : null,
              new Client(
                parseInt(dataSensor.cli_id),
                dataSensor.cli_name
              ),
              new Workspace(
                parseInt(dataSensor.wrk_id),
                dataSensor.wrk_name,
                parseInt(dataSensor.cli_id),
                (dataSensor.wrk_parent_id ? parseInt(dataSensor.wrk_parent_id) : null)
              ),
              dataSensor.sme_value ? parseFloat(dataSensor.sme_value) : null
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getSensor(sensordId: string): Observable<Sensor> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_sensors.php?sensorId=' +
          sensordId
      )
      .pipe(
        map((dataSensor: any) => {
            return new Sensor(
              parseInt(dataSensor.sen_id),
              dataSensor.sen_external_code,
              dataSensor.sen_label,
              dataSensor.sen_min_temp
                ? parseFloat(dataSensor.sen_min_temp)
                : null,
              dataSensor.sen_max_temp
                ? parseFloat(dataSensor.sen_max_temp)
                : null,
              new Client(
                parseInt(dataSensor.cli_id),
                dataSensor.cli_name
              ),
              new Workspace(
                parseInt(dataSensor.wrk_id),
                dataSensor.wrk_name,
                parseInt(dataSensor.cli_id),
                (dataSensor.wrk_parent_id ? parseInt(dataSensor.wrk_parent_id) : null)
              ),
              dataSensor.sme_value ? parseFloat(dataSensor.sme_value) : null,
              parseInt(dataSensor.sen_display_before_morning),
              parseInt(dataSensor.sen_display_after_morning),
              parseInt(dataSensor.sen_display_before_lunch),
              parseInt(dataSensor.sen_display_after_lunch),
              parseInt(dataSensor.sen_display_before_evening),
              parseInt(dataSensor.sen_display_after_evening)
            );
        })
      );
  }

  getMeasuresSensors(sensordId: string, periodStart: Date, periodEnd: Date): Observable<SensorMeasure[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_sensor_measures.php?sensorId=' + sensordId + 
          '&periodStart=' + moment(periodStart).format('YYYY-MM-DD HH:mm') + ':00' +
          '&periodEnd=' + moment(periodEnd).format('YYYY-MM-DD HH:mm') + ':59'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataSensor) => {
            return new SensorMeasure(
              moment(new Date(dataSensor.sme_date)),
              parseFloat(dataSensor.sme_value)
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  deleteSensor(sensordId: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/delete_sensor.php?sensorId=' +
        sensordId
    );
  }

  postSensor(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_sensor.php',
      formData
    );
  }

  getAlerts(): Observable<SensorAlert[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_sensors_alerts.php'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataSensorAlert) => {
            let lstSensors:Array<Sensor> = [];

            dataSensorAlert.sensors.forEach((dataSensor:any) => {
              lstSensors.push(new Sensor(
                parseInt(dataSensor.sen_id),
                dataSensor.sen_external_code,
                dataSensor.sen_label
              ));
            });

            return new SensorAlert(
              parseInt(dataSensorAlert.sal_id),
              parseInt(dataSensorAlert.sal_delay_start),
              parseInt(dataSensorAlert.sal_delay_repeat),
              parseInt(dataSensorAlert.sal_nb_repeat),
              new Client(
                parseInt(dataSensorAlert.cli_id),
                dataSensorAlert.cli_name
              ),
              lstSensors,
              [],
              dataSensorAlert.last_alert
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }
  
  getSensorAlert(sensordAlertId: string): Observable<SensorAlert> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_sensors_alerts.php?sensorAlertId=' +
          sensordAlertId
      )
      .pipe(
        map((tabSensorAlert: any) => {

          let dataSensorAlert:any = tabSensorAlert[0];

          let lstSensors:Array<Sensor> = [];

          console.log('sensors??', dataSensorAlert.sensors);

            dataSensorAlert.sensors.forEach((dataSensor:any) => {
              lstSensors.push(new Sensor(
                parseInt(dataSensor.sen_id),
                dataSensor.sen_external_code,
                dataSensor.sen_label
              ));
            });

            return new SensorAlert(
              parseInt(dataSensorAlert.sal_id),
              parseInt(dataSensorAlert.sal_delay_start),
              parseInt(dataSensorAlert.sal_delay_repeat),
              parseInt(dataSensorAlert.sal_nb_repeat),
              new Client(
                parseInt(dataSensorAlert.cli_id),
                dataSensorAlert.cli_name
              ),
              lstSensors,
              dataSensorAlert.recipients,
              dataSensorAlert.last_alert
            );
        })
      );
  }

  postSensorAlert(formData: any): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_sensor_alert.php',
      formData
    );
  }

  deleteSensorAlert(sensorAlertId: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/delete_sensor_alert.php?sensorAlertId=' +
        sensorAlertId
    );
  }

  getTriggeredAlerts(): Observable<SensorTriggeredAlert[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_sensors_alerts_triggered.php'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataSat) => {
            return new SensorTriggeredAlert(
              parseInt(dataSat.sat_id),
              dataSat.sat_date,
              new Sensor(
                parseInt(dataSat.sen_id),
                '',
                dataSat.sen_label,
                null,
                null,
                new Client(
                  parseInt(dataSat.cli_id),
                  dataSat.cli_name
                )
              ),
              
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }  
}
