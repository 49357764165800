import { Client } from "./client";

export class Dishe {
    public rheId: number;
    public disId: number;
    public name: string;
    public internalCode: string;
    public identifiant: string;
    public qty: number;
    public unityName: string;
    public client: Client;

    constructor(
        rheId: number,
        disId: number,
        name: string,
        internalCode: string,
        identifiant: string,
        qty: number,
        unityName: string,
        client: Client,
        ) {
        this.rheId = rheId;
        this.disId = disId;
        this.name = name;
        this.internalCode = internalCode;
        this.identifiant = identifiant;
        this.qty = qty;
        this.unityName = unityName;
        this.client = client;
    }
}
