import moment from "moment/moment";

export class Bandeau {

  public headband_id: number;
  public headband_name: string;
  public headband_message: string;
  public headband_date_begin: moment.Moment | null;
  public headband_date_end: moment.Moment | null;
  public headband_active: number;
  public headband_flag: string;

  constructor(
    headband_id: number,
    headband_name: string,
    headband_message: string,

    headband_date_begin: moment.Moment | null = null,
    headband_date_end: moment.Moment | null = null,

    headband_active: number,
    headband_flag: string,
  ) {
    this.headband_id = headband_id;
    this.headband_name = headband_name;
    this.headband_message = headband_message;
    this.headband_date_begin = headband_date_begin;
    this.headband_date_end = headband_date_end;
    this.headband_active = headband_active;
    this.headband_flag = headband_flag;
  }
}
