import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiLsproductsService {

  constructor(private http: HttpClient) {}

  delOrTransfert(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_ls_products_del_transfert.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

}
