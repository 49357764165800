@defer (when dataLoaded) {
@if(canWrite()) {
<div class="grid">
    <div class="col text-right">
        <button pButton [label]="'Ajouter un rapport mail' | transloco" icon="pi pi-plus" class="p-button-success"
            (click)="addReport()"></button>
    </div>
</div>
}
<p-table [value]="allReports" styleClass="p-datatable-gridlines p-datatable-striped" stateStorage="session"
    stateKey="emails-list-session" [paginator]="true" rowGroupMode="rowspan" groupRowsBy="user.id" [rows]="nbRows"
    [showCurrentPageReport]="true" currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}">
    <ng-template pTemplate="header">
        <tr>
            <th>Type</th>
            @if(isRoot()) {
            <th pSortableColumn="fullName">{{ 'Utilisateur' | transloco }} <p-sortIcon field="fullName"></p-sortIcon>
            </th>
            }
            <th pSortableColumn="title">{{ 'Licence' | transloco }}<p-sortIcon field="title"></p-sortIcon></th>
            <th pSortableColumn="title">{{ 'Titre' | transloco }}<p-sortIcon field="title"></p-sortIcon></th>
            <th pSortableColumn="frequency.name">{{ 'Fréquence' | transloco }} <p-sortIcon
                    field="frequency.name"></p-sortIcon></th>
            <th pSortableColumn="lastDate">{{ 'Dernier envoi' | transloco }} <p-sortIcon field="lastDate"></p-sortIcon>
            </th>
            <th></th>
        </tr>
        <tr>
            <th>
                <p-columnFilter field="objectType" matchMode="in" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-multiSelect [ngModel]="value" [options]="alertTypes" placeholder="Toutes"
                            (onChange)="filter($event.value)" optionLabel="label" optionValue="code"
                            class="liste-recherche-table">
                        </p-multiSelect>
                    </ng-template>
                </p-columnFilter>
            </th>
            @if(isRoot()) {
            <th>
                <p-columnFilter field="user.id" matchMode="in" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-multiSelect [ngModel]="value" [options]="distinctUsers" placeholder="Toutes"
                            (onChange)="filter($event.value)" optionLabel="fullName" optionValue="id"
                            class="liste-recherche-table">
                        </p-multiSelect>
                    </ng-template>
                </p-columnFilter>
            </th>
            }
            <th>
                <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-multiSelect [ngModel]="value" [options]="licences" placeholder="Toutes"
                            (onChange)="filter($event.value)" optionLabel="displayName" optionValue="id"
                            class="liste-recherche-table">
                        </p-multiSelect>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="text" field="title" matchMode="contains"></p-columnFilter>
            </th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-mailreport let-rowIndex="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">
        <tr>

            <td>{{ mailreport.objectType | transloco }}</td>
            @if(mailreport.objectType == 'mailReport') {
            @if(isRoot()) {
            <td *ngIf="rowgroup" [attr.rowspan]="rowspan">{{ mailreport.user.fullName }}</td>
            }
            <td></td>
            <td>{{ mailreport.title }}</td>
            <td>
                @if(mailreport.frequency.code == CODE_PERIODE_SEMAINE) {
                {{ mailreport.frequency.name }}{{ ' le ' | transloco }}{{ mailreport.day?.name | lowercase }}
                }
                @else {
                {{ mailreport.frequency.name }}{{ ' à partir du ' | transloco }}{{ mailreport.startDate |
                date:'dd/MM/yyyy' }}
                }
            </td>
            <td>{{ mailreport.lastDate | date:'dd/MM/yyyy' }}</td>
            <td class="flex align-items-center justify-content-center">
                @if(canWrite()) {
                <button pButton icon="pi pi-pencil" class="flex p-button-rounded p-button-success mr-2"
                    (click)="editMailReport(mailreport)" title="Modifier"></button>
                <button pButton icon="fa fa-store fa-xl" class="flex p-button-rounded p-button-success mr-2"
                    (click)="editLicences(mailreport)" title="Licences"></button>
                <button pButton icon="pi pi-trash" class="flex p-button-rounded p-button-warning"
                    (click)="deleteMailReport(mailreport)" title="Supprimer"></button>
                }
            </td>
            }
            @else if(mailreport.objectType == 'sensorAlert') {
            <td></td>
            <td>{{ mailreport.client.name }}</td>
            <td>{{ mailreport.title }}</td>
            <td>
                {{ 'délai' | transloco }} {{ mailreport.delayStart }}
                {{ ' minutes, répétition toutes les ' | transloco }}{{ mailreport.delayRepeat }}
                {{ ' minutes. ' | transloco }}({{ 'max' | transloco }} {{ mailreport.nbRepeat }})
            </td>
            <td>{{ mailreport.lastAlert | date:'dd/MM/yyyy HH:mm' }}</td>
            <td class="flex align-items-center justify-content-center">
                @if(canWrite()) {
                <button pButton icon="pi pi-pencil" class="flex p-button-rounded p-button-success mr-2"
                    (click)="editSensorAlert(mailreport)"></button>
                <button pButton icon="pi pi-trash" class="flex p-button-rounded p-button-warning"
                    (click)="deleteSensorAlert(mailreport)"></button>
                }
            </td>
            }
        </tr>
    </ng-template>
</p-table>
}
@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

<p-dialog 
    [(visible)]="modalVisible" 
    [header]="'Ajouter un rapport mail' | transloco " 
    [modal]="true" [style]="{ width: '30rem' }">
    
    <span class="p-text-secondary block mb-5">
        {{ 'Sélectionner le type de rapport :' | transloco }}
    </span>

    <div class="flex flex-column gap-3">
        @for(oneAlertType of alertTypes; track oneAlertType) {
            <button pButton [label]="oneAlertType | transloco" (click)="validNewReport(oneAlertType)" class="p-button-gray"></button>
        }
    </div>
</p-dialog>