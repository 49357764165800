import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SynchroService {
  
  private apiUrl = '/sync/job300';
  constructor(private http: HttpClient) {}

  synchro300(clientId: number): Observable<string> {
    return this.http
      .get<any>(`${this.apiUrl}?clientId=${clientId}`)
      .pipe(
        map((retourSynchro: any) => {
          
          return retourSynchro;
        }),
        catchError((error) => {
          console.error('Erreur lors de la synchronisation:', error);
          return of('ERREUR'); // Retourne un observable avec 'ERREUR'
        })
      );
  }
}