import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule  } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';

import { EditComponent } from '../../../../core/edit/edit.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiProcessListService } from '../../../../../services/api/api-process-list.service';
import { ProcessList } from '../../../../../models/process-list';

@Component({
  selector: 'app-licences-edit-stats',
  templateUrl: './licences-edit-stats.component.html',
  styleUrl: './licences-edit-stats.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule
  ],
})
export class LicencesEditStatsComponent extends EditComponent implements OnInit, OnDestroy {
  @Input() editForm!: FormGroup;
  @Input() cliId: string = '';

  detailStats:boolean = false;
  processListSubscription: Subscription = new Subscription();  
  processList: ProcessList[] = [];

  constructor(
      private apiProcessListService: ApiProcessListService,
      private route: ActivatedRoute,
    ) {
        super(route);
    }

  override ngOnInit(): void {
    this.clicModuleStats();

    this.processListSubscription = this.apiProcessListService
          .getProcessList(this.cliId)
          //.pipe(take(1))
          .subscribe((data: ProcessList[]) => {
            this.processList = data;
            this.dataLoaded = true;
          });
  }

  ngOnDestroy(): void {
    if(this.processListSubscription) {
      this.processListSubscription.unsubscribe();
    }
  }

  clicModuleStats() {
    this.detailStats = this.editForm.controls['cpa_stats_menu'].value;
  }
  
}
