import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Client } from '../../models/client';
import {map, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class ApiGroupsService {

  groupsLicences: any[] = [];
  userLicences: Client[] = [];
  nodeFilter = false;

  constructor(private http: HttpClient) {}

  // Fonction pour construire un arbre de TreeNode à partir de la liste des groupes
  buildTreeNode(fields: any[], parentId: string | null, nodeFilter?: boolean): TreeNode[] {

    const nodes = fields
        //.filter((field: any) => field.cgr_id in this.groupsLicences)  // Filtrer les champs pertinents
        .map((field:any) => {

          let children: any[] = [];

          if (field.childs) {
            children = this.buildTreeNode(field.childs, field.cgr_id);
          }

          if (field.cgr_id in this.groupsLicences) {
            //le groupe a des licences on les ajoute
            this.groupsLicences[field.cgr_id].forEach((oneLicenceId: string) => {
              let oLicence: Client | undefined = this.userLicences.find((oneLicence: Client) => oneLicence.id === parseInt(oneLicenceId));

              if (oLicence) {
                children.push({
                  data: {
                    label: oLicence.displayName,
                    id: oLicence.id
                  },
                  type: 'licence',
                  key: oLicence.id,
                  icon: 'fa fa-store fa-xl',
                  label: oLicence.displayName,
                  expanded: false
                });
              }
            });

          }

          // Créer le noeud de groupe
          const node: TreeNode = {
            data: {
              label: field.cgr_name,
              id: field.cgr_id
            },
            type: 'groupe',
            key: field.cgr_id,
            icon: 'fa fa-folder fa-xl',
            label: field.cgr_name,
            expanded: false,
            children: children.length > 0 ? children : []
          };

          // Retourne le noeud s'il contient au moins un élément de type 'licence'
          if (!this.nodeFilter) {
            const hasLicenceChild = children.some(
              child => child.type === 'licence' || (child.children && child.children.length > 0)
            );
            return hasLicenceChild ? node : null; // Retourne null si le nœud n'a pas de "licence"
          }

          return node;
        })
      .filter(node => node !== null); // Filtrer les nœuds nulls

    return nodes as TreeNode[];
  }

  convertToTreeNodes(groups: any[], groupsLicences: any[], userLicences: Client[]): TreeNode[] {
    this.groupsLicences = groupsLicences;
    this.userLicences = userLicences;

    let treeNodes = this.buildTreeNode(groups, null);

    return treeNodes;
  }
  convertToTreeNodesNoFilter(groups: any[], groupsLicences: any[], userLicences: Client[]): TreeNode[] {
    this.groupsLicences = groupsLicences;
    this.userLicences = userLicences;
    this.nodeFilter = true;
    return this.buildTreeNode(groups, null, this.nodeFilter);
  }

  deleteGroup(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_delete_group.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  updateGroup(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_maj_group.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  addGroup(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_add_group.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  deleteOrNotLicence(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_maj_cancel_date_client.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  getMasterOfGroup(cgrId:number): Observable<string> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_group_master.php?cgrId=' +
          cgrId
      )
      .pipe(
        map((dataProcess: any) => {
          let cliId = '0';

          if(dataProcess?.ccg_cli_id) {
            cliId = dataProcess?.ccg_cli_id;
          }
          console.log('cliId', cliId)
          return cliId;
        })
      );
  }

}
