@defer (when dataLoaded) {
  <div>
    <p-panel [header]="'Ajout/Modification des paramètres' | transloco">
      <form [formGroup]="editForm">
        <div class="formgrid grid">
            <div class="col text-left" style="margin-bottom: 15px;">
              <button
              pButton
              [label]="'Accéder aux Outputs' | transloco"
              icon="pi pi-print"
              class="p-button-success"
              (click)="accessToOuputs()"
              [disabled]="!addEnabled"
              ></button>
            </div>
           <div class="col text-right" style="margin-bottom: 15px;">
            <button
            pButton
            [label]="'Ajouter un paramètre' | transloco"
            icon="pi pi-plus"
            class="p-button-success"
            (click)="displayTable()"
            [disabled]="!addEnabled"
          ></button>
          </div>
      </div>

        <div>
            <p-table
              [value]="parameters"
              [resizableColumns]="true"
              columnResizeMode="expand"
              selectionMode="single"
              [tableStyle]="{'align-item': 'center', 'min-width': '50px', 'max-width': '100%'}"
              styleClass="p-datatable-gridlines p-datatable-striped"
              stateStorage="session"
              stateKey="receptionListParameters-session"
              currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
              dataKey="id"
              [(selection)]="processReceptionSelected"
              sortMode="multiple"
              [paginator]="true"
              [rows]="20"
              [showCurrentPageReport]="false">

              <ng-template pTemplate="header">
                <tr>
                <th pSortableColumn="rpa_order">
                {{ 'Ordre' | transloco }}
                <p-sortIcon field="rpa_order"/>
                </th>
                <th pResizableColumn>{{ 'Nom' | transloco }}</th>
                <th pResizableColumn>{{ 'Type' | transloco }}</th>
                <th pResizableColumn>{{ 'Longueur' | transloco }}</th>
                <th pResizableColumn>{{ 'Mode' | transloco }}</th>
                <th pResizableColumn>{{ 'Liste' | transloco }}</th>
                <th pResizableColumn>{{ 'Defaut' | transloco }}</th>
                <th pResizableColumn>{{ 'Default time' | transloco }}</th>
                <th pResizableColumn>{{ 'Validation' | transloco }}</th>
                <th pResizableColumn>{{ 'Placeholder' | transloco }}</th>
                <th pResizableColumn>{{ 'Readonly' | transloco }}</th>
                <th pResizableColumn>{{ 'Requis' | transloco }}</th>
                <th style="min-width:10%">{{ 'Action' | transloco}}</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-processReceptionSelected let-ri="rowIndex"
              style="{'align-item': 'center'}">
                <tr>
                <td style="width: 8px">{{processReceptionSelected.rpa_order}}</td>
                <td>{{processReceptionSelected.rpa_name}}</td>
                <td>{{processReceptionSelected.rpa_type}}</td>
                <td>{{processReceptionSelected.rpa_length}}</td>
                <td>{{processReceptionSelected.rpa_input_mode}}</td>
                <td style="max-width: 200px">{{processReceptionSelected.rpa_values_list}}</td>
                <td style="max-width: 200px">{{processReceptionSelected.rpa_default}}</td>
                <td>{{processReceptionSelected.rpa_default_when}}</td>
                <td>{{processReceptionSelected.rpa_validation}}</td>
                <td>{{processReceptionSelected.rpa_placeholder}}</td>
                <td>{{ processReceptionSelected.rpa_readonly == 0 ? 'Non' : 'Oui' }}</td>
                <td>{{ processReceptionSelected.rpa_required == 0 ? 'Non' : 'Oui' }}</td>
                <td>
                <div class="flex align-items-center justify-content-center gap-2">
                <button
                pButton
                type="button"
                icon="pi pi-pencil"
                class="flex p-button-rounded p-button-success mr-2"
                [pTooltip]="'Modifier ce paramètre' | transloco"
                tooltipPosition="left"
                [disabled]="areButtonsDisabled"
                (click)="onRowSelect(processReceptionSelected, processReceptionSelected.rpa_id)"
                >
                </button>
                <button
                pButton
                type="button"
                icon="pi pi-trash"
                class="flex p-button-rounded p-button-unactivate mr-2"
                (click)="deleteReceptionParameter(processReceptionSelected)"
                [pTooltip]="'Supprimer ce paramètre' | transloco"
                [disabled]="areButtonsDisabled"
                tooltipPosition="left"
                ></button>
                </div>
                </td>
                </tr>
                <tr></tr>
              </ng-template>
            </p-table>
          </div>

          <div *ngIf="processReceptionisSelected" class="py-5">
            <p-panel [header]='setting | transloco'>
              <form [formGroup]="editForm" (ngSubmit)="save()" >
                <div class="formgrid grid">
                  <div class="field col-12 md:col-6 xl:col-6">
                    <label for="param_order" class="label-required">{{ "Ordre" | transloco }}</label>
                    <br/>
                    <p-inputNumber
                      inputId="param_order"
                      formControlName="param_order"
                      [min]="1"
                      [max]="100"
                      [step]=1
                      [showButtons]="true"
                      [required]="true">
                    </p-inputNumber>
                    @if(isErrorFormControl(editForm.controls['param_order'])) {
                    <br/><small class="text-red-500">{{
                      "Ordre requis" | transloco
                      }}</small>
                    }
                  </div>
                </div>
              <div class="formgrid grid">
                <div class="field col-12 md:col-6 xl:col-6">
                    <label for="param_name" class="label-required">{{ "Nom" | transloco }}</label>
                    <input
                      type="text"
                      pInputText
                      formControlName="param_name"
                      [required]="true"
                      class="p-inputtext w-full"
                      [style]="{ height: '45px'}"/>
                    @if(isErrorFormControl(editForm.controls['param_name'])) {
                    <br/><small class="text-red-500">{{ "Nom requis" | transloco }}</small>
                    }
                  </div>
                <div class="field col-12 md:col-6 xl:col-6">
                    <label for="type" class="label-required">{{"Type" | transloco}}</label>
                    <p-dropdown
                      [style]="{ width: '100%' }"
                      [options]="filteredTypes"
                      class="w-full"
                      formControlName="param_type"
                      optionLabel="label"
                      optionValue="value"
                      [required]="true"
                      (onChange)="typeChange()">
                    </p-dropdown>
                    @if(isErrorFormControl(editForm.controls['param_type'])) {
                    <small class="text-red-500">{{ "Type requis" | transloco }}</small>
                    }
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field col-12 md:col-6 xl:col-6">
                  <div *ngIf="editForm.controls['param_type'].value == 'varchar'">
                      <label for="longueur">{{
                      "Longueur" | transloco
                      }}</label>
                      <p-dropdown
                        [style]="{ width: '100%', 'margin-top': '10px' }"
                        [options]="longueurText"
                        class="w-full"
                        formControlName="param_length"
                        optionLabel="label"
                        optionValue="value">
                      </p-dropdown>
                </div>
                  </div>
                <div class="field col-12 md:col-6 xl:col-6">
                  <div *ngIf="editForm.controls['param_type'].value == 'varchar'">
                    <label for="mode">{{
                    "Mode" | transloco
                    }}</label>
                    <p-dropdown
                      [style]="{ width: '100%', 'margin-top': '10px' }"
                      [options]="modeText"
                      class="w-full"
                      formControlName="param_mode"
                      [filter]="true"
                      optionLabel="label"
                      optionValue="value">
                    </p-dropdown>
                    @if(isErrorFormControl(editForm.controls['param_mode'])) {
                    <small class="text-red-500">{{ "Mode requis" | transloco }}</small>
                    }
                </div>
              </div>
            </div>
          <div class="formgrid grid">
            <div class="field col-12 md:col-6 xl:col-6">
              <div *ngIf="editForm.controls['param_mode'].value === 'list'">
              <label for="param_list">{{ "Liste" | transloco }}</label>
              <br/>
              <p-chips [(ngModel)]="listValues" separator="," [ngModelOptions]="{standalone: true}"
              (onBlur)="getTheList()"></p-chips>
              </div>
            </div>
          </div>
            <div class="formgrid grid">
              <div class="field col-12 md:col-6 xl:col-6">
                <div *ngIf="editForm.controls['param_type'].value !== 'separator'
                && editForm.controls['param_type'].value !== 'comment'">
                    <label for="param_readOnly">{{"Lecture seule ?" | transloco}}</label>
                    <p-dropdown
                    [style]="{ width: '100%', 'margin-top': '10px' }"
                    [options]="ouiNon"
                    class="w-full"
                    formControlName="param_readOnly"
                    optionLabel="label"
                    optionValue="value">
                    </p-dropdown>
                  </div>
                </div>
              <div class="field col-12 md:col-6 xl:col-6">
                <div *ngIf="editForm.controls['param_type'].value !== 'separator'
                && editForm.controls['param_type'].value !== 'comment'">
                <label for="param_requis">{{"Requis ?" | transloco}}</label>
                <p-dropdown
                [style]="{ width: '100%', 'margin-top': '10px' }"
                [options]="ouiNon"
                class="w-full"
                formControlName="param_requis"
                optionLabel="label"
                optionValue="value">
                </p-dropdown>
                </div>
              </div>
            </div>
          <div class="formgrid grid">
            <div class="field col-12 md:col-6 xl:col-6">
          <div *ngIf="editForm.controls['param_type'].value !== 'separator' && editForm.controls['param_type'].value !== 'comment'">
            <label for="param_default_when">{{
            "Temps par defaut" | transloco
            }}</label>
            <br/>
            <p-inputNumber
              inputId="param_default_when"
              formControlName="param_default_when"
              [style]="{ width: '100%', 'margin-top': '10px' }"
              [min]="0"
              [max]="100"
              [step]=1
              [showButtons]="true"
              [required]="false">
            </p-inputNumber>
            </div>
          </div>
          </div>
          <div class="formgrid grid">
            <div class="field col-12 md:col-6 xl:col-6">
              <div *ngIf="editForm.controls['param_type'].value !== 'varchar'
              && editForm.controls['param_type'].value !== 'separator' && editForm.controls['param_type'].value !== 'comment'">
              <label for="validation">{{
              "Critère de Validation" | transloco
              }}</label>
              <input
              type="text"
              pInputText
              formControlName="param_validation"
              class="p-inputtext w-full"
              [style]="{ width: '100%', 'margin-top': '10px' }">
            </div>
          </div>
            <div class="field col-12 md:col-6 xl:col-6">
              <div *ngIf="editForm.controls['param_type'].value !== 'separator' && editForm.controls['param_type'].value !== 'comment'">
                <label for="placeHolder">{{
                "Valeur par défaut" | transloco
                }}</label>
                <input
                type="text"
                pInputText
                formControlName="param_placeHolder"
                class="p-inputtext w-full mr-2"
                [style]="{ width: '100%', 'margin-top': '10px' }">
              </div>
            </div>
          </div>
        <div class="formgrid grid">
          <div class="field col-12 md:col-6 xl:col-6">
            <div *ngIf="editForm.controls['param_type'].value == 'date'">
              <label for="param_requis">{{
              "DLC ?" | transloco
              }}</label>
                <br/>
                <p-dropdown
                [style]="{ width: '100%', 'margin-top': '10px' }"
                [options]="ouiNon"
                class="w-full"
                formControlName="param_dlc"
                optionLabel="label"
                optionValue="value"
                ></p-dropdown>
            </div>
          </div>
          <div class="field col-12 md:col-6 xl:col-6">
            <div *ngIf="editForm.controls['param_type'].value == 'date'">
              <label for="param_default">{{
              "Nb de jours de DLC par défaut" | transloco
              }}</label>
              <br/>
              <p-inputNumber
                inputId="param_default"
                formControlName="param_default"
                [style]="{ width: '100%', 'margin-top': '10px' }"
                [min]="1"
                [max]="100"
                [step]=1
                [showButtons]="true"
                [required]="false"
                [disabled]="!(editForm.controls['param_dlc'].value === 1)">
              </p-inputNumber>
          </div>
        </div>
        </div>
          <div class="grid py-1">
            <div class="col-12"></div>
            <div class="col text-center">
              <button
                pButton
                [label]="'Annuler' | transloco"
                icon="pi pi-minus"
                class="p-button-unactivate"
                [disabled]="cancelEnabled"
                (click)="cancel()"
              ></button>
              <button
                pButton
                [label]="'Enregistrer' | transloco"
                icon="pi pi-save"
                class="p-button-success"
                style="margin-left: 20px"
                [disabled]="!editForm.valid"
              ></button>
            </div>
        </div>
      </form>
     </p-panel>
      </div>
    </form>
  </p-panel>
</div>
}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast position="top-center"></p-toast>

