export class ClientReceptionType {

  public rty_id: number;
  public rty_name: string;
  public rty_menu: number;
  public rty_traceability: number;
  public cpr_id: number;
  constructor(
    rty_id: number,
    rty_name: string,
    rty_menu: number,
    rty_traceability: number,
    cpr_id: number,
  ) {
    this.rty_id = rty_id;
    this.rty_name = rty_name;
    this.rty_menu = rty_menu;
    this.rty_traceability = rty_traceability;
    this.cpr_id = cpr_id;
  }
}
