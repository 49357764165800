<form [formGroup]="editForm" style="display: block" (ngSubmit)="save()">
  <p-panel [header]="'Gestion des produits LS' | transloco">
    <div class="field">
    <label for="param_LSCliSource" class="label-required">{{"Licence" | transloco}}</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="licences"
        class="w-full"
        formControlName="param_LSCliSource"
        [filter]="true"
        optionLabel="displayName"
        optionValue="id"
        [placeholder]="'Sélectionnez une licence' | transloco"
        [required]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        (onChange)="licenceCliSelected()"
      >
      </p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_LSCliSource'])) {
        <small class="text-red-500">{{ "Licence requise" | transloco }}</small>
        }
    </div>
        <div class="field col-12 md:col-6 xl:col-6 nopadding">
          <label class="label-required">{{"Date de saisie erronnée" | transloco }}</label>
          <br/>
          <p-calendar
            [readonlyInput]="true"
            aria-label="Période"
            dateFormat="dd/mm/yy"
            formControlName="param_LSDate"
            (onSelect)="changeStartPeriod()"/>
        </div>
        <div class="field col-12 md:col-6 xl:col-6 nopadding">
          <label class="label-required">{{"Tranferer les produits ?" | transloco }}</label>
          <br/>
          <p-toggleButton
            formControlName="param_Transfert"
            onLabel="Oui"
            offLabel="Non"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            styleClass="w-9rem"
            ariaLabel="Do you confirm"
            (onChange)="doTransfertData($event)"/>
        </div>
    <div *ngIf="targetLicence" class="field">
      <label for="param_LSCliTarget" class="label-required">{{"Licence cible" | transloco}}</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="licences"
        class="w-full"
        formControlName="param_LSCliTarget"
        [filter]="true"
        optionLabel="displayName"
        optionValue="id"
        [placeholder]="'Sélectionnez une licence cible' | transloco"
        [required]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"/>
      @if(isErrorFormControl(editForm.controls['param_LSCliTarget'])) {
      <small class="text-red-500">{{ "Licence requise" | transloco }}</small>
      }
    </div>
        <div class="formgrid grid">
          <div class="col text-center">
            <button
              type="button"
              pButton
              [label]="'Annuler' | transloco"
              icon="pi pi-arrow-left"
              class="p-button-success"
              [disabled]="cancelButton"
              (click)="cancelDelete()"
            ></button>
            <button
              pButton
              [disabled]="!editForm.valid"
              [label]="'Confirmer' | transloco"
              icon="pi pi-exclamation-triangle"
              class="p-button-success"
              style="margin-left: 15px;"
            ></button>
          </div>
        </div>
  </p-panel>
</form>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast position="top-center"></p-toast>
