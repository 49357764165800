@defer (when dataLoaded) {
<div>
  <p-panel [header]="'Gestion des bandeaux d\'information' | transloco">
    <div class="formgrid grid">
      <div class="col text-right" style="margin-bottom: 15px;">
        <button
          pButton
          [label]="'Ajouter un bandeau' | transloco"
          icon="pi pi-plus"
          class="p-button-success"
          (click)="displayAddBandeau()"
          [disabled] = "addBandeauDisabled"
        ></button>
      </div>
    </div>

    <div>
      <p-table
        [value]="bandeauList"
        [resizableColumns]="true"
        selectionMode="single"
        [tableStyle]="{'align-item': 'center', 'min-width': '50px', 'max-width': '100%'}"
        styleClass="p-datatable-gridlines p-datatable-striped"
        stateStorage="session"
        stateKey="bandeauParameters-session"
        currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
        dataKey="id"
        [(selection)]="bandeauSelected"
        [paginator]="true"
        [rows]="20"
        [showCurrentPageReport]="false"
      >

        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'ID' | transloco }}</th>
            <th>{{ 'Titre' | transloco }}</th>
            <th>{{ 'Message' | transloco }}</th>
            <th>{{ 'Date de début' | transloco }}</th>
            <th>{{ 'Date de fin' | transloco }}</th>
            <th>{{ 'Actif ?' | transloco }}</th>
            <th>{{ 'Action' | transloco }}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-bandeauSelected let-ri="rowIndex"
        style="{'align-item': 'center'}">
          <tr>
            <td>{{bandeauSelected.headband_id}}</td>
            <td>{{bandeauSelected.headband_name}}</td>
            <td class="ellipsis">{{bandeauSelected.headband_message}}</td>
            <td>{{ bandeauSelected.headband_date_begin | date : "dd/MM/yyyy"  }}</td>
            <td>{{ bandeauSelected.headband_date_end | date : "dd/MM/yyyy"  }}</td>
            <td [ngSwitch]="bandeauSelected.headband_active">
              <span *ngSwitchCase="0">non</span>
              <span *ngSwitchDefault>oui</span>
            </td>
            <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                pButton
                type="button"
                icon="pi pi-pencil"
                class="flex p-button-rounded p-button-success mr-2"
                [pTooltip]="'Modifier ce bandeau' | transloco"
                tooltipPosition="left"
                [disabled]="areButtonsDisabled"
                (click)="onRowSelect(bandeauSelected, bandeauSelected.headband_id)"
                >
              </button>
              <button
                pButton
                type="button"
                icon="pi pi-trash"
                class="flex p-button-rounded p-button-unactivate mr-2"
                [pTooltip]="'Supprimer ce bandeau' | transloco"
                tooltipPosition="left"
                [disabled]="areButtonsDisabled"
                (click)="deleteBandeau(bandeauSelected.headband_id)"
                >
              </button>
            </div>
            </td>
          </tr>
          <tr></tr>
        </ng-template>
      </p-table>
    </div>

    <div *ngIf="displayTableau" class="py-5">
      <p-panel [header]="'Mise à jour du bandeau' | transloco">
        <form [formGroup]="editForm" (ngSubmit)="save()" >
          <div class="formgrid grid">
            <div class="field col-12 md:col-6 xl:col-6">
              <label for="param_bandeauTitre" class="label-required">{{ "Titre" | transloco }}</label>
              <input
                type="text"
                pInputText
                formControlName="param_bandeauTitre"
                [required]="true"
                class="p-inputtext w-full"
                [style]="{ height: '45px'}"/>
              @if(isErrorFormControl(editForm.controls['param_bandeauTitre'])) {
              <br/><small class="text-red-500">{{ "Titre requis" | transloco }}</small>
              }
            </div>
            <div class="field col-12 md:col-6 xl:col-6">
              <label for="param_bandeauMessage" class="label-required">{{ "Message" | transloco }}</label>
              <br />
              <textarea
                pInputTextarea
                formControlName="param_bandeauMessage"
                class="w-full mr-2"
                rows="4"
              ></textarea>
              @if(isErrorFormControl(editForm.controls['param_bandeauMessage'])) {
              <br/><small class="text-red-500">{{ "Message requis" | transloco }}</small>
              }
            </div>
            <div class="field col-12 md:col-6 xl:col-6">
            <label class="label-required">{{ "Date de début" | transloco }}</label>
            <br />
            <p-calendar [readonlyInput]="true" aria-label="Période" dateFormat="dd/mm/yy"
            formControlName="param_bandeauDateDebut" (onSelect)="changeStartPeriod()"></p-calendar>
            </div>
            <div class="field col-12 md:col-6 xl:col-6">
            <label class="label-required">{{ "Date de fin" | transloco }}</label>
            <br />
            <p-calendar [readonlyInput]="true" aria-label="Période" dateFormat="dd/mm/yy"
            formControlName="param_bandeauDateFin" (onSelect)="changeEndPeriod()"></p-calendar>
            </div>
            <div class="field col-12 md:col-6 xl:col-6">
              <label for="param_bandeauActif" class="label-required">{{"Actif ?" | transloco}}</label>
              <p-dropdown
                [style]="{ width: '100%', 'margin-top': '10px' }"
                [options]="ouiNon"
                class="w-full"
                formControlName="param_bandeauActif"
                optionLabel="label"
                optionValue="value">
              </p-dropdown>
            </div>

          </div>
          <div class="grid py-1">
          <div class="col text-center">
            <button
              pButton
              [label]="'Annuler' | transloco"
              icon="pi pi-minus"
              class="p-button-unactivate"
              (click)="cancel()"
              [disabled]="cancelButtonDisabled"
            ></button>
            <button
              pButton
              [label]="'Enregistrer' | transloco"
              icon="pi pi-save"
              class="p-button-success"
              style = "margin-left: 20px;"
              [disabled]="!editForm.valid"
            ></button>
            </div>
          </div>
        </form>
      </p-panel>
    </div>
  </p-panel>
</div>
}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast position="top-center"></p-toast>
